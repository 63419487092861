/*
* Style Page
*/
.wrap-content{
    margin-top: 100px;
    margin-bottom: 100px;
}
.page-header{
    position: relative;
    text-align: center;
    margin-top: 0;
    margin-bottom:0px;
    padding: 260px 0 260px;
    background:url('#{$image-url}bg-header.jpg') no-repeat 0 0 #f8f2ef;
    background-size: cover ;
    background-position: center center;
    &:after{
        content: '';
        top: 0;
        left: 0;
        position: absolute;
        background: rgba(0,0,0,0.2);
        width: 100%;
        height: 100%;
        pointer-events: none;
    }
    .page-title{
        z-index: 99;
        position: relative;
    }
}
.page-content{
    padding-bottom: 150px;
    padding-top:50px;
    &.no-padding-top{
        padding-top: 0;
    }
    &.no-padding-bottom{
        padding-bottom: 0;
    }
    &.no-padding-content{
        padding: 0;
    }


}
.page-title{
    text-align: center;
    margin-top:0px;
    font-size: 84px;
    font-weight: 500;
    color: #FFF;
    margin-bottom: 10px;
    > h1{
        margin-top: 0;
        font-size: 36px;
        font-weight: 500;
    }
}
.cat-header{
    padding: 0px 0 80px;
}
.logo-default{
    padding-bottom: 5px;
    max-height: 33px;
}

/**
 * Checkout
 */
.woocommerce-nav{
    margin-bottom: 30px;
    margin-top: 30px;

    .woocommerce_checkout_login,.woocommerce_checkout_coupon{
        background: #f8f8f8;
        border: 1px solid #e7e7e7;
        padding:30px 60px;
    }
    .login,.checkout_coupon {
        margin-top: 30px;
    }
    .woocommerce-info{
        text-align: center ;
        font-size: 14px;
        a{
            color: $color-title;
            font-weight: 500;
        }
    }
}
.woocommerce-checkout-payment{
    input[type="checkbox"]{
       position: relative;
    }
}
.woocommerce-checkout {
    .titlecheckout{
        font-size: 28px;
        font-weight: 300;
        margin: 0;
        padding: 50px 0px 30px;
    }
    .select2-container--default .select2-selection--single{
        border-radius: 0;
        height: 50px;
        border:1px solid #ebebeb;
        line-height: 50px;
        background: #fff;
    }
    .select2-container .select2-selection--single .select2-selection__rendered{
        padding-left: 15px;
    }
    .select2-container--default .select2-selection--single .select2-selection__arrow{
        height: 50px;
    }
    .select2-container--default .select2-selection--single .select2-selection__rendered{
        line-height: 48px;
        background: #fff;
    }
    .address-field select{
        height: 50px;
        line-height: 50px;
        background: #fff;
    }
    input{
        color: #777777;
    }
    .checkbox{
        color: $color-title;
        @include rtl-padding-left(5px);
    }
    .select2-choice{
        height: 42px;
        line-height: 32px;
    }
    label{
        color: $color-title;
        font-weight: 500;
    }
    .title-checkout{
        color: $color-title;
        padding: 20px;
        margin: 0;
        font-size: 18px;
        text-transform: uppercase;
    }

    .woocommerce-billing-fields .titlecheckout{
        @include rtl-padding-left(0px);
    }
    .woocommerce-billing-fields p{
        padding-bottom: 10px;
    }
    .form-row-first,.form-row-last{
        width: 47.5%;
        @include rtl-float-left();
    }
    .form-row-first{
        @include rtl-margin-right(5%);
    }
    .amount{
        @include rtl-float-right();
    }
    .create-account{
        margin-bottom: 0;
    }
    .shipping-details{
        padding-bottom: 20px;
    }
    .checkout-review{

        thead th{
            border: none;
        }
        th{
            padding: 20px;
        }
        td{
            padding: 20px;
        }
        .product-total{
            @include rtl-text-align-right();
        }
        .checkbox{
            padding-left: 20px;
        }
    }
    .woocommerce-checkout-review-order{
        background: rgb(248,248,248);
        border: 1px solid #e7e7e7;
    }

    #payment {
        border-radius: 5px;
        ul.payment_methods {
            padding: 0;
            p{
                font-size: 14px;
            }
            li{
                padding: 20px 40px;
                border-bottom:1px solid #e7e7e7 ;
                label{
                    text-transform: capitalize;
                    font-weight: 500;
                }
                &:first-child{
                    padding-top: 0;
                }
            }
            list-style: none;
            label{
                display: inline-block;
                font-size: 14px;
                width: 100%;
                position: relative;
                &:before{
                    border-radius: 50%;
                    height: 12px;
                    @include rtl-left(-20px);
                    top: 5px;
                    width: 12px;
                    background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
                    border: 1px solid #ccc;
                    content: " ";
                    cursor: pointer;
                    display: block;
                    position: absolute;
                }
                &[for="payment_method_paypal"]{
                    &:before{

                    }
                }
            }
            .payment_method_paypal{
                label{
                    &:before{
                        margin-top: 8px;
                    }
                }
            }

        }
        .input-radio{
            visibility: hidden;
            display: none;
            &:checked + label::before {
                border-color: #282828;
                border-width: 2px;
            }
        }
        .payment_method_paypal {
            .about_paypal {
                @include rtl-float-right();
                line-height: 52px;
                font-size: 0.83em;
            }

            img {
                max-height: 52px;
                vertical-align: middle;
            }
        }

    }

    .place-order{
        padding: 20px;
        a{
            color: $color-title;
        }
    }
    form.login{
        .form-row input.input-text{
            background: white;
        }
    }
    .sok-coupon #coupon_code {
        padding: 0 10px;
    }
    .btn-order{
        font-size: 16px;
    }
}

/**
 * Check Cart
 */
.shop_table{
    border: none;
    background: none;
    @include rtl-text-align-left();
    thead{
        background: none;
    }
    th{
        padding-top: 0;
    }
    .product-thumbnail{
        @include rtl-text-align-left();
        max-width: 110px;
    }
    .product-name{
        display: table-cell;
    }
    td{
        padding: 20px;
    }
    .order-total{
        color: $color-title;
        background: #ededed;
    }
    .shipping{
        td{
            @include rtl-text-align-right();
            line-height: 24px;
        }
        .shipping-calculator-button{
            text-decoration: underline;
        }
        .shipping-calculator-form{
            padding-top: 15px;
            .button{
                margin-top: 15px;
                font-size: 12px;
                padding: 6px 20px;
            }
        }
    }

}
.cart-item{
    margin-top: 10px;
}
.cart_item {
    border-bottom: 1px solid #e7e7e7;
    > *{
        padding: 20px 0px;
        border-bottom: none;
    }
    .add-action{
        display: none;
    }
    >*{
        vertical-align: middle;
    }
}
.variation{
    color: $color-title;
    display:block;
    margin: 10px 0;
    font-weight: 400;
    dd{
        margin-bottom: 5px;
    }
    >*{
        display:block;
        margin: 0;

        > p{
            margin: 0;
            color: $font-color;
        }
    }
}
.page-cart-warp{
    padding-top: 50px;
}
.page-cart{
    @include rtl-padding-right(35px);
    .cart .quantity input.qty,.cart .quantity{
        width: 70px;
        margin: 0;
        height: 32px;
        border:none;
        background: #FFF;
        font-weight: 400;
        font-size: 15px;
    }
    .product-name{
        a{
            color:$color-title;
            font-weight: 500;
            line-height: 20px;
        }
    }
    .product-subtotal >*{
        font-weight: 500;
    }

    .shop_table{
        margin-bottom: 50px;
        thead > tr > *{
            border: none;
        }
        thead > tr > th:first-child{
            @include rtl-padding-left(0px);
        }
        tbody > tr > td:first-child{
            @include rtl-padding-left(0px);
        }
    }
    .sok-coupon input[type="submit"]{
        line-height: 25px;
    }

}
.sok-coupon{
    margin-bottom: 20px;
    .actions{
        margin-top: 0;
    }
    .coupon{
        background: #f8f8f8;
        border: 1px solid #e7e7e7;
        padding: 15px;
        width: 100%;
    }
    .input-group-btn{
        padding-right: 0;
        padding-left: 15px;
        input{
            padding: 0 15px;
        }
    }
    .form-control{
        border: none;
    }
    span{
        border: none;
        padding: 0 15px;
        font-size: 16px;
    }
    #coupon_code{
        background: #fff;
        height: 42px;
        padding:15px;
        border: 1px solid #e7e7e7;
        color: #000;
        font-size: 14px;
        &::-webkit-input-placeholder {
            color: #ccc;
        }
        &::-moz-placeholder {
            color: #ccc;
        }
        &:-ms-input-placeholder {
            color: #ccc;
        }
        &:-moz-placeholder {
            color: #ccc;
        }
    }
    input[type="submit"]{
        background: #fff;
        color: $color-title;;
        border: 1px solid $color-title;
        line-height: 25px;
        height: 42px;
        font-size: 12px;
        &:hover{
            background: $color-title;
            color: #FFF;
            border-color: $color-title;
        }
    }
    .input-group-addon{
        display: none;
        background: none;
        font-size: 14px;
        color: $color-title;
        text-transform: uppercase;
    }
    .button{
        color: #FFF;
    }
}
//shipping--------------------------
.shipping_address >p{
    margin-bottom: 20px;
}
.shipping{
    .amount{
        @include rtl-float-right();
    }
    ul{
        li{
            padding: 2px;
            label{
                text-transform: capitalize;
                font-weight: 400;
            }
        }
        list-style: none;
        label{
            display:block;
            font-size: 14px;
            min-width: 100px;
            text-align: left;
            position: relative;
            &:before{
                border-radius: 50%;
                height: 12px;
                @include rtl-left(-20px);
                top: 5px;
                width: 12px;
                background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
                border: 1px solid #ccc;
                content: " ";
                cursor: pointer;
                display: block;
                position: absolute;
            }
        }
    }
    .shipping_method{
        visibility: hidden;
        display: none;
        &:checked + label::before {
            border-color: #282828;
            border-width: 2px;
        }
    }
    .shipping-calculator-button{
        color:$color-title;
        padding-top: 15px;
    }
    select,input{
        width: 100%;
    }
}
//totals page cart .
.sok-shipping{
    background: rgb(248, 248, 248);
    border:1px solid #e7e7e7 ;
    margin-bottom: 15px;
}
.woocommerce-shipping-methods{
    margin-bottom: 15px;
}
.cart-collaterals{
    background: rgb(248, 248, 248);
    border:1px solid #e7e7e7 ;
    .select2-container--default .select2-selection--single{
        border: 1px solid #ebebeb;
        background: #fff;
        height: 50px;
        border-radius: 0;
    }
    .select2-container--default .select2-selection--single .select2-selection__rendered{
        line-height: 50px;
    }
    .select2-container--default .select2-selection--single .select2-selection__arrow{
        height: 50px;
    }
    .title-cart{
        color: $color-title;
        padding: 20px;
        margin: 0;
        text-transform: uppercase;
        font-size: 18px;
        border-bottom:1px solid #e7e7e7 ;
    }
    .shop_table{
        tr > *{
            padding: 20px;
        }
        tr > th{
            font-weight: 400;
        }
        .cart-subtotal > td{
            @include rtl-text-align-right();
        }

        .order-total{
            color: $color-title;
            th{
                font-weight: 500;
            }
            .amount{
                @include rtl-float-right();
                font-weight: 500;
            }
        }
    }
    .wc-btn-updatecart,.wc-proceed-to-checkout{
        display: block;
        padding:0  40px;
        margin-bottom: 10px;
    }
}
.cart_totals{
    padding-bottom: 20px;
}


/**
 * Contact form
 */
.contact{
    .fa{
        color: $theme-color;
        font-size: 18px;
        width: 30px;
    }
}
/**
 * error 404
 */
.error-404 ,.no-results{
    padding: 0px 0 80px 0;    
    text-align: center;
    .page-title,.title-page{
        margin-top: 150px;
        margin-bottom: 10px;
        font-size: 54px;
        color: $color-title;
    }
    .page-header{
        background: transparent;
        padding: 0;
        border: none;
        &:after{
            background: transparent;
        }
    }
    .searchform{
        display: inline-block;
        padding: 0;        
        .input-group{
            max-width: 500px;
        }
        .form-control{
            width: 100%;
            height: 50px;
            min-width: 400px;
        }
        button{
            height: 50px;
            width: 50px;
            padding: 0;
            line-height: 50px;            
        }
    }
    .page-content{
        margin-top: 0px;
    }
}

 .login-wrap{
    .login{
        .form-row{            
            &:nth-child(3n+0){
                margin: 0 0 30px 0;
                @include rtl-float-left();                 
                .checkbox-style {
                    position: relative;
                    @include rtl-padding-left(20px);
                    input[type="checkbox"]{
                        margin-top: -1px;
                        margin-bottom: 0;                        
                        @include vertical-align(absolute);
                        @include rtl-left(0);
                    }
                }               
            }
        }
    }
}

/**
 * Thank you
 */

.woocommerce-order-received{
    .page-title{
        display: none;
    }
    .thankyou-order-details{
        text-align: center;
    }
    ul.order_details{
        display: inline-block;
        margin-top: 20px;
        margin-bottom: 20px;
        list-style: none;
        padding: 0;
        li{
            padding: 30px;
            @include rtl-float-left();
            @include rtl-text-align-left();
            color: #777777;
            strong{
                margin-top: 10px;
                color: $black;
                font-weight: 400;
                display: block;
            }
        }
    }

}
.thankyou-order{
    text-align: center;
    padding-top: 30px;
}

.woocommerce-thankyou-order-received{
    font-size: 18px;
    text-align: center;
    position: relative;
    color: #03a678;
    &:before{
        position: relative;
        font-family:"ionicons";
        content: "\f373";
        @include rtl-margin-right(10px);
        color: #03a678;
        font-size: 30px;
    }
}
.thankyou-order-payment-details{
    max-width: 768px;
    margin: auto;
    > p{
        font-size: 14px;
        margin-bottom: 30px;
    }

    .wc-item-meta{
        margin-top: 15px;
    }
    .woocommerce-order-details{
        border: 1px solid #ddd;
        background: #fafafa ;
        margin-bottom: 30px;
        border-radius:3px;
        h2{
            margin: 0;
            font-size: 28px;
            font-weight: 300;
            padding:20px;
            border-bottom: 1px solid #ddd;
        }
        .order_details{
            margin: 0;
        }
        .product-total{
            @include rtl-text-align-right();
        }
        tr >*{
            padding: 20px;
        }
        thead{
            th{
                border-bottom: 1px solid #e7e7e7;
            }
        }
        tfoot >tr:last-child{
            .amount{
                font-weight: bold;
            }
        }

    }
    .woocommerce-customer-details{
        h2{
            padding:15px 0;
            margin: 60px 0 20px;
            font-size: 24px;
            font-weight: 300;
            border-bottom: 1px solid #ddd;
        }
    }
    .order_heading{
        padding: 20px;
        margin: 0;
        border-bottom: 1px solid #e7e7e7;
    }
}
.customer_details{
    tr{
        > *{
            border: none;
            vertical-align:middle;
        }
    }
}


/**
* Wishlist
*/
.woocommerce{
    table{
        &.wishlist_table{
            border: 0;
            empty-cells: hide;
            margin-bottom: 0;
            margin-top: 50px;            
            tr{
                th,td{
                    padding: 24px 0;
                }
                th {
                    padding-right: 24px;
                }                
            }           
            thead{
                background-color: transparent;
                border: none;        
                tr{
                    th{
                        border-top: none;
                        padding-top: 0;
                        line-height: 1;
                        font-size: 18px;
                        min-width: 160px;
                        border-color: $color-border;                        
                    }
                }                                
            }
            tbody{
                background-color: transparent;
                border: none;                    
                tr{
                    td {
                        padding-right: 24px;  
                        border-color: $color-border;                            
                        &.product-add-to-cart{
                            text-align: right;
                            padding-right: 0;
                            a{                                                                        
                                margin: 0;
                                padding: 10px 24px;
                                color: $white;                                
                                height: auto;
                                width: auto;
                                background: $color-title;
                                border-color: $color-title;
                                letter-spacing: 0;        
                                font-size: 12px;        
                                text-transform: uppercase;                
                                display: inline-flex !important;
                                @include justify-content(center);
                                @include align-items(center);
                                @include border-radius(0 !important);  
                                @include transition(all 0.2s ease);                                       
                                &:hover,&:focus,&:active{
                                    color: $color-title;
                                    background: $white;
                                    border-color: $color-title;
                                }
                            }
                        }               
                        &:last-child {
                            padding-right: 0;
                            text-align: right;
                        }
                    }                                                
                    &:last-child {
                        border-bottom: none;                        
                    }
                }                                        
            }                      
            tfoot{
                border: none;
                background: transparent;
                tr{
                    border: 0;
                }
                td {
                    padding-top: 0;
                    padding-bottom: 0;                        
                }
            }                                     
            tr td, 
            tr th.wishlist-delete, 
            tr th.product-checkbox{ 
                vertical-align: middle;
                @include rtl-text-align-left();
            }
            td.product-thumbnail{
                a {
                    max-width: 90px;
                    width: 90px;
                }
            }                 
            td.product-price{
                font-size: 14px;
                del{
                    outline-style: none;
                }
                ins{
                    text-decoration: none;
                    padding: 0 0 0 5px;
                    color: $theme-color;
                    font-size: 16px;
                    font-weight: 500;
                }
            }                 
            td.product-stock-status{
                span.wishlist-in-stock {
                    color: $brand-success;
                    font-weight: 500;
                    font-size: 16px;
                }
            }                 
            &.cart{
                a.remove{                        
                    color: $color-title !important;
                    font-size: 18px;
                    margin: 0;
                    font-weight: 500;
                    border: 1px solid $color-border !important;
                    background: transparent !important;
                    @include rtl-padding-left(0);
                    @include border-radius(100px); 
                    @include flexbox();
                    @include justify-content(center);
                    @include align-items(center);
                    @include square(32px);
                    @include transition(all 0.2s ease);
                    &:hover{
                        background: $color-title !important;
                        color: $white !important;
                        border-color: $color-title !important;
                    }
                }
            }
        }
    }
    #content{
        table{
            &.wishlist_table{
                &.cart{
                    a.remove{
                        margin: 0;
                    }      
                }                 
            }            
        }        
    }   
    form{
        &.track_order{
            width: 100%;
            max-width: 500px;
            margin: 0 auto;
            padding-top: 44px;
            .clear{
                + .form-row{                
                    margin-bottom: 0;
                }
            }
            .form-row{                
                button[type="submit"]{
                    margin-top: 30px;
                    width: 100%;
                    height: 60px;                
                    font-weight: 500;
                    font-size: 16px;                
                    @include border-radius(0);                    
                }                   
            }            
        }
    }  
}

.about-heading{
    max-width: 480px;
}

/**
 * panel
 */
.panel{
    box-shadow: none;
}
.panel-heading >*,.panel-title{
    font-size: 18px;
    text-transform: capitalize;
}
.panel-body{
    padding: 30px 0;
    .form-row{
        margin-bottom: 30px;
    }
}

.edit {
    font-size: 16px;
    text-transform: capitalize;
}


//Login
.woocommerce .login,.woocommerce .register{
    input.form-control{
        height: 60px;
        padding: 0;
        margin-bottom: 15px;
        background:#FFF;
    }
    p{
        margin-bottom: 15px;
    }
}
.logined {
    .author-img{
        display: inline-block;
        vertical-align: top;
    }
    .name-account{
        font-size: 14px;
        vertical-align: top;
        padding-left: 30px;
        padding-top: 0;
        padding-bottom: 0;
        display: inline-block;
        strong{
            font-weight: 500;
            font-size: 16px;
            line-height: 16px;
            padding: 0;
            display: block;
        }
    }
    ul{
        list-style: none;
        padding: 0;
        li{
            font-size: 14px;
            line-height: 30px;
            padding: 15px 0;
            border-bottom: 1px solid #eee;
            &.is-active{
                a{
                    color:  $color-title;
                }
            }
            a{
                color: $font-color;
                &:hover{
                    color: $color-title;
                }
            }

        }
    }
}
.woocommerce  {
    #loginTab{
        border: none;
        .title-login{
            padding-right: 60px;
        }
    }
    .title-login,.title-register{
        display:inline-block;
        margin-bottom: 0;
        font-size: 36px;
        line-height: normal;
        font-weight: 500;
        padding: 0;
        margin-right: 0;
    }
    li.nav-item{
        color: #b0b0b0;
        a{
          color: #b0b0b0;  
        }
        &.active > a ,&:hover > a,&:focus > a{
            color: $color-title;
        }
    }
    .lost_password {
        margin-bottom: 0 !important;
        height: 24px;
        @include flexbox();
        @include align-items(center);
    }
}
.woocommerce-account{
    .page-title{
        display: none;
    }
    .name-account{
        strong{
            text-transform: capitalize;
            font-size: 16px;
        }
    }
    ul{
        list-style: none;
        padding: 0;
        li{            
            line-height: 30px;
            &.is-active{
                a{
                    color:  $color-title;
                }
            }
            a{
                color: $font-color;
                &:hover{
                    color: $color-title;
                }
            }

        }
    }
}
.myaccount-login{
    width: 100%;
    max-width: 500px;
    margin:0 auto;
    padding-top: 44px;    
    .nav{
        margin-bottom: 44px;
    }
    .btn{
        margin-top: 30px;
        width: 100%;
        height: 60px;
        border-radius: 0;
        font-weight: 500;
        font-size: 16px;
    }
}

.mo_image_id{
    display: none;
}

.lost_reset_password{
    text-align: center;
    max-width: 520px;
    margin: 42px auto;
    .form-row{
        &:last-child{            
            margin-bottom: 0;            
        }
    }
    input[type="submit"] {
        height: 60px;    
        padding: 20px 40px;
    }    
}
.button-offcanvas{
    position:absolute;
    visibility: hidden;
    opacity: 0;
}
//cross-sells
.cross-sells{
    margin-top: 50px;
    text-align: center;
}

//Quick View
.mfp-wrap #sok-quickview {
    display: block;
}
#sok-quickview {
    background: #fff none repeat scroll 0 0;
    margin: 0 auto;
    max-width: 1080px;
    position: relative;
    .slick-slide img{
        width: 100%;
    }
    .entry-summary{
        margin: 50px;
        padding: 0;
    }
    .product-detail-wrap .cart{
        padding-bottom: 0;
    }
    .mfp-close{
        top:0 !important;
        font-size: 14px;
    }
    .flickity-prev-next-button{
        background: none;
        border-radius: 0;
        font-size: 14px;
        opacity: 0;
        width: 24px;
        height: 24px;
        @include transform(0.4s);
        &:focus{
            box-shadow: none;
            outline: none;
        }
    }
    .na-product-image:hover .flickity-prev-next-button{
        opacity: 1;
        @include transform(0.4s);
    }
    .flickity-prev-next-button:disabled{
        display: none;
    }
    .full-image{
        width: 100%;
    }
    .slide >img{
        width: 100%;
    }
    .woocommerce-product-rating{
        display: none;
    }
    .price{
        color: $theme-color;
        font-size: 24px;
        font-weight: 500;
        line-height: 36px;
        margin-bottom: 0;
        padding: 25px 0;
    }
    .stock{
        border: 1px solid #00bc0d;
        border-radius: 3px;
        color: #00bc0d;
        display: inline-block;
        font-size: 12px;
        padding: 2px 10px;
    }
    .description{
        clear: both;
        color: #555555;
        display: block;
        font-size: 14px;
        line-height: 24px;
        padding: 10px 0 20px;
        position: relative;
    }
    .reset_variations{
        font-size: 12px;
        position: absolute;
        @include rtl-right(50px);
        top: 35px;
    }
    .cart{
        @include clearfix();
        .quantity{
            width: 100px;
        }
    }
    .product_meta{
        clear: both;
        margin: 0;
        padding-top: 10px;
    }
    .product_meta > *{
        color: #000000;
        display: block;
        font-weight: 500;
        padding: 5px 0;
    }
}

.product-image.loading{
    &::after{
        background: white none repeat scroll 0 0;
        color:$theme-color;
        content: "";
        height: 100%;
        @include rtl-left(0px);
        opacity: 0.6;
        position: absolute;
        top: 0;
        width: 100%;

    }
    &::before{
        animation: 0.8s ease 0s normal none infinite running dotFade;
        background: $theme-color none repeat scroll 0 0;
        border-radius: 49%;
        bottom: 0;
        color: $theme-color;
        content: "";
        height: 12px;
        left: 0;
        margin: auto;
        opacity: 1;
        position: absolute;
        right: 0;
        top: 0;
        width: 12px;
        z-index: 99;
    }
}
.mfp-bg{
    z-index: 10429;
}
.mfp-wrap{
    z-index: 10439;
}
.mfp-close{
    i{
        pointer-events:none;
    }
}
//Dashboard
.name-account{
    padding: 20px 0 10px;

}
.my-account{
    margin-bottom: 20px;
    .avatar > img{
        border-radius: 100%;
    }
}
.sidebar .btn-logout{
    font-size: 12px;
    color: #8b8b8b;
    text-decoration: underline;
}
.woo-dashboard{
    border-radius: 5px;
    font-size: 16px;
    line-height: 28px;
    min-height: 500px;
    padding: 0px;
    .woocommerce-Button{
        @include rtl-margin-right(10px);
    }
    .shop_table .order-total{
        background: none;
    }
}
.is-active >a{
    color: $theme-color;
}

.cart-empty{
    margin-top: 42px;
    text-align: center;
    + .return-to-shop{
        text-align: center;
        float: none;
        width: 100%;
        .wc-backward{
            width: auto;
            display: inline-block;
            padding: 20px 30px;
            line-height: normal;
            height: auto;
        }
    }
}


//contact
.fa-map-marker,.fa-phone,.fa-envelope-o{
    @include rtl-margin-right(10px);
    color: #000;
}
//preview
.preview{
    .wpb_single_image{
        overflow: visible;
    }
    .group-btn-image {
        background: white none repeat scroll 0 0;
        border-top: 1px solid #ebebeb;
        bottom: 0;
        color: black;
        font-size: 16px;
        padding: 24px;
        position: absolute;
        text-align: center;
        text-transform: uppercase;
        width: 100%;
    }
    .no-effect{
        box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
        position: relative;
        &:hover{
            box-shadow: 0 10px 20px 0 rgba(168, 182, 191, 0.6);
        }
    }
}

.woocommerce-additional-fields{    
    textarea{
        height: 100px;
    }
}
.post-item.page{
    .article-meta{
        display: none;
    }
}
// FAQ
.vc_custom_heading{
    &.custom_heading_faq{
        margin: 0;
        padding: 48px 0 0 0;
    }
}
.wpb_text_column{
    &.text_column_faq{
        margin: 0;
        padding: 43px 0 0 340px;
    } 
}
.page {
    #comments, #review_form,#comments .heading, #review_form .heading{
        margin-top: 50px;
    }
}