//Style Footer
.scrollup {
    border-radius: 100%;
    color: #151515 !important;
    cursor: pointer;
    height: 50px;
    left: 0;
    margin: auto;
    padding: 2px;
    position: absolute;
    right: 0;
    font-size: 14px;
    text-align: center;
    text-transform: uppercase;
    top: -30px;
    width: 50px;
    z-index: 999;
    background: white;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.13);
    line-height: 46px;
    &:hover{
        background: $theme-color;
        color: #FFF !important;
    }
}

#na-footer {
    color:$color-title;
    font-size: 16px;
    line-height: 32px;
    .coppy-right {
        @include rtl-text-align-right();
        p{
            margin: 0;
        }
    }
    .footer-bottom {
        a {
            color: $color-black;
            font-weight: 500;
            &:hover{
                color: $theme-color;
            }
        }
    }
    .share-links {
        .social-item {
            margin-bottom: 0;
            padding: 4px 7px;
            width: initial;
            a{
                i {
                    font-size: 18px;
                    color: $color-meta;
                    &:hover {
                        color: $color-black;
                    }
                }
            }
            &:first-child {
                padding-left: 0;
                a {
                    width: initial;
                    margin-left: 0;
                }
            }
        }
    }
    .widget{
        margin-bottom: 80px;
        &:last-child{
            margin-bottom: 0;
        }
    }
    .widgettitle {
        font-size: 26px;
        font-weight: 500;
        margin: 0;
        margin-bottom: 35px;
    }
    a{
        color:$color-title;
        &:hover{
            color:$color-title;
        }
    }
    ul{
        padding: 0;
        list-style: none;
        line-height: 30px;
        font-size: 16px;
        color: $color-meta;
        li{
            line-height: 40px;
        }
    }
    .social-icons{
        padding: 0;
        margin: 0;
        li{
            margin: 0;
        }
    }
    .footer-top {
        text-align: center;
        .widgettitle{
            font-size: 34px;
            font-weight: 500;
            margin-bottom: 20px;
        }

    }
    .footer-center{
        border-top: 1px solid $color-border;
        padding-top: 100px;
        padding-bottom: 80px;
    }
    .footer-top + .footer-center{
        border-top:none;
    }

    //footer-bottom
    .footer-bottom {
        background: $footer-bg-bottom;
        border-top: 1px solid $color-border;
        font-size: 16px;
        padding: 30px 0;
    }
}


.footer-bottom-left{
    line-height: 30px;
}
.footer-bottom-right{
    @include rtl-text-align-right();
    .widget{
        display: inline-block;
    }
}
.footer-bottom-center {
    text-align: center;
}
//hidden footer
.sok-footer-hidden{
    .page-content{
        margin-bottom: 0;
    }
}

.sok-footer-2{
    .site-content{
        margin-bottom: 210px;
        z-index:2;
        background: #FFF;
    }
}
.footer-2{
    background: #FFF;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
}