//Style page Product Detail

.detail-content{
    padding-bottom: 100px;
}
/**
* Title Product
*/
.single-title-product{
    display: inline-block;
    position: relative;
}
.product_title{
    font-size: 46px;
    line-height: 59px;
    font-weight: 500;
    margin-bottom: 0;
    padding-top: 0;
}
.detail-rating {
    display: inline-block;
    span {
        line-height: 40px;
        font-size: 12px;
    }
}

/**
* Detail-wrap Product
*/

.product-detail-wrap{
    margin-bottom: 150px;
    .woocommerce-review-link,.woocommerce-write-review-link{
        display: none;
    }
    .woocommerce-noreviews{
        font-size: 16px;
        line-height: 30px;
        margin-bottom: 0;
        font-style: italic;
    }
    .woocommerce-product-rating{
        margin: 0;
    }
    .review-link,.noreview{
        display: none;
    }


    /**
    * Sale
    */
    .single-title-product{
        .onsale{
            border:none;
            top: -30px;
        }
    }
    .onsale{
        top:-10px;
        font-size: 14px;
        @include rtl-right(-35px);
        @include rtl-left(auto);
        background: transparent;
        color: $theme-color;
        text-transform: capitalize;
    }
    .onhot{
        top:10px;
        @include rtl-right(40px);
    }

    /**
    * Availability
    */
    .availability{
        display: none;
    }

    /**
    * Thumbnails
    */
    .na-product-image{
        position: relative;
    }
    .product-thumbnails{
        @include rtl-text-align-left();
        padding:0;
        list-style: none;
        margin: 0 -5px;
    }

    .thumbnails{
        padding: 10px 50px !important;
        position: relative;
        display: block;
        li{
            text-align: center;
            margin: 5px;
        }
        #slider-prev,#slider-next{
            background: none !important;
            &:before{
                font-family: 'FontAweSome';
                text-align: center;
                height: 26px;
                border: $border-color-base;
                border-radius: 100%;
                display: block;
            }
        }
        #slider-prev{
            @include rtl-left(15px !important) ;
            &:before{
                content: '\f104';
            }
        }
        #slider-next{
            right:15px !important;
            &:before{
                content: '\f105';
            }
        }
    }

    /**
    * Price Product
    */
    .price{
        font-size: 26px;
        color: $color-black;
        padding:20px 0 30px;
        line-height: 36px;
        margin-bottom:0;
        font-weight: 400;
        del{
            font-size: 18px;
            color: $color-meta;
            font-weight: 300;
            line-height: 36px;
            @include rtl-padding-right(5px);
        }
        ins{
            line-height: 36px;
        }
    }
    // Select option price
    .single_variation_wrap{
        .single_variation{
            display: block;
        }
        .price{
            border-top:none;
            border-bottom:none;
            padding: 0;
            margin: 0;
        }
    }

    /**
    * Description Product
    */
    .product-data-tabs{
        padding-top: 30px;
        max-width: 480px;
    }
    .show-detail,.show-reviews{
        display: block;
        padding: 20px 0;
        cursor: pointer;
        position: relative;
        border-bottom: 1px solid #dddddd;
        &:after{
            position: absolute;
            top:23px;
            line-height: 28px;
            right: 0;
            content: "\f3d6";
            font-family: "Ionicons";
            font-size: 30px;
            color: $color-title;
        }
        &.open{
            &:after {
                content: "\f3d4";
            }
        }
    }
    .single-description-product{
        .single-description-product-inner{
            font-size: 16px;
            line-height: 30px;
            display: none;
            padding: 30px 0;
            > {
                h2,h3,h4,h5,h6{
                    font-weight: 500;
                    font-size: 20px;
                    margin-top: 0px;
                }
            }
            a{
                box-shadow: 0 -1px 0 #ccc inset;
                &:hover {
                    box-shadow: none;
                }
            }
            ul,ol{
                margin-bottom: 30px;
            }

        }
        .show-detail{
            padding-left:0px;
            font-size: 20px;
            font-weight: 300;
            color: $color-title;
        }

    }
    .single-comment-product{
        .single-comment-product-inner{
            display: none;
        }
        .show-reviews{
            padding-left:0px;
            font-size: 20px;
            font-weight: 300;
            color: $color-title;
        }
    }


    ul{
        @include rtl-padding-left(15px);
    }
    .woocommerce-variation-description{
        clear: both;
        position: relative;
        display: block;
        font-size: 14px;
        line-height: 24px;
        color: $font-color;
        p{
            margin: 0;
        }
    }

    /**
    * Button after Quantity
    */
    .cart{
        position: relative;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        margin-bottom: 20px;
        margin-top:0px;
        padding-top: 30px;
        max-width: 480px;
    }
    .button-groups{
        opacity: 1;
    }

    .variations tbody tr:last-child{
        height: 0;
        *{
            line-height: 0;
            padding: 0;
        }
    }

    /**
    * Button wishlist-buttom
    */
    .wishlist-buttom{
        .feedback{
            display: none;
        }
    }
    /**
    * Button after Quantity
    */
    .zoom-image{
        width: 100%;
        height: auto;
    }

    /**
    * Meta Product
    */
    .product_meta{
        clear: both;
        padding-top: 10px;
        margin: 0;
        >*{
            display: block;
            padding: 5px 0;
            color: $color-title;
            font-weight: 400;
            span,a{
                color: $font-color;
                font-size: 16px;
                font-weight: 300;
                &:hover{
                    color: $theme-color;
                }
            }
        }
    }
    .product-share-wrap{
        margin-top: 20px;
        display: inline-block;
        @include rtl-float-right();
        line-height: 36px;
        @include rtl-text-align-right();
         li:first-child {
            @include rtl-padding-left(0);
        }
    }
    .product-share{
        display: inline-block;
        a{
            padding-left: 10px;
        }
        [class*="ion-social-"]{
            font-size:20px;
            color: #bfbfbf;
            &:hover{
                color: $color-title;
            }
        }
    }


    //share
    .product_vertical + *{
        padding: 7px 0;
        .text-share{
            padding-top: 30px;
            padding-bottom: 15px;
            display: block;
            font-weight: 400;
            text-align: center;
            font-size: 16px;
            text-transform: uppercase;
            color: #bdbdbd;
        }
        .product-share{
            display:block;
            text-align: center;
            a{
                padding: 10px;
                color: #9d9d9d;
                .fa{
                    font-size: 20px;
                }
            }
        }
    }
    .product-share-padding+*{
        @include rtl-padding-left(16.777%);

    }
    .sok_list_att{
        list-style: none;
        color:$theme-color;
        @include rtl-padding-left(20px);
        margin: 0;
        label{
            color:$theme-color;
            font-weight: 400;
            margin-bottom: 0;
        }
        li{
            position: relative;
            &:before{
                content:'\f383';
                font-family:"ionicons";
                position: absolute;
                @include rtl-left(-20px);
                top:0;
                color:#82b440;
                font-size: 16px;
            }
        }
    }
}
.detail-breadcrumb{
    position: relative;

}

.product-nav {
    position: absolute;
    @include rtl-right(0);
    top:0;
    padding: 24px 15px;
    .nav-product {
        display: inline-block;
        padding:3px;
    }
    a {
        background: #c0c0c0 none repeat scroll 0 0;
        color:#fff;
        height: 24px;
        line-height: 24px;
        border-radius: 100%;
        text-align: center;
        width: 24px;
        display: block;
        &:hover {
            background: $theme-color;
            color: #ffffff;
        }
    }
}
.container-fluid .product-nav{
    @include rtl-right(75px);
}
/**
* Layout Product
*/
.product-layout-vertical {
    .product-detail-before{
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        z-index: 99;
        padding-left: 50px;
    }
    .product-detail-summary{
        padding:100px 85px 100px;
        z-index:0;
    }
    .na-product-image{
        padding-left: 0px;
        padding-right: 0;
    }
    .product-thumbnails +  .na-product-image{
        padding-left: 15px;
    }
    .gallery-cell {
        padding-bottom: 10px;
        width: 100%;
        a, img {
            width: 100%;
        }
        img {
            opacity: 1;
            border: 1px solid #f7f7f7;
            @include transition(0.4s);
        }
        .size-shop_thumbnail {
            display: block;
            height: auto;
        }
        &.slick-current img {
            opacity: 1;
            @include transition(0.4s);
        }

        &:last-child{
            padding-bottom:0;
        }
        &.slick-active:last-child{
            padding-top:0px;
        }

    }
    .slick-dots{
        bottom: 30px;
    }
    .slick-dots li button::before{
        color: #fff;
    }
    .slick-dots li.slick-active button::before {
        opacity: 1;
        color: #fff;
    }
}
.product-layout-grid {
    .summary-before{
        padding-left: 50px;
    }
    .product-detail-summary{
        padding:100px 85px 100px;
        position: -webkit-sticky;
        position: sticky;
        top: 0;
    }
    .entry-summary{
        padding: 0;
        max-width: 480px;
    }
    .gallery-main{
        margin: 0 -5px;
    }
    .grid-thumbnail{
        width: 50%;
        padding: 0 5px 10px 5px;
        float: left;
    }
}

.product-layout-sticky{
    .sticky-product-detail{
        padding:150px 85px 100px;
        position: -webkit-sticky;
        position: sticky;        
        // z-index: 1050;
        top: 0;
    }
    .summary-before{
        .wishlist-buttom{
            position: absolute;
            right: 50px;
            top:25px;
        }
    }
    .entry-summary{
        position: relative;
        padding: 0;
        max-width: 480px;
    }
    .product-gallery-slider figure{
        margin-bottom: 0px;
    }
}


/**
* Quantity Product
*/

.text-quantity{
    @include rtl-float-left();
    @include rtl-margin-right(20px);
    line-height: 36px;
    margin-top: 20px;
}
form.cart .form-inline{
    display: inline-block;
}
.cart .quantity {
    @include rtl-margin-right(30px);
    cursor: pointer;
    position: relative;
    display: inline-block;
    background: transparent;
    .input-group {
        border:2px solid $theme-color;
        padding: 0;
    }
    input.qty {
        font-size: 18px;
        height: 56px;
        width: 50px;
        font-weight: 400;
        color: $color-title;
        @include appearance(textfield);
        text-align: center;
        border:none;
        position: relative;
        display: inline-block;
        border-left: none;
        border-right: none;
        background: transparent;
        @include box-shadow(none);
        &::-webkit-inner-spin-button, 
        &::-webkit-outer-spin-button { 
            @include appearance(none);
            margin: 0; 
        }
    }
    .input-group-addon{
        padding:5px 15px;
        background: transparent;
        color: $color-title;
        font-weight: 300;
        font-size: 20px;
        border:none;
        &.qty-minus{
            font-size: 30px;
        }
        &:hover{
            color: #000;
        }
    }
}
.grouped_form{
    .out-of-stock{
        padding: 0;
        background:none;
        color: #dd3333;
        margin: 0;
        font-size: 12px;
    }
    .product_type_simple{
        font-size: 14px;
    }
    .quantity .input-group{
        border: 1px solid #ddd;
    }
    table{
        border: none;
    }
    table > thead > tr, table > tbody > tr, table > tfoot > tr{
        border:none;
    }
    table > thead > tr > th, table > thead > tr > td, table > tbody > tr > th, table > tbody > tr > td, table > tfoot > tr > th, table > tfoot > tr > td{
        padding:5px 0;
    }
    .woocommerce-grouped-product-list-item__quantity{
        width: 150px;
    }
    .woocommerce-grouped-product-list-item__price{
        text-align: right;
    }
}
.product-detail-wrap .grouped_form{
    display: block;
    table > thead > tr > th, table > thead > tr > td, table > tbody > tr > th, table > tbody > tr > td, table > tfoot > tr > th, table > tfoot > tr > td{
        vertical-align: middle;
    }
    label{
        color:$color-title;
        vertical-align: middle;
        margin: 0;
        font-weight: 500;
        a{
            color:$color-title;
        }
    }
    del{
        text-decoration:none;
        display: none;
    }
    ins{
        text-decoration:none
    }
    .amount{
        color: $color-title;
        font-size: 16px;
    }
    .quantity{
        @include rtl-margin-right(0);
    }
    .single_add_to_cart_button{
        width: 100%;
    }
}
.group_table{
    width: 100%;
    .woocommerce-grouped-product-list-item__label{
        text-align: center;
    }
}

.pswp{
    z-index: 9999 !important;
}

/**
* Image Detail Product
*/
.product-gallery-slider{
    .slide{
        width: 100%;
        display: inline-block;
    }
}

/**
* Table Product
*/

//tab-reviews
#reviews {
    #comments{
        position: relative;
        display: block;
        margin-top: 40px;
        margin-bottom: 40px;
        border: none;
        clear: both;
    }
    .commentlist{
        padding: 0;
        margin: 0;
    }
    #review_form_wrapper{
        position: relative;
        display: block;
    }
    .comment-respond{
        margin-top: 60px;
    }
    input[type="submit"]{
        letter-spacing: 1px;
        min-width: 200px;
    }
    #review_form{
        margin-top:0px;
        border: none;
        clear: both;
        .comment-form{
            padding-top: 30px;
        }
        p{
            margin-bottom: 0px;
        }
        .comment-notes{
            outline: thin;
            margin-top: 10px;
            & + .row{
                padding-top: 20px;
            }
        }
    }

    .comment-reply-title{
        margin-bottom: 30px;
        font-size: 20px;
        font-weight: 400;
        color: $color-title;
    }
    li{
        list-style: none;
        padding: 30px 0;
        border-top:1px solid #EEEEEE;
        &:first-child{
            border: none;
            padding: 0 0 30px ;
        }

    }
    .tall{
        color:#ececec;
    }

    .avatar{
        max-width: 50px;
        @include rtl-float-left();
        border-radius: 100%;
        @include rtl-margin-right(30px);
    }
    .description{
        font-size: 16px;
        line-height: 30px;
        @include rtl-padding-left(80px);
    }
    .star-rating{
        margin: 3px 0 0 10px;
        @include rtl-float-right();
    }
    label{
        font-weight: 400;
        color: $black;
        padding: 10px 0;
    }
    .bypostauthor .comment-text .meta >strong{
        color: $color-title;
        font-size: 16px;
        text-transform: capitalize;
    }
    .comment-form-rating{
        position: absolute;
        top: 0;
        @include rtl-right(0);
        label{
            padding: 0;
        }
    }

}


.variations_form.cart{
    display: block;
    padding-top: 0;
    .variations{
        margin-top: 15px;
        margin-bottom: 30px;
        tr{
            display:block;

            .value{
                padding:7px 0 5px;
                display: block;
            }
            &:nth-child(2n+1){


            }
            &:nth-child(2n){
                margin-bottom: 15px;
            }
            &:last-child{
                margin: 0;
            }
        }
        label{
            margin: 0;
        }
    }
    ul{
        list-style: none;
        padding: 0;
    }
    li + li {
        margin-top: 10px;
        position: relative;
    }
    .label{
        padding: 10px 0 5px;
        color: #222222;
        min-width:100px;
        font-weight: 400;
        @include rtl-text-align-left();
        display: inline-block;
        >label{
            color: #222222;
            font-size: 16px;
            font-weight: 400;
            text-transform: capitalize;
        }
    }

    .woocommerce-variation-description p{
        padding-bottom: 30px;
    }
    .reset_variations{
        padding-bottom:0px;
        display: block;
        text-align: right;
        padding-top: 15px;
        color: $theme-color;
        text-decoration: underline;
        text-transform: lowercase;
    }
    select{
        display: block;
        background:transparent;
        height: 50px;
        border: 1px solid #ddd;
        width: 100%;
        color: #a4a4a4;
        appearance: none;
        font-size: 14px;
        > option{
            padding: 5px 10px;
            border-bottom: 1px solid #ddd;

        }
    }
    .woocommerce-variation-price{
        padding:0px 0 40px;
    }
    .woocommerce-variation-description{
    }
    .att_label{
        text-align: center;
        line-height: 32px;
        font-weight: 400;
        font-size: 12px;
        cursor: pointer;
        &:hover,&.selected{
            background: $theme-color;
            color: #FFFFFF;
        }
    }
    .att_color{
        margin: 8px 5px -5px;
        cursor: pointer;
    }
    .att_img{
        margin:0 5px;
        display: inline-block;
        cursor: pointer;
        >img{
            border: 1px solid #ededed;
            height: 40px;
            padding: 3px;
            width: 40px;
        }
        &:hover,&.selected{
            >img{
                border: 1px solid $theme-color;
            }
        }

    }
    table{
        border: none;
    }
    table > thead > tr, table > tbody > tr, table > tfoot > tr{
        border: none;
    }
    .swatchtitlelabel{
        font-weight: 400;
        font-size: 16px;

    }
    label.wcva_single_textblock{
        border: solid 1px #fff !important;
    }
    label.selectedswatch.wcvaround{
        border: solid 1px #fff !important;
    }
    label.wcvaswatchlabel{
        width: 22px !important;
        height: 22px !important;
        outline:none !important;
        border: none !important;
        border-radius: 100%;
        position: relative;
        @include flexbox();
        @include justify-content(center);
        @include align-items(center);
        &.wcva_single_textblock{
            width: 30px !important;
            height: 30px !important;
        }
        &:before{
            opacity: 0;
            top: -3px;
            left: -3px;
            width: calc(100% + 6px);
            height: calc(100% + 6px);
            border: 1px solid #000;
            content: "";
            display: block;
            border-radius: 100%;
            text-align: center;
            position: absolute;
        }
    }
    label.wcva_single_textblock,label.selectedswatch.wcva_single_textblock{
        border-radius: 0;
        border: solid 2px #fff !important;
        background: #eee;
        color: black;
        width: 24px;
        line-height: 26px;
        font-size: 12px;
        font-weight: 300 !important;

    }
    label.selectedswatch{
        width: 22px !important;
        height: 22px !important;
        box-shadow: none !important;
        outline: none !important;
        border-radius: 100%;
        position: relative;
        &:before{
            opacity: 1;
            top: -3px;
            left: -3px;
            width: calc(100% + 6px);
            height: calc(100% + 6px);
            border: 1px solid #000;
            content: "";
            display: block;
            border-radius: 100%;
            text-align: center;
            position: absolute;
        }
        &.wcva_single_textblock{
            width: 30px !important;
            height: 30px !important;
            &:before{
                border-radius: 0%;
            }
        }
    }
}
#yith-quick-view-content{
    .wp-post-image{
        width: 100%;
        height: auto;
        display: block;
    }
}

//FIx element
.single-product{
    .modal-backdrop.in {     
        z-index: -1;
    }    
}

.single-product.woocommerce .thumbnails #slider-prev, .single-product.woocommerce .thumbnails #slider-next{
    width: 23px !important;
    height: 23px !important;
}
.product-detail-wrap .thumbnails #slider-prev::before,
.product-detail-wrap .thumbnails #slider-next::before,
.entry-summary .thumbnails #slider-prev::before,
.entry-summary .thumbnails #slider-next::before{
    width: 23px !important;
    height: 23px !important;
}
.yith_magnifier_gallery li a{
    margin-top: 0px !important;
}
.related.products-block,.related-wrapper .products-block{
    overflow: visible;
}

.thumbnails.slider {
    #slider-prev,#slider-next{
        position: absolute;
        top:50%;
        margin-top: -10px;
        cursor: pointer;
    }
}

#yith-wcwl-popup-message{
    line-height:24px;
    border-radius: 0;
}
.mfp-close{
    display: none !important;
}

.product-detail-wrap .gallery-cell.slick-current img{
        opacity: 1;
}
div.wcvaround:hover{
    border: solid 1px #828282 !important
}

.meta-sizeguide{
    .modal-header {
        padding: 0;     
    }
}