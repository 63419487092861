//style widget Service
.na-service{
    .block-content{
        margin: 0 -15px;
    }
    .service-box{
        padding: 0 15px;
    }
    .items-service{
        padding-left: 5%;
        padding-right: 5%;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
    }
    .col-item{
        float: none;
        -webkit-flex: 1 1 auto;
        -ms-flex: 1 1 auto;
        flex: 1 1 auto;
    }
    .entry-content-inner{
        max-width: 300px;
        text-align: center;
        margin: auto;
    }
    .service-box-inner{
        display: block;
        text-align: center;
        a{
            color: $color-title;
            font-size: 18px;
            line-height: 34px;
            font-weight: 300;
        }
    }
    .entry-image{
    }
    .title-box{
        margin-top: 20px;
        margin-bottom: 5px;
    }
}