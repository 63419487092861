//style widget Advanced Search Woo
.aws-container{
    .aws-search-field{
        height: 60px;
        padding: 10px 15px;
        background: #FFF;
        font-size: 16px;
    }
    .aws-search-form .aws-search-clear,.aws-search-form{
        height: 60px;
        line-height: 60px;
    }
}

.aws-search-result{
    border: none;
    margin-top: 30px;
    .aws_result_item{
        margin-top: 30px !important;
        width: 50%;
        float: left;
        border: none;
        &:nth-child(2n+1){
            clear: left;
        }
    }
    .aws_result_link{
        padding: 0;
    }
    .aws_result_image{
        text-align: center;
        width: 100%;
        display: block;
        float: none;
        img{
            width:auto;
            height: auto;
        }
    }
    .aws_result_content{
        padding-top: 15px;
    }
    .aws_result_title{
        font-size: 15px;
        line-height: 20px;
        font-weight: 500;
        color:$color-title;
        text-align: center;
    }
    .aws_result_excerpt{
        display: none;
    }
    .aws_result_price{
        text-align: center;
        visibility: visible;
        opacity: 1;
        font-size: 16px;
        color: $color-title;
        line-height: 30px;
        del{
            font-size: 14px;
            padding-right: 5px;
            color: #929292;
        }
    }
    .aws_result_sale{
        width: auto;
        right: 50px;
        top: 10px;
        padding-top: 0;

    }
    .aws_onsale{
        background: #e56568;
        border-radius: 100%;
        width: 40px;
        height: 40px;
        padding-top: 0;
        top:0;
        right:0px;
        line-height: 40px;
        transform: rotate(0deg);
    }
    .aws_result_item:hover, .aws-search-result .aws_result_item.hovered{
        background: transparent;
    }
    .aws_no_result{
        font-size: 26px;
        color: $color-meta;
        float: none;
        text-align: center;
        width: 100%;
    }

}