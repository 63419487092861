// Small screen < 767px-------------------------------------------------------------------------------------------------
@media (max-width: $screen-xs-max){
    .admin-bar{
        .canvas-warp{
            padding-top: 47px;
        }
        .canvas-header{
            position: relative;
        }
        .canvas-header .btn-close{
            right: -20px;
        }
    }
    .search-wrap .search-title{
        padding-top: 100px;
    }

    .na-cart{
        .icon-cart{
            .icon-basket {
                margin-right: 24px;
            }
            .mini-cart-items {                
                right: 10px;
                width: 18px;
                height: 18px;
            }
        }         
    }
    .page-header{
        padding: 50px 0;
    }
    .page-cart {
        padding-right: 15px;
    }
    .product-remove{
        a {        
            background: transparent;
            text-align: center;
            border: 2px solid $color-border;
            @include square(30px);
            @include flexbox();
            @include justify-content(center);
            @include align-items(center);
            @include transition(all 0.3s ease-in-out 0.3s);
            &:hover,&:active,&:focus{
                color: #FFF;
                background: $color-title;
                border-color: $color-title;
            }
        }        
    }
    .header-center .cart-wrap {
        margin-right: 5px;
    }
    .header-center .user-mini{
        margin-left: 5px;
    }
    .canvas-warp{
        padding: 30px;
        .form-row{
            margin-bottom: 30px;
        }
        .aws-container {
            margin-top: 40px;
        }
    }       
    .modal-header{
        &.btn-close-popup{
            .close {
                top: 70px;
                right: 0;
            }       
        }         
    }
    .site-header{
        padding: 0;
    }
    .entry-title {
        line-height: 70px;
    } 
    .form-submit {     
        text-align: center;
    }
    blockquote, 
    .wp-block-quote:not(.is-large):not(.is-style-large) {        
        padding-left: 0;
        padding-right: 0;
    }
    .vc_row{
        &.vc_custom_1550458406586 {
            padding-right: 0px !important;
            padding-left: 0px !important;
        }   
    }        
    .wpb_text_column{
        &.text_column_faq {        
            padding-top: 20px;
        }   
    }        
    .vc_column-inner{
        &.vc_custom_1550306465718 {
            padding-left: 30px !important;
            padding-right: 30px !important;
        }   
    }        
    .wpcf7-form{
        input.wpcf7-form-control {  
            margin-bottom: 15px;
        }
        .message-contact {
            margin-top: 1px;
        }
    }     
    .error-404 {
        padding: 0;
        .page-title{
            font-size: 42px;
            line-height: 60px;
        }
    }
    .cats-block{
        .product-category{
            .woocommerce-loop-category__title {                
                font-size: 16px;
                padding: 15px;
                right: 0;
                bottom: 0;
                left: 0;
                margin: 0;
                line-height: normal;
                height: auto;
            }
        }         
    }     
    .header-center{
        .searchform-mini {
            left: 20px;
            @include square(30px);
        }
        .user-mini {
            left: 60px;
            @include square(30px);
        }
    }
    .pagination {
        margin-bottom: 60px;
    }
    .nano-intro{
        .content-box {        
            padding-left: 40px;
            padding-right: 40px;
            margin: 0 !important;
        }
    }
    .page-content{
        padding-bottom: 100px;        
    }              
    .mobile-mini {
        width: 26px;    
    }
    .btn-mini-search {
        font-size: 22px;
    }
    .site-description{
        display: none;
    } 
    .wpb-js-composer{
        .vc_tta-accordion{
            &.vc_tta{
                &.vc_general{
                    .vc_tta-panel-body {
                        padding: 0px 30px 40px 30px;
                    }                
                }                 
            }
            &.accordion_faq {
                padding: 50px 15px 0 15px;
            }   
        }        
    }     
    .block{
        &.nano-intro {         
            margin-top: 80px;
        }
    }    
    .about-heading {
        max-width: none;
        padding: 0 15px;
        font-size: 22px !important;
        line-height: 38px !important;
        text-align: center;
        margin: 0 auto;
    }
    .wpb_images_carousel{
        margin-top: 0;
        .vc_carousel-slideline{
            .vc_item{
                .vc_inner {
                    padding-left: 0;
                    padding-right: 0;
                }
            }             
        }         
    } 
    .nano-infoTeams{
        .block-content {
            margin: 0 -15px;
            .box-team-list {            
                padding: 0 15px;
                .box-content {
                    margin: 0 15px;
                }
            }
        }
    } 
    .products-block{
        li {
            &:nth-child(2n + 1){
                clear: left;                
            }
        }
        .col-item{
            &:nth-child(2n+1){
                padding-right: 7.5px;
                clear: left;
            }   
        }        
        .product-col{
            &:nth-child(2n+1){
                padding-right: 7.5px;
                clear: left;
            }            
        }        
    }     
    .block-products-widgets{         
        .widgetcontent {
            margin-top: 30px;
        }    
        .items-duel{
            .col-item {
                padding-top: 30px;
            }
        } 
        &.block-duel{       
            .block-title {    
                padding-left: 30px;
                padding-right: 30px;
            }                     
            .box_des {
                padding-left: 35px;
                padding-right: 35px;
            }
            .product-cat-duel{
                &.products-block {
                    margin-bottom: 50px;
                }
            }            
        }         
    }
    .product-cat-duel{
        &.col2{
            .col-item{
                &:nth-child(2) {
                    padding-top: 0;                    
                }
                &:nth-child(2n) {
                    padding-left: 7.5px;
                }                    
            }            
        }         
    }    
    .product-block{
        margin-bottom: 30px;
    }
    .newsletters{        
        .group-input-newsletter{
            width: 70%;            
        }
    }
    .slicknav_menu{
        .slicknav_icon-bar{            
            @include box-shadow(none); 
        }
    }  
    .na-service{
        .col-item {
            padding: 0 0 10px 0;
            @include flex(auto);
        }
        .block-content{
            margin: 0;
        }
        .items-service {        
            padding-left: 0;
            padding-right: 0;
            @include flex-direction(column);     
        }
        .service-box {
            width: 100%;
            max-width: 100%;
            padding: 0 15px;
            @include flex-basis(100%);
            &:last-child{
                padding-bottom: 0;
                margin-bottom: 0;
            }
        }
        .service-box-inner {
            padding: 10px 0px;
        }   
    }     
    //slider
    #slider-showcase_wrapper{
        height: calc(100vh - 350px) !important;
    }
    .rtl [class*="col-"]{
        float: none;
    }
    //category
    .blog-column-center-layout {
        .post-item {
            margin: 0 5px;
        }
    }
    .nano-textBlock{
        margin-top: 60px;        
        .content-box {
            padding: 30px 0px 0px 0;
        }
    }          
    .slicknav_btn{
        top:50%;
        margin-top:-20px !important;
    }
    .woocommerce-result-count{
        display: none;
    }
    .wrapper-content-product{
        visibility: hidden;        
        @include opacity(0);        
    }
    .cart-bottom {     
        padding-bottom: 85px;
        padding-left: 30px;
        padding-right: 30px;
    }
    .cart_list {
        max-height: 360px;
        margin-bottom: 0;        
    }
    .cart_list li .cart-image{
        width: 40%;
    }
    .cart_list li .cart-main-content{
        width: 60%;
    }
    .cart-panel {     
        padding: 30px;
        min-height: calc(100vh - 50px);
    }
    .na-cart .total{
        padding: 30px 0px 0;
    }
    .cart-bottom .buttons{
        margin: 10px 0 0;
    }

    .page-title{
        font-size: 64px;
        line-height: normal;
    }
    .woocommerce{
        table.shop_table.cart{
            + .return-to-shop {
                margin-bottom: 20px;
            }
            .product-name {
                text-align: left;        
            }
            thead > tr > th{
                &.product-subtotal{
                    text-align: right;
                }
                &.product-remove{
                    display: none;   
                }
            }
        }  
        table{
            &.wishlist_table{
                margin-bottom: 0;
                thead{
                    tr{
                        @include flexbox();
                        @include align-items(center);
                        @include justify-content(flex-start);
                        th{         
                            border: 0;
                            padding-right: 12px;                                    
                            @include flex-grow(1);
                            @include flexbox();                                                         
                            &.download-remaining,
                            &.product-thumbnail,
                            &.product-stock-status,
                            &.product-remove,
                            &.product-add-to-cart,
                            &.woocommerce-orders-table__header-order-date{                      
                                display: none;
                            }   
                            &.product-name{
                                padding-left: 0;
                                @include justify-content(center);   
                            }
                            &.product-price{
                                padding-right: 0;
                                @include justify-content(center);   
                            }                           
                        }
                    }               
                } 
                tbody{
                    tr{
                        position: relative;
                        @include flexbox();
                        @include align-items(center);
                        @include justify-content(flex-start);
                        td{         
                            @include flex-grow(1);
                            @include flexbox();                 
                            &.download-remaining,
                            &.product-stock-status,
                            &.woocommerce-orders-table__cell-order-date{
                                display: none;
                            }                            
                            &.product-remove{
                                right: 0;
                                padding-right: 40px;
                                padding-left: 0;
                                @include vertical-align(absolute);
                            }
                            &.product-name{
                                @include flexbox();
                            }
                            &.product-add-to-cart{                          
                                a {                             
                                    padding: 0;
                                    overflow: hidden;
                                    color: transparent;
                                    background: white;
                                    border-width: 2px;
                                    position: relative;
                                    border-color: $color-border;
                                    @include square(32px);                                                                      
                                    @include border-radius(100px !important);       
                                    &:after{
                                        content: '\f370';
                                        font-family: Ionicons;
                                        color: $gray;
                                        font-size: 16px;
                                        @include center-box(absolute);                                            
                                    }
                                    &:hover,&:active,&:focus{
                                        color: transparent;
                                        background: $color-title;
                                        border-color: $color-title;
                                        &:after{
                                            color: #FFF;
                                        }
                                    }                                                       
                                }
                            }   
                            &.product-name,&.product-price{                             
                                max-width: 50%;                                 
                                @include flex-basis(50%);                                   
                            }                               
                            &.product-remove,.product-add-to-cart,&.product-name,&.product-price{
                                @include flex-grow(0);
                                @include justify-content(flex-start);   
                            }               
                            &.product-add-to-cart{
                                @include justify-content(flex-end); 
                            }                           
                        }                   
                    }               
                }                   
            }
        }   
        form{
            &.track_order {
                p{
                    &:first-child{
                        margin-bottom: 30px;
                    }
                }
            }
        }
    }
    .product-layout-sticky{    
        .sticky-product-detail {
            padding: 60px 15px 80px;
        }
        .entry-summary, 
        .product-data-tabs {  
            padding-left: 15px;
            padding-right: 15px;            
        }
    }     
    .product-layout-vertical,
    .product-layout-grid{
        .product-detail-summary {
            padding: 60px 15px 80px;
            .entry-summary,
            .product-data-tabs {  
                padding-left: 15px;
                padding-right: 15px;
            }
        }
    }     
    .meta-sizeguide{
        .modal-body {
            padding-left: 30px;
            padding-right: 30px;
            position: relative;
            @include square(100%);
            .modal-inner {              
                margin: 0;  
                @include vertical-align(absolute);
            }
        }        
    }     
    .product-detail-wrap{
        .entry-summary{
            padding: 0px 15px 0px;
        }
        form.cart{
            .form-inline {
                @include flexbox();
            }
        }         
        .onsale {
            right: 0;
        }
    }    
    .cart{
        .quantity {     
            margin: 0 20px 0 0;
        }
    } 
    
    .filter-show{
        .wrapper-content-product{
            visibility: visible;
            @include opacity(1);
        }
    }      
    .shop-content{
        .wrapper-content-product{
            padding:0 20px;
        }
    }     
    .container-fluid .container-inner, 
    .container-full .container-inner{
        padding:0 20px;
    }
    div.widget-testimonial .carousel-nav{
        padding:0 20%;
    }
    #sok-top-navbar{
        display: none;
    }    
    .product-taxonomy .layout-large .caption-product{
        background: white;
    }
    #comments .comment-lists{
        ul.children{
            padding-left: 15px;
        }
    }
    .nano-showcase{
        .content-right {
            position: absolute;
            left: -99999px;
            top: -99999px;
        }                
        .content-left{
            float: none;
            width: 100%;            
            padding: 0;
            .title-box{
                left: 30%;
                a {
                    font-size: 26px;
                    line-height: 36px;
                }
            }         
            .content-box {
                padding-left: 40%;                
            }    
            .col-item{
                padding: 0;
                text-align: center;
                .entry-image {
                    padding-left: 40%;
                    padding-right: 10%;                    
                }
            }             
        }         
    }  
    .caption-product{
        text-align: center;
    }
    .product-grid{
        .product-price{
            .shopswatchinput {
                display: none;
            }
            .price,
            .shopswatchinput {
                max-width: 100%;        
                @include flex-basis(100%);
                @include justify-content(center);
            }       
        }         
    } 
    .shopswatchinput{           
        .wcvashopswatchlabel{
            &.wcva_shop_textblock{
                line-height: 18px;
            }   
        }
    } 
    .canvas-header{
        .btn-close {        
            font-size: 28px;
            @include square(60px);            
        }      
    }
    // Blog    
    .layout-grid{
        &.archive-blog{
            .item-post{
                margin-bottom: 15px;
            }   
        } 
    }
    .layout-list{        
        .post-item {     
            padding-top: 0;
            padding-bottom: 0;     
        }                    
    }  
    .layout-tran{        
        &.archive-blog{
            .item-post{
                margin-bottom: 15px;
            }   
        }        
    }     
    .archive-blog{
        .entry-content {
            display: block;
        }    
        .item-post {
            margin-bottom: 50px;
        }
    }     
    .post-list{
        .entry-header {        
            margin-bottom: 0;
            padding-left: 30px;
            padding-right: 30px;
        }
        .entry-title {
            line-height: 40px;
            margin-bottom: 10px;
        }
        .article-image {         
            margin-bottom: 30px;
        }
        .article-content {
            padding-left: 50px;
            padding-right: 50px;
            padding-top: 15px;
            width: auto;            
            max-width: none;
            margin-bottom: 50px;
        }
    }
    .post-related{
        .post-grid{
            .article-content {                
                padding-left: 60px;
                padding-right: 60px;
            }            
        }
        article.post-item{
            .entry-header-title{
                .entry-title {
                    padding-left: 0;
                    padding-right: 0;
                }
            }             
        }         
    }     
}

// Small screen < 640px-------------------------------------------------------------------------------------------------
@media (max-width: 640px) {
    .woocommerce-nav .woocommerce_checkout_login, .woocommerce-nav .woocommerce_checkout_coupon{
        padding: 30px 15px 0;
    }
    .sok-coupon .coupon {
        border: none;
        padding: 0;
    }
    .product-layout-grid .product_grid{
        padding: 0 0 0 7.5px;
    }
    .main-content .pagination{
        margin-bottom: 0;
    }
    .pagination .page-numbers{
        margin-bottom: 30px;
    }

    .product-detail-wrap,.product-layout-vertical,.product-layout-grid{    
        > .row,.rows{
            margin:0;
            .product-detail-before,
            .product-detail-summary,
            .col-md-7,.sticky-product-detail{
                padding-left:0;
                padding-right:0;
            }
        }
    }
    .archive-blog .article-meta .meta-author{
        display: none;
    }
    .widget-related{
        .products-row{
            margin:0 -7.5px;
        }
        .article-carousel{
            .product-col:nth-child(2n+1), 
            .col-item:nth-child(2n+1),
            .product-col:nth-child(2n),
            .col-item:nth-child(2n){
                float: left;
                padding:0 7.5px;
            }
        }
    }
    .nano-slider-category .article-carousel .entry-content-inner {
        opacity: 1;     
    }
    .product-detail-wrap form.cart .form-inline {
        max-width: 50%;
        @include flex-basis(50%);
    }
    .button.single_add_to_cart_button {
        padding-left: 15px;
        padding-right: 15px;        
        @include flexbox();
        @include align-items(center);
        @include justify-content(center);        
        max-width: 50%;
        @include flex-basis(50%);
    }
    .block-masonry-products{
        margin: 0 ;
        .grid-sizer{
            width: 50%;
            line-height: 0;
        }
        .masonry-product .col-item{
            width: 50%;
        }
        .grid-banner{
            width: 50%;
        }
        &.na-left{
            .masonry-product .col-item{
                &:nth-child(4){
                    width: 50%;
                }
                &:nth-child(5){
                    width: 100%;
                }
            }
        }
        &.na-right{
            .masonry-product .col-item{
                &:nth-child(3){
                    width: 50%;
                }
                &:nth-child(4){
                    width: 100%;
                }

            }
        }
        &.na-center{
            .masonry-product .col-item{
                &:nth-child(3){
                    width: 50%;
                }
                &:nth-child(7){
                    width: 100%;
                }

            }
        }
    }
    .block-asymmetric-products{
        margin: 0;
        .asymmetric-title{
            padding:30px 0;
            .block-title{
                font-size: 26px;
                margin-top: 0;
                margin-bottom: 0;
            }
        }
        .asymmetric-product{
            padding: 0;
        }
        .products-blocks{
            margin:0px;
            li{
                padding:7.5px;
                width: 100%;
                &:first-child {
                    width: 100%;
                    .product-block{
                        padding-left: 0px;
                    }
                }
                &:last-child {
                    width: 100%;
                    .product-block{
                        padding-right: 0px;
                    }
                }
            }
        }
        &.style-2{
            .asymmetric-title{
                padding:30px 0;
            }
            .row{                
                @include flexbox();
                @include flex-direction(column);
            }
            .col-md-8{                
                @include order(2);
            }
            .col-md-4{
                @include order(1);
            }
            .products-blocks{
                margin:0px;
                li{
                    padding:7.5px;
                    width: 100%;
                    &:first-child {
                        width: 100%;
                        .product-block{
                            padding-left: 0px;
                        }
                    }
                    &:last-child {
                        width: 100%;
                        .product-block{
                            padding-right: 0px;
                        }
                    }
                }
            }
        }
    }
    .header-content-right{
        top: 16px;
    }
    .admin-bar{
        .cart-box{
            padding-top: 46px;
        }
        .shop-sidebar{
            padding-top: 110px;
        }
    }
    p{
        margin-bottom: 10px;
    }    
    .single-post{
        #comments {
            margin-top: 40px;    
        }
        .box-article{
            .entry-header{
                margin: 0px auto 30px;
            }
            .entry-content{
                margin: 30px auto 0;
            }
        }
        .post-related .widgettitle span{
            padding: 15px;
        }
    }         
    .woocommerce table.shop_table.cart{
        + .return-to-shop {
            margin-bottom: 0;
        }        
        thead > tr > th{
            &.product-subtotal{
                text-align: right;
            }
            &.product-remove{
                display: none;   
            }
        }
    }
    .page-cart-bottom{
        margin-top: 15px;
        .sok-coupon .input-group-addon{
            display: none;
        }
        .sok-coupon #coupon_code{
            padding: 0 10px;
        }
    }
    .woocommerce table.wishlist_table{
        .product-stock-status{
            display: none;
        }
        .product-name{
            display: none;
        }
        .product-thumbnail{
            width: 100px;
        }
        .product-remove{
            min-width: 30px;
        }
        tr th{
            display: none;
        }
    }    
    .container-fluid {
        padding-left: 15px;
        padding-right: 15px;
    }
    //header
    .slicknav_menu .slicknav_icon-bar{
        box-shadow: none;
    }
    .slicknav_menu .slicknav_no-text{
        margin-top: 9px;
    }
    .site-logo{
        line-height: 30px;
    }
    .header-simple .searchform-mini{
        @include rtl-padding-right(20px);
    }
    .error-404 .searchform .form-control, .no-results .searchform .form-control{
        min-width: 300px;
    }
    .slicknav_btn{
        padding-left: 15px;
    }
    .header-content-right{
        right: 15px;
    }    
    .placeholder-header-fixed{
        max-height: 70px !important;
    }
    //short codes
    .block-title{
        margin-bottom: 20px;
        margin-top: 15px;
        font-size: 26px;
        line-height: 34px;
    }
    .product-tabs {
        .tabs-title{
            font-size: 26px;
            line-height: 36px;
            margin-bottom: 10px;
        }
        .widgettitle{
            padding-bottom: 20px;
        }
        ul.nav-product-tabs li{
            @include rtl-padding-right(0);
        }
    }
    .na-infoCategory{
        .entry-image{
            width: 100%;
            padding:0 50px;
            .image_f{
                margin-bottom: 5px;
            }
            .des_f{
                font-size: 14px;
                line-height: 22px;
            }
            &.style-2{
                .image_f{
                    margin-top: 5px;
                    margin-bottom: 50px;
                }
            }
        }
        .entry-content{
            width: 100%;
            padding: 0;
            .title-box{
                font-size: 26px;
                line-height: 32px;
                margin-bottom: 10px;
            }
            .des-box{
                padding: 5px 0 10px;
                font-size: 14px;
                line-height: 24px;
            }
            &.style-2{
                padding: 0;
                .title-box{
                    font-size: 26px;
                    line-height: 32px;
                    margin-bottom: 10px;
                }
            }
        }
    }
    .box-text {
        padding: 100px 0;
        >*{
            padding: 50px 30px;
        }
        h2, h1, h3, h4{
            font-size: 36px;
            line-height: 42px;
        }
        .btn{
            margin-top: 15px;
        }
    }
    .text-responsive{
        text-align: center;
        padding-bottom: 15px;
        h2, h1, h3, h4,h5{
            font-size: 16px !important;
            line-height: 20px!important;
            display: inline-block;
            margin-bottom: 0 !important;
            margin-top: 15px;
        }
    }
    .wpb-js-composer{
        .vc_tta-accordion{
            &.vc_tta{
                &.vc_general{
                    .vc_tta-panel-title{
                        > a {
                            padding: 30px;
                            line-height: 36px;
                        }     
                        &:before, 
                        &:after{
                            content: none;
                        }
                    }                     
                }                 
            }            
        }        
    }     
    .nano-textBlock{
        .image-box{
            width: 100%;
            padding: 0;
        }
        .content-box{
            width: 100%;
            padding: 10px 0px 0px 0;
            .title-text{
                font-size: 36px;
                line-height: 56px;                
            }
            .content-text{
                font-size: 14px;
                line-height: 24px;
                margin-bottom: 10px;
            }
        }
    }
    .wpb_text_column{
        &.text_column_faq {
            padding-left: 30px; 
            padding-right: 30px;
        }   
    }    
    .nano-slider {
        .article-carousel{
            margin: 0 -25px;
            .content-box{
                bottom: 20px;
                @include rtl-left(30px);
            }
        }
        .article-carousel-center {
            padding-bottom: 20px;
            .slider-box{
                padding: 25px 0px;
            }
            .sub-box-left{
                display: none;
            }
            .entry-content{
                padding: 50px 0 0;
            }
            .slick-arrow{
                display: none !important;
            }
        }
    }
    .scattered-box{
        padding-top: 30px;
        padding-bottom: 30px;
        .des-box{
            font-size: 14px;
            line-height: 22px;
        }
        &.ver-left{
            width: 100%;
            .entry-image{
                padding-top: 0;
            }
        }
        &.ver-right{
            width: 100%;
            .entry-image{
                padding-top: 0;
            }
        }
        &.squ-right{
            width: 100%;
            .entry-image{
                padding-top: 0;
            }
            .entry-content{
                top:0;
            }
        }
        &.squ-left{
            width: 100%;
            .entry-image{
                padding-top: 0;
            }
            .entry-content{
                top:0;
            }
        }
        &.hoz-center{
            padding-left: 0px;
            padding-right: 100px;
            .entry-content{
                top:30px;
                width: 50%;
                .des-box{
                    padding: 0;
                }
            }
        }
    }
    .na-imageGallery{
        .entry-content{
            width: 100%;
            .entry-content-inner{
                padding:0 0px 30px 30px
            }
        }
        .entry-image{
            padding: 0;
            width: 100%;
        }
    }
    .text-header{
        font-size: 26px !important;
        line-height: 30px !important;;
    }
    .items-scattered{
        .sm-center{
            padding: 0 15px;
        }
        .sm-right{
        }
        .caption-product{
            position: relative;
            bottom: 0;
            left: 0;
            right:0;
        }
    }

    //footer
    .footer-center{
        padding-top: 50px;
        padding-bottom: 30px;
    }
    .footer-bottom{
        text-align: center;
    }
    .footer-bottom-right{
        padding-top: 10px;
        text-align: center;
    }
    #na-footer .social-icons li{
        padding: 0 15px;
    }
    #na-footer.footer-2{
        .widget_contact_info {
            .group-des{
                display: none;
            }
            .contact-info{
                width: 60%;
            }
        }
    }

    #sb_instagram.sbi_col_8.sbi_disable_mobile #sbi_images .sbi_item{
        width: 25%;
    }
    .category-tabs{
        padding-top: 15px;
    }
    .category-tabs .cat-title{
        padding-bottom: 15px;
    }
    .category-tabs .tabs-title-cat{
        padding-top:0;
        padding-bottom: 0px;
        padding-left: 5px;
        padding-right: 5px;
    }

    .category-tabs ul.nav-tabs li{
        padding: 0 5px 10px;
        float: none;
        text-align: center;
        display: inline-block;
    }
    .block-blog{
        .des-box{
            display: none;
        }
    }
    //category
    .products-block {
        .product-col, .col-item{
            &:nth-child(2n+1){
                padding-right: 7.5px;     
                clear: left;           
            }
            &:nth-child(2n) {
                padding-left: 7.5px;
            }
        }
        .product-block{
            margin-bottom: 30px;            
        }
        &.na-carousel{
            .product-col,.col-item{
                &:nth-child(2n+3){
                    padding-right: 7.5px;
                    clear: none;
                }
                &:nth-child(2n) {
                    padding-left: 7.5px;
                }
            }
        }
    }
    .na-service{
        .service-box {
            width: 100%;
            max-width: 100%;
            padding: 0 15px;
            @include flex-basis(100%);
            &:last-child{
                padding-bottom: 0;
                margin-bottom: 0;
            }
        }
    }     
    .product-tabs {
        .widgettitle{
            padding-bottom: 10px;
        }
        .nav-product-tabs li{
            padding-bottom: 10px;
        }

    }
    .wrap-select{
        border: none;
    }
    .woo-search{
        width: 100%;
        margin-top: 10px;
    }
    #filter-full{
        margin-top: 65px;
    }
    .btn-filter-down + .woocommerce-ordering{
        float: left !important;
        width: 50%;
        text-align: left;
        .wrap-select > select{
            padding-left: 0;
        }
    }
    #filter-down{
        width: 100%;
        text-align:left;
        .widget,.widget-area{
            padding-left: 7.5px;
            padding-right: 7.5px;
            width: 50%;
            float: left;
            .widget{
                padding: 0;
                width: 100%;
            }
            &:last-child{
                 .widgettitle,
                 > h2,
                 > h3{
                    & + *{
                        left: 0;
                    }
                }
            }
        }
        .widget .widgettitle,.widget > h2,
        .widget > h3,
        .widget-area .widgettitle,
        .widget-area > h2,
        .widget-area > h3{
            padding-left: 0;
            display: block;
            line-height: 30px;
            &:after{
                left: 80%;
                top:-5px;
            }
            & + *{
                top:30px;
                left: 0;
                padding: 10px 20px;
            }
            &:hover{
            }
        }
    }
    .cats-block{
        margin-bottom: 20px;
        li{
            margin-bottom: 0;
        }
        li:nth-child(2n+1){
            @include rtl-padding-left(7.5px);
        }
        li:nth-child(2n){
            @include rtl-padding-right(7.5px);
        }
        .woocommerce-loop-category__title{
            top: 15px;
            left: 35px;
        }
        &.cat-1{
            .category-thumb-large{
                width: 65%;
            }
            .category-thumb{
                width: 35%;
            }
        }
        &.cat-2{
            .category-thumb-large{
                width: 100%;
                margin-bottom: 7.5px;
            }
            .category-thumb{
                width: 50%;
                padding: 7.5px;
                margin: 0;
            }
            .category-thumb-wide{
                padding-top: 7.5px;
                padding-left: 7.5px;
                padding-right: 7.5px;
            }
        }
        &.cat-3{
            .grid-sizer,.category-thumb{
                width: 50%;
            }
        }
    }
    .na-shop{
        > li{
            padding: 0 15px;
            width: 50%;
            float: left;
            margin-bottom: 30px;
            &:nth-child(2n+1){
                clear: left;
            }
        }        
    }
    .woocommerce-nav-category {
        padding-bottom: 30px;
        border-bottom: 1px solid #e6e5e1;
        margin-bottom: 30px;
    }
    .product-list .caption-product .button-groups {
        padding-top: 30px;
        padding-left: 0px !important;
    }
    .products-block{
        .product-col,.col-item{
            &.no-padding{
                padding: 0;
            }           
        }
    }
    //slider
    #slider-showcase_wrapper{
        height: calc(100vh - 350px) !important;
    }
    .na-masonry-category {
        .grid-sizer,.small{
            width: 50%;
        }
        .medium{
            width: 100%;
        }
    }
    //detail
    .single-product{
        .breadcrumb,.product-nav{
            display: none;
        }
        .form-group{
            margin-bottom: 0;
        }        
        .nav-tabs{
            border-bottom:none;
            >li{
                display: block;
                width: 100%;
                margin-right: 0;
                margin-bottom: 10px;
            }
        }
        .woocommerce-tabs #reviews .comment-form-rating,
        .woocommerce-tabs #reviews .comment-notes{
            position: relative;
            top:0;
            left: 0;
        }
        .woocommerce-tabs #reviews .comment-form-rating{
            padding-top: 15px;
        }
        .woocommerce-tabs #commentform{
            margin-top: 30px;
        }
    }
    .product-layout-grid{
        .entry-summary{
            padding: 10px 20px 30px;
        }
    }
    .product-layout-carousel .entry-summary{
        margin-top:0;
    }
    .product-layout-carousel-left .woocommerce-product-gallery__image{
        min-height: 400px;
    }
    .product-layout-carousel-left .entry-summary-inner{
        padding:0;
    }
    //blog
    .post-list{
        .post-image a{
            padding: 0;
        }
    }    
    .search-transition-wrap .search-transition-inner{
        padding: 0 !important;
    }
    .search-transition-wrap .btn-mini-close{
        right:0px !important;
    }    
    .shop_table_responsive.cart{
        .product-price,.product-name{
            display: none;
        }
    }
    .page-cart{
        padding: 0 15px ;
    }
    .wpcf7-form{
        .block-title{
            font-size: 36px;
            line-height: 42px;
            margin-bottom: 30px;
        }
    }     
    .entry-title,
    .page-title{
        font-size: 36px;
        line-height: 46px;
    }
    .padding-big{
        padding: 100px 0;
    }
    .entry_pagination{
        .pagination{
            padding-bottom: 0;
        }
    }     
    .post-tran{
        .article-content{
            @include opacity(1);
            visibility: visible;        
        }
        .article-content-inner{
            background: rgba(255, 255, 255, 0.9);
        }
    }         
    .post-list{
        margin-bottom: 0;
        .article-image {
            display:block;
            float: none;
            width: 100%;
            margin-bottom: 30px;
            .post-image{
                img.lazy{
                    width: 100%
                }
            }             
        }
        .article-content {
            float: none;
            width: 100%;        
            padding: 0;
            margin-bottom: 50px;
        }
        .article-meta {
            display: block;
            width: 100%;
            padding: 10px 30px 20px;
        }
        .entry-header{
            padding-right: 0;
            padding-left: 0;
            margin-bottom: 0;
        }
        .entry-content{
            padding-right: 10px;
            padding-left: 10px;
            padding-top: 15px;
            display: block;            
        }
    }       
    #na-footer{
        .newsletters{
            .group-input-newsletter{
                width: 100%;
            }
        }         
    }     
    .entry-avatar{
        padding-bottom: 30px;
    }
    .box-large .post-tran .entry-title{
        padding: 0;
        margin: 0;
    }
    .box-large .post-tran .entry-title > a{
        font-size: 20px;
        line-height: 26px;
        padding: 0;
    }
    .style_center .post-tran .entry-title > a{
        font-size: 20px;
        line-height: 20px;
    }
    .style_center .article-content {
        bottom: 0;
        left: 0;
        margin: auto;
        right: inherit;
        text-align: left;
    }
    .style_center .entry-title {
        line-height: 30px;
        margin: 0;
    }
    .article-content .entry-meta-right{
        line-height: 24px;
    }
    #comments .comment-box{
        display: block;
        width: auto;
    }
    .post-tran .entry-content{
        display: none;
    }
    .post-list{
        .article-meta{
            padding: 10px 15px 10px;
        }
    }     
    .archive-blog .article-meta .posted-on{
        padding: 0;
    }
    #comments .comment-box{
        padding-top: 20px;
    }
    .comment-respond{
        padding-bottom: 20px;
    }    
}
// Small screen / phone 480px-------------------------------------------------------------------------------------------
@media (max-width:$screen-xs-min) {
    .block{
        &.nano-intro {         
            margin-top: 70px;
            .content-box {
                padding-bottom: 50px;
                min-height: auto;
                height: auto;
            }
        }
    } 
    .wpb-js-composer{
        .vc_tta{
            &.vc_general{
                .vc_tta-panel-title {
                    font-size: 20px;     
                }
            }           
        }        
    }     
    .nano-showcase{        
        .content-left {
            width: 100%;
            float: none;
            .content-box {
                padding-left: 20%;                
            }
            .image-box {
                padding-top: 105px;
            }
            .col-item{
                .entry-image {
                    padding-left: 20%;
                    padding-right: 10%;
                }
            }             
        }                
        .border-mask.center {
            left: 0;
        }
    }  
    .nano-infoTeams{
        .block-content{
            .box-team-list {
                max-width: 100%;
                margin-bottom: 30px;
                @include flex-basis(100%);                
                &:nth-last-child(2) {
                    margin-bottom: 30px;
                }
            }     
        }         
    }     
    .search-box, .intro-box, 
    .login-box, .mobile-box {
        width: 100%; 
    }
    .cart-bottom {
        padding-bottom: 30px;     
    }
    .page-content{
        padding-bottom: 80px;
    }
    .widget.widget_instagram{
        .col-5 {
            width: 50%;
        }
    }     
    .box-intro-images{
        .box-content {         
            bottom: 0;
            left: 0;
            padding: 30px 15px;
        }
        .title-box{
            font-size: 38px;
            line-height: 48px;
            a {
                font-size: 38px;
                line-height: 48px;
            }
        }         
    }     
    .cart-box {
        width: 100%;     
    }
    .cart-panel {
        min-width: auto;
        padding: 30px;
    }  
    .shop-sidebar{
        width:320px;
        padding: 60px 15px;
    }
    .searchform-mini.searchform-moblie{
        top: 15px;
    }
    .search-transition-wrap .btn-mini-close{
        top:-100px;
    }
    .header-content-right{
        top:5px;
    }
    .product-name a{
        font-size: 14px;
    }  
    .block-products-widgets{
        .title-ground{
            text-align: center;
            .btn-unline {
                float: none;
                margin: 0 auto;
                padding-top: 15px;
                width: auto;              
                border: 0;                  
                display: block;
                @include box-shadow(none);
            }
            .block-title {
                float: none;
                margin: 0;
                width: 100%;                
                padding: 0;
            }
        }         
    } 
    #na-footer{
        .social-icons{
            li{
                padding: 0 10px;
            }
        }     
        &.footer-2{
            .widget_contact_info{
                .mobile, 
                .phone{
                    font-size: 16px;
                }    
            }             
        }         
    }         
    .archive-blog .post-tran .entry-title > a{
        font-size: 16px;
        line-height: 20px;
        padding: 0;
    }
    .blog-column-center-layout .article-content{
        bottom: 0px;
        @include rtl-left(0px);
        .entry-title{
            margin-bottom: 0;
        }
    }
    .archive-blog .post-tran .article-meta{
        padding: 5px 15px;
    }    
    div.woocommerce-tabs{
        padding: 50px 0px 0;
    }
    .tabs.wc-tabs.resp-tabs-list >li{
        float: none;
        text-align: left;
        display: block;
        margin-bottom: 10px;
    }
    .widget-services-content{
        margin-bottom: 30px;
    }    
    .newsletters{
        text-align: left;
        .btn{
            font-size: 12px;
        }
        #mc4wp{  
            width: 100%;
        }
        .group-input-newsletter{
            width: 100%;            
        }
    }
    .widget-introduce{
        .introduce-text{
            padding: 20px;
        }
    }     
    div.widget_deals .item-deal{
        padding: 10px;
    }
    div.widget_deals{
        padding-bottom: 50px !important;
    }
    h3.widgettitle{
        font-size: 20px;
    }
    .na-recent-post.col-xs-6{
        width: 100%;
    }   
    .products-block .article-carousel{
        .product-col:nth-child(2n+1),.col-item:nth-child(2n+1){
            clear: none;
        }
    }
    .widget-testimonial{
        display: none;
    }
    .widget-banner-slider{
        display: none;
    }
    .widget-product{
        overflow: hidden;
        .slick-prev,.slick-next{
            display:none !important;
        }
    }
    .product-taxonomy .layout-large .caption-product{
        bottom: 0;
        left: 0;
        position: relative;
    }
    .product-taxonomy .layout-large .caption{
        border-top: 1px solid #ebebeb;
    }
    .product-taxonomy .layout-large .product-block{
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.13);
    }

    .entry_pagination .page-numbers .title-pagination{
        display: none;
    }
    .entry_pagination .page-numbers{
        height: auto;
    }
    #comments .comment-lists{
        ul.children{
            padding-left: 0px;
        }
    }
    .article-content.full-width{
        .entry-header,.entry-avatar,.entry-content{
            padding-left: 0px;
            padding-right: 0px;
        }
    }
    .post-gird {
        .entry-header,.entry-avatar,.entry-content{
            padding-left: 30px;
            padding-right: 30px;
        }
    }
    .entry-footer{
        margin-left:15px;
        margin-right:15px;
    }
    .post-related{
        .post-grid{
            .article-content {
                padding-left: 15px;
                padding-right: 15px;
            }
        }         
    }     
    .entry-footer-social .entry-footer-right{
        float: none;
    }
    .share-links{
        padding:30px 0 0;
    }
    .count-share .social-icons {
        right: inherit;
        left: 70px;
        @include opacity(1);
        top: -20px;
        visibility: visible;
        min-width: 180px;
    }
    .share-links .social-icons {
        text-align: left;
    }
    .entry_pagination .pagination {
        padding: 15px 0 0px;
    }
    .entry-footer-social .entry-footer-left {
        float: none;
    }

    #na-footer .sok-image-content {
        padding: 10px 0 30px;
    }
    #na-footer .footer-top{
        padding: 0;
    }
    #na-footer .sok-social-icon a{
        margin: 0 15px;
    }
    #na-footer .footer-bottom{
        padding: 20px;
        border-top: 1px solid #eee;
    }
    //slider
    #slider-showcase_wrapper{
        height: calc(100vh - 350px) !important;
    }
    #na-footer .footer-center {      
        padding-bottom: 70px;        
    }
}

// X-small screen / phone < 400px --------------------------------------------------------------------------------------
@media (max-width: 400px){
    .post-sidebar .article-image{
        @include rtl-padding-right(10px);
        width: 40%;
    }
    .box-blog.large-vertical .box-small .article-content{
        width: 60%;
    }
    .woocommerce-ordering .orderby{
        overflow:hidden;
        text-overflow:ellipsis;
        white-space:nowrap;
        max-width: 146px;
        padding-right: 20px;
    }
    .top-products .wrap-select > select{
        line-height: 50px;
    }
    //breadcrumb
    .group-cover .title-page{
        font-size: 20px;
    }
    .wrap-breadcrumb-cover .breadcrumb{
        font-size: 12px;
        padding: 0;
        margin: 0;
    }
    .scrollup{
        display: none !important;
    }
    .title-pagination{
        display: none;
    }
    .entry_pagination .page-numbers{
        height: auto;
    }
}