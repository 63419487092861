//style widget Info Category

.na-infoCategory{
    .entry-image{
        @include rtl-float-left();
        width: 50%;
        text-align: center;
        @include rtl-padding-right(15px);
        .image_f{
            margin-bottom: 30px;
        }
        .des_f{
            font-size: 18px;
            line-height: 34px;
            color: $color-meta;
        }
        &.style-2{
            margin-top: 35px;
            @include rtl-text-align-left();
            .image_f{
                margin-bottom: 0px;
                margin-top: 30px;
            }
            .des_f{
                max-width: 470px;
                text-align: center;
                display:block;
            }
        }
    }
    .entry-content{
        margin-top: 55px;
        @include rtl-float-left();
        @include rtl-padding-left(115px);
        width: 50%;
        .content-box{
            max-width: 400px;
            @include rtl-text-align-left();
            margin-bottom: 85px;
        }
        .image-box{
            position: relative;
        }
        .title-box{
            font-size: 42px;
            line-height: 52px;
        }
        .des-box{
            font-size: 16px;
            line-height: 26px;
            color: $color-meta;
            padding: 5px 0 30px;
        }
        .image_t{

        }
        .des_t{
            -ms-writing-mode: tb-rl;
            -webkit-writing-mode: vertical-rl;
            writing-mode: vertical-rl;
            @include rotate(180deg);
            white-space: nowrap;
            position: absolute;
            @include rtl-right(-65px);
            top:0;
            bottom: 0;
            font-size: 18px;
            line-height: 34px;
            color: $color-meta;
            text-align: center;
        }
        &.style-2{
            margin-top: 0px;
            @include rtl-padding-left(15px);
            .content-box{
                @include rtl-padding-left(100px);
                max-width:100%;
                margin-top: 55px;
                margin-bottom: 0;
            }
            .des_t{
                display: none;
            }
        }
    }
}
.container{
    //background: #ccc;
}