//style Text Block
.nano-textBlock {
    position: relative;
    margin-bottom: 0;
    text-align: center;
    .image-box{
        padding: 0;
    }
    .content-box{

    }
    .title-text{
        font-size: 48px;
        line-height: 68px;
        font-weight: 400;
        color: $color-title;
        margin-bottom: 30px;
        margin-top: 50px;
    }
    .content-text{
        font-size: 18px;
        line-height: 34px;
        margin-bottom: 25px;
        font-weight: 300;
    }
}
