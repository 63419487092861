//style widget Masonry Category

.na-masonry-category{
    position: relative;
    .items-masonry{
        margin: 0 -5px;
    }
    .masonry-box{
        padding: 5px;
    }
    .box-content{
        position: absolute;
        top:0;
        @include rtl-left(30px);
    }
    .grid-sizer,.small{
        width: 25%;
    }
    .lager{
        width: 50%;
    }
    .medium{
        width: 75%;
    }
    .title-box{
        font-size: 24px;
        color: $color-title;
        margin-bottom: 10px;
        a:hover{
            text-decoration:underline;
            color: $color-title;
        }
    }
    .des-box{
        font-size: 16px;
        line-height: 24px;
    }
}
