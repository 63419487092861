//style widget Slider
.nano-slider-category .article-carousel{
    margin: 0px;
    .slider-box{
        position: relative;
        &:hover {
            .content-box{
                background: rgba(239,178,146,0.6);
                @include transition(0.4s);
            }
            .entry-content-inner{
                opacity: 1;
                @include transition(0.4s);
            }
        }
    }
    .col-item{
        padding: 0 0px;
        width: 50%;
        height: 100vh;
    }
    .content-box{
        background: transparent;
        @include transition(0.4s);
        height: 100%;
        position: absolute;
        text-align: center;
        width: 100%;
        top:0;
        z-index: 10;
        &::before {
            content: "";
            display: inline-block;
            height: 100%;
            margin-right: -0.25em;
            vertical-align: middle;
        }
    }
    .entry-content-inner{
        opacity: 0;
        display: inline-block;
        max-width: 80%;
        position: relative;
        vertical-align: middle;
        @include transition(0.4s);
        &:hover{
            .btn-next i{
                padding-left: 15px;
            }
        }
    }
    .image-box{
        height: 100%;
        width: 100%;
        background-clip: padding-box;
        background-position: 50% 20%;
        top: 0;
        background-size: cover;
        background-repeat: no-repeat;
    }
    .btn-next{
        padding-top: 0;
    }
    .title-box{
        font-size: 84px;
        font-weight: 500;
        margin-top: 5px;
        margin-bottom: 0;
    }
    .slick-dots{
        text-align: right;
        padding-right: 15px;
        li{
            width: 25px;
            height: 25px;
            &.slick-active{
                button::before{
                    color:  #fff;
                    background: white;
                    opacity: 1;
                }
            }
            button:hover{
                &:before{
                    opacity: 1;
                }
            }
        }
        button::before{
            font-size: 12px;
            text-align: center;
            line-height: 12px;
            opacity: 0.5;
            width: 14px;
            height: 14px;
            color: transparent;
            border: 2px solid #fff;
            border-radius: 100%;
        }
    }
}

