// Style Layout page Category

.shop-content{
    padding-top: 50px;
    &.layout-shop-duel{
        padding-top: 80px;
    }
    &.layout-filter-down{
        padding-top: 0;
    }
    &.sok-both{
        padding-top: 0;
        .cats-block{
            padding-bottom: 60px;
        }
    }
}

/**
* Title
*/
.page-title-woo{
    font-size: 30px;
    margin-top: 0px;
    margin-bottom: 30px;
    font-weight: 500;
}

/**
* Category Image
*/
.wrapper-thumb-cat{
    margin-bottom: 30px;
    .category-image{
        width: 100%;
    }
}
/**
* Category Description
*/
.term-description{
    margin-bottom: 30px;
    font-size: 16px;
    color: #e0e0e0;
    text-transform: none;
    max-width:800px;

}

/**
* Category Filter
*/
.top-products{
    margin-bottom: 30px;
    .btn-filter,.switch-layout,.woocommerce-result-count{
        line-height: 60px;
        height: 60px;
    }
    span{
        font-size: $font-size-base
    }
    .wrap-select > select{
        height: 60px;
        line-height: 30px;
        option{
            padding: 5px;
        }
    }
    .wrap-select::after{
        line-height:60px;
    }

}
#filter-full{
    display: none;
    background: #f7f7f7;
    margin-top:15px;
    padding: 30px 50px;
    .content-inner{
        margin: 0 -20px;
    }
    .widget{
        width: 20%;
        margin-top:20px;
        margin-bottom:20px;
        padding-left: 20px;
        padding-right: 20px;
        @include rtl-float-left();
        .widgettitle, > h2, > h3{
            margin: 0;
            padding-bottom: 20px;
            font-size: 18px;
            color: $color-title;
            font-weight: 400;
            &:after{
                content:none;
            }
        }
        a:hover{
            color: $color-title;
        }
        ul{
            padding: 0;
            margin: 0;
            list-style: none;
        }
        li{
            line-height: 30px;
            &.chosen,&.current-cat,&.current{
                color: $color-title;
                font-weight: 500;
                a{
                    color: $color-title;
                    font-weight: 500;
                }
            }
        }
    }
    .widget_layered_nav_filters{
        width: 100%;

    }
    .widget_layered_nav ul li span, .widget_layered_nav_filters ul li span{
        float: none;
    }
}
.hidden-filters{
    .shop-btn-filter{
        display: none;
    }
}


#filter-down{
    padding: 0;
    max-width: 800px;
    width: auto;
    position: static;
    text-align: left;
    .widget,.widget-area{
        display: inline-block;
        position: relative;
        width:auto;
        margin-top:10px;
        margin-bottom:10px;
        padding-left: 30px;
        padding-right: 30px;
        &:first-child{
            padding-left: 0;
            .widgettitle, > h2, > h3{
                padding-left: 0;
            }
        }
        .widget{
            margin: 0;
            padding: 0;
        }
        .widgettitle, > h2, > h3{
            font-size: 18px;
            color: $color-title;
            font-weight: 300;
            z-index: 1;
            margin: 0;
            padding: 0 10px 0 20px;
            cursor: pointer;
            &:after{
                background: transparent;
                color: #777777;
                content: "\f107";
                font-family: "FontAwesome";
                height: 100%;
                line-height: 40px;
                pointer-events: none;
                position: absolute;
                @include rtl-left(100%);
                text-align: center;
                top:-12px;
                width: 10px;
                font-size: 16px;
                border: none;
            }
            + *{
                text-align: left;
                min-width: 180px;
                position: absolute;
                opacity: 0;
                visibility: hidden;
                top:40px;
                @include rtl-left(0px);
                background: white;
                box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
                padding: 20px;
                @include transition(0.4s);
                z-index: 10;
                border-radius:3px;
            }
        }
        &:last-child{
            .widgettitle, > h2, > h3 {
                + *{
                    @include rtl-left(0px);
                }
            }
        }
        &:hover {
            .widgettitle, > h2, > h3 {
                & + *{
                    opacity: 1;
                    visibility: visible;
                    @include transition(0.4s);
                }
            }
        }
        a:hover{
            color: $color-title;
        }
        ul{
            padding: 0;
            margin: 0;
            list-style: none;
        }
        li{
            line-height: 36px;
            font-size: 14px;

            &.chosen,&.current-cat,&.current{
                color: $color-title;
                a{
                    color: $color-title;
                }
                &:hover a{
                    color: #cf3b3d;
                }
            }
        }

    }

    .widget_layered_nav ul li span, .widget_layered_nav_filters ul li span{
        float: none;
    }
    + .woo-search{
        display: none;
    }
    .wrap-select > select{
        margin: 0;
    }
    .widget_product_categories .product-categories > li{
        > a{
            font-size: 16px;
            font-weight: 300;
        }
        .children{
            padding-left: 15px;
        }
    }

}
//end filter-down
.woocommerce-nav-filters{
    .widget_layered_nav_filters{
        padding-bottom: 30px;
        .widgettitle{
            padding-bottom: 15px;
            font-size: 18px;
            font-weight: 500;
            &:after {
                content:none;
            }
        }
        ul{
            padding: 0;
            list-style: none;
            display: flex;
        }
        li{
            @include rtl-padding-right(30px);
        }
    }
}

.woocommerce-nav-category{
    padding-bottom: 50px;
    border-bottom: 1px solid #e6e5e1;
    margin-bottom: 50px;
    .navbar-nav > li > a{
        padding: 0;
        background: none;
    }
    .navbar-nav > li{
        @include rtl-padding-right(80px);
    }
    li ul{
        padding-top: 15px;
        a{
            font-size: 14px;
            font-weight: 400;
            color: #777777;
            &:hover{
                color: $color-title;
            }
        }
    }
    ul{
        list-style: none;
        padding: 0;
    }
    li{
        line-height: 30px;
    }
    a{
        font-size: 16px;
        color: $color-title;
        &:hover{
            color: $color-title;
        }
    }
    .current-menu-item > a{
        color: $color-title;
    }
}

.btn-filter-down{
    display: none;
}
.btn-filter-left{
    @include rtl-padding-right(30px);
}

// show grid/list toggle buttons
.switch-layout{
    display: none;
    font-size: 18px;
    margin:0;
    line-height: 40px;
    @include rtl-margin-right(30px);
    > li{
        padding: 0;
    }
    a{
        padding:0px 7px;
        background:transparent;
        cursor: pointer;
        color: #dddddd;
        &.active{
            color: $color-title;
        }

    }
}
//products per page
.products-per-page{
    padding: 0 15px;
}

/**
* Category SubCategories
*/
.sok-subcategories .shop-content-top{
    display: none;
}

.shop-content-top{
    margin-bottom: 40px;
}


/**
* Category info
*/
.archive_product_info{
    position: relative;
    display: block;
}
.products-block{
    width: auto;
    padding:0;
    margin-left: -25px;
    margin-right: -25px;
    position: relative;
    li{
        line-height: 20px;
    }
}

.products-row{
    margin: 0 -25px;
    clear:both;
}
.product-col,.col-item{
    padding-left: 25px;
    padding-right: 25px;
    position: relative;
    display: block;
    @include rtl-float-left();
    &.no-padding{
        padding: 0;
        .product-block{
            margin: 0;
            border-radius: 0;
        }
    }
}
.wrap-content.no-padding{
    margin-top:0;
    margin-bottom:0;
}

/**
* Product
* Grid and List
*/
.products-block{
    list-style: none;
    margin-bottom: 0;
    .product-col{
        @include rtl-float-left();
    }
}


.grid-price{
      display: block;
}
.list-price,.product-review,.product-add-review{
    display: none;
}

.description-product{
    display: none;
}
//Grid
.product-grid{
    &:hover{
       .ground-addcart {
           opacity: 1;
           bottom:0px;
           @include transition(0.4s);
       }
        .wishlist-buttom{
            @include transition(0.4s);
            background: #FFF;
            opacity: 1;
            visibility: visible;
        }
    }
    .wishlist-buttom{
        width: 40px;
        height: 40px;
        line-height: 40px;
        border-radius: 100%;
        opacity: 0;
        text-align: center;
        @include transition(0.4s);
        visibility: hidden;
    }
    .ground-addcart{
        opacity: 0;
        position: absolute;
        bottom:-50px;
        left: 0;
        right: 0;
        margin: auto;
        text-align: center;
        @include transition(0.4s);
        a{
            font-size: 14px;
            font-weight: 300;
            text-transform: capitalize;
            background: white;
            color:$color-title;
            padding: 10px 20px;
            line-height: 50px;
            width: 100%;
            display: block;
            background:  rgba(255,255,255,0.8);
            @include transition(0.2s);
            &:hover{
                background:  rgba(255,255,255,0.8);
                @include transition(0.2s);
            }
        }
    }
    .caption{
        max-width: 100%;
    }
    .price{
        line-height: 40px;
    }
    .amount{
        font-size: 16px;
    }
    del{
        .amount{
            font-size: 16px;
            font-weight: 300;
            color: $color-meta;
        }
    }
    ins{
        //display: block;
    }

}
//List
.product-list{
    border-top: 1px solid #eaeaea;
    padding: 30px 0;
    margin-bottom: 0;
    &.first{
        clear: both;
    }
    .product-block{
        background: #FFF;
        position: relative;
        display: block;
        overflow: hidden;
        margin: 0;
    }
    .wp-post-image{
        height: 100% !important;
    }
    .img-effect:hover .wp-post-image{
        @include rtl-margin-left(0);
    }
    //image product
    .caption-image{
        @include rtl-float-left();
        display: block;
    }
    .caption-product{
        padding: 20px 30px;
        overflow: hidden;
        .ground-addcart{
            @include rtl-text-align-left();
            padding-top: 15px;
            bottom: 0;
            position: relative;
            opacity: 1;
            display: inline-block;
        }
        .button-groups{
            position: relative;
            top:0;
            @include rtl-right(0);
            display: inline-block;
            @include rtl-padding-left(10px);

        }
        .yith-wcwl-add-to-wishlist .ajax-loading{
            top:3px;
        }
        .add_to_wishlist,.yith-wcwl-add-to-wishlist a{
            border-radius:0px;
            width: 50px;
            height: 50px;
            text-align: center;
            font-size: 12px;
            padding: 10px 20px;
            border: 1px solid #ccc;
            text-transform: uppercase;
            letter-spacing: 1px;
            &:before{
                content: none;
            }
            &:hover{
                background:#eaeaea;
            }
        }
    }
    //caption
    .caption{
        @include rtl-text-align-left();
        border: none;
    }
    .grid-price{
         display: none;
    }
    .product-price .shopswatchinput{
        float: none;
    }
    .list-price,.product-review,.product-add-review{
        display: block;
        line-height: 30px;
    }
    .product-review{
        display: none;
    }

    .product-name{
        padding-bottom: 10px;
        display: block;
        a{
            font-size: 24px;
            font-weight: 500;
            line-height: 30px;
            @include transition(0s);
        }
    }
    .add_to_cart_button{
        background: #1a1a26;
        padding: 10px 20px;
        color: #FFF;
        font-size: 12px;
        border: none;
        text-transform: uppercase;
        letter-spacing: 1px;
        &:hover,&:focus{
            background: #37373D;
            border-color:#000 ;
            padding: 10px 20px;
            color: #FFF;
            box-shadow: none;
        }
    }
    .added_to_cart{
        background: $theme-color;
        padding: 10px 20px;
        color: #FFF;
        font-size: 12px;
        text-transform: uppercase;
        letter-spacing: 1px;
        border: none;
        &:hover,&:focus{
            background: darken($theme-color, 15%);
            padding: 10px 20px;
            color: #FFF;
            box-shadow: none;
        }
    }
    .ground-addcart .button{
        background: #000;
        padding: 10px 20px;
        color: #FFF;
        font-size: 12px;
        text-transform: uppercase;
        letter-spacing: 1px;
        border: none;
        &:hover,&:focus{
            background: #37373D;
            color: #FFF;
            box-shadow: none;
        }
    }
    .price-review{
        display:block;
        position: relative;
        padding:20px 0px;
    }
    .price{
        display: inline-block;
        padding-top: 0;
        padding-bottom: 0;
        @include rtl-padding-right(0px);
        vertical-align: middle;
        font-size: 18px;
        color: $theme-color;
        font-weight: 500;
        line-height: 26px;
    }

    .woocs_price_code{
        display: inline-block;
        >*{
            display: inline-block;
        }
    }
    del{
      font-size: 14px;
    }
    .rating{
        padding-top: 0;
    }

    //description product
    .description-product{
        @include rtl-float-left();
        padding: 30px 0px 15px;
        line-height: 24px;
        display: block;
        color: #a0a0a4;
        p{
            margin-bottom: 10px;
        }
    }
    .product-block{
        &:hover{
            .price{
                opacity: 1;
                visibility: visible;
            }
        }
    }
//quick view
    .quick-view{
        display: none;
        bottom: 10px;
        @include rtl-right(10px);
    }
}
//Trans
.product-trans{
    .caption-image{

        &:before{
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background: rgba(255, 255, 255, .8);
            @include transition(0.4s);
            opacity: 0;
            visibility: hidden;
        }

    }
    &:hover{
        .caption-image{
            &:before{
                @include transition(0.4s);
                opacity: 1;
                visibility: visible;
            }
        }
        .caption-product,.button-groups{
            opacity: 1;
            @include transition(0.4s);
        }
    }
    .button-groups{
        opacity: 0;
    }
    .caption-product{
        opacity: 0;
        @include transition(0.4s);
        position: absolute;
        bottom:5px;
        @include rtl-left(30px);
        @include rtl-right(30px);
        margin: auto;
        &.show-cart{
            bottom:50%;
            margin-bottom: -70px;
        }
    }
    .ground-addcart{
        padding-top: 30px;
        margin: auto;
        text-align: center;
        a{
            font-size: 14px;
            text-transform: capitalize;
            border: 1px solid $theme-color;
            color: $theme-color;
            padding: 10px 20px;
            -webkit-transition: 0.2s;
            -o-transition: 0.2s;
            transition: 0.2s;
            @include transition(0.4s);
            &:hover{
                background: $theme-color;
                color:#fff;
                @include transition(0.4s);
            }
        }

    }
    .caption{
        text-align: center;
        min-height:auto;
    }
    .product-name{
        text-align:center;
    }
    .product-name a{
        color: $color-title;
        font-size: 16px;
    }
    .price,.price ins{
        color: $color-title;
    }
    &.product-trans-lg{
        .product-price,.product-name{
            text-align: left;
        }
        .caption{
            text-align: left;
        }
        .caption-product{
            right:0;
            left: 30px;
            bottom: 20px;
        }
        .product-price .shopswatchinput{
            padding-right: 15px;
        }
    }

}
//Gallery
.product-gallery{
    &:hover{
        .caption-product {
            opacity: 1;
            visibility: visible;
            @include transition(0.4s);
        }
        .show-cart{

        }

    }
    .description-product{
        display: block;
        padding-top: 20px;
    }
    .button-groups{
        opacity: 1;
        right:20px;
        left: auto;

    }
    .caption-product{
        width: 100%;
        height: 100%;
        opacity: 0;
        visibility: hidden;
        @include transition(0.4s);
        position: absolute;
        padding:0px;
        bottom:0px;
        @include rtl-left(0px);
        @include rtl-right(0px);
        margin: auto;
        &.show-cart{
            .ground-addcart{
                display: block;
            }
        }
    }
    .caption-product-inner{
        background: rgba(255, 255, 255, 0.8);
        width: 100%;
        height: 100%;
        padding: 60px;
        position: relative;
    }
    .caption{
        @include rtl-text-align-left();
        min-height:auto;
        padding-top: 100px;
    }
    .ground-addcart{
        display:none;
        margin: auto;
        @include rtl-text-align-left();
        @include transition(0.4s);
        a,.button{
            background: none;
            color: $color-title;
            font-size: 14px;
            padding:0px;
            @include transition(0.4s);
            font-weight: 400;
            &:hover{
                color:$color-title;
                text-decoration: underline;
                @include transition(0.4s);
            }
        }

    }

    .product-name{
        @include rtl-text-align-left();
        padding-bottom: 5px;
        a{
            color: $color-title;
            font-size: 20px;
            line-height: 28px;
        }

    }
    .price,.price ins{
        font-size: 16px;
        opacity: 1;
        visibility: visible;
        color: $color-title;
        @include transition(0.4s);
    }
}

//Scattered
.product-scattered{
    .caption-image{
    }
    &:hover{
        .show-cart{
            .ground-addcart{
                @include transition(0.4s);
                opacity: 1;
                visibility: visible;
                z-index: 99;
            }
            .price,.price ins{
                @include transition(0.4s);
                opacity: 0;
                visibility: hidden;
            }
        }
        .wishlist-buttom{
            opacity: 1;
            visibility: visible;
            @include transition(0.4s);
        }

    }
    .wishlist-buttom{
        opacity: 0;
        visibility: hidden;
        @include transition(0.4s);
    }
    .caption-product{
        opacity: 1;
        @include transition(0.4s);
        position: absolute;
        bottom:15px;
        @include rtl-left(30px);
        @include rtl-right(30px);
        margin: auto;
        &.show-cart{
            .ground-addcart{
                display: block;
            }
        }
    }
    .ground-addcart{
        display:none;
        position: absolute;
        bottom: 20px;
        opacity: 0;
        visibility: hidden;
        margin: auto;
        @include rtl-text-align-left();
        @include transition(0.4s);
        a,.button{
            background: none;
            color:$theme-color;
            font-size: 14px;
            padding:0px;
            @include transition(0.4s);
            text-decoration: underline;
            font-weight: 400;
            &:hover{
                text-decoration: underline;
                @include transition(0.4s);
            }
        }

    }
    .caption{
        @include rtl-text-align-left();
        min-height:auto;
    }
    .product-name{
        @include rtl-text-align-left();
    }
    .product-name a{
        color: $color-title;
        font-size: 16px;
    }
    .price,.price ins{
        opacity: 1;
        visibility: visible;
        color: $color-title;
        @include transition(0.4s);
        line-height: 40px;
    }
}

.product-category{
    position: relative;
    margin-bottom: 40px;
    .woocommerce-loop-category__title{
        position: absolute;
        top:30px;
        @include rtl-left(45px);
        margin: auto;
        @include rtl-text-align-left();
        font-size: 18px;
        color:$color-title;
        a{
            font-weight: 400;
            padding-bottom: 5px;
        }
    }
    mark, .mark{
        background: none;
        font-weight: 400;
        padding-top: 8px;
        display: block;
        font-size: 14px;
        color: #898989;
    }
    .btn-shop{
        display: none;
        padding-top: 10px;
        font-size: 14px;
        color: $theme-color;
        text-decoration: underline;
    }
    &.category-list{
        margin-bottom: 10px;
        .woocommerce-loop-category__title{
            position: relative;
            top:0px;
            @include rtl-left(0px);
        }
        .count{
            display: none;
        }
    }
}

//Layout BOX
.items-scattered{
    .sm-center{
        text-align: center;
        .product-block{
            padding-top: 100px;
            max-width: 370px;
            display: inline-block;
        }
    }
    .sm-right{
        padding-top: 100px;
        text-align: right;
        .product-block{
            max-width: 370px;
            display: inline-block;
        }
    }
    .lg-right{
        text-align: right;
        padding-right: 100px;
        .product-block{
            max-width: 470px;
            display: inline-block;
        }
    }
    .lg-left{
        text-align: left;
        .product-block{
            max-width: 470px;
            display: inline-block;
        }
    }
    .lg-center{
        text-align: right;
        .product-block{
            max-width: 470px;
            display: inline-block;
        }
    }

    .product-scattered,.product-scattered-sm{
        margin-bottom: 100px;
    }
    .products-block > li:nth-child(2n+1){
        @include rtl-clear-left();
    }
    .button-groups{
        display: none;
    }
    //affect
    .col-item{
        transform: translate3d(0, 120px, 0);
    }
    .come-in {
        transform: translate3d(0, 120px, 0);
        animation: come-in 1s ease forwards;
    }
    .come-in:nth-child(odd) {
        animation-duration: 0.8s;
    }
    .already-visible {
        /*transform: translateY(0);*/
        transform: translate3d(0, 0, 0);
        animation: none;
    }
    @keyframes come-in {
        to { transform: translate3d(0, 0, 0); }
    }
}


//FIx element
.archive_product_info{
    .rating{
        display: none;
    }
}
.wrap-select{
    position: relative;
    &:after{
        background:transparent;
        color: #777777;
        content: "\f123";
        font-family: "ionicons";
        height: 100%;
        line-height: 52px;
        pointer-events: none;
        position: absolute;
        right: 0;
        text-align: center;
        top: 0;
        width: 10px;
        font-size: 16px;        
    }
    >select{
        padding: 5px 20px;
        border: none;
        color: $color-title;
        line-height: 40px;
        height: 50px;
        background:#fff;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
    }

}
.woocommerce-result-count{
    font-size: 14px;
    line-height: 40px;
    margin: 0;
    color:#777777;
}
.top-products .woocommerce-alert{
    display:none;
}
.group_table{
    td.label{
        vertical-align: middle;
    }
}
.wrap-breadcrumb .title-page{
    display: block;
}

//Layout Column
.col1{
    .col-item{
        width: 100%;
    }
}
.col2{
    .col-item{
        width: 50%;
    }
}
.col3{
    .col-item{
        width: 33.33%;
    }
}
.col4{
    .col-item{
        width: 25%;
    }
}
.col5{
    .col-item{
        width: 20%;
    }
}
.col6{
    .col-item{
        width: 16.66%;
    }
}
.paging_numbers{
    .pagination{
        padding: 0;
    }
}
.shop-content.layout-filter-full{
    padding-top: 0;
}