/* Macbook Pro Retina 13" */
@media (max-width: 1440px) {
    .cart-box {        
        width: 450px;    
        @include transform(translateX(450px));
    }
    .cart-panel {
        min-width: 450px;
        padding: 50px;
    }
    .cart_list{
        padding-top: 0;
        margin: 40px 0 100px;
        max-height: 490px;  
        li{
            .remove {
                padding-top: 0;     
            }
            .cart-main-content {
                padding-bottom: 0;
            }      
        }        
        .empty {
            font-size: 28px;
            line-height: normal;  
            padding-top: 0;   
        }
    }
    .cart-bottom {           
        padding-left: 50px;
        padding-right: 50px;
        padding-bottom: 60px;
    }
    .login-wrap{
        .lost_password{
            line-height: 24px;
        }
    }
    .search-title {
        padding-top:100px;
        font-size: 28px;
        line-height: 38px;        
    }
    .myaccount-login {
        padding-top: 40px;
    }
    .canvas-warp {
        padding: 40px;        
    }
    .search-box, .intro-box, .login-box, .mobile-box {
        width: 450px;        
        @include transform(translateX(600px)); 
    }
    .wpb_text_column{
        &.text_column_faq {             
            padding-left: 320px;
        }   
    }    
    .vc_custom_heading{
        &.custom_heading_faq {
            font-size: 48px !important;
            line-height: 60px !important;   
        }       
    } 
    .wpb-js-composer{
        .vc_tta-accordion{
            &.vc_tta.vc_general{
                .vc_tta-panel-body {
                    padding-top: 36px;
                }
            }             
            &.accordion_faq {    
                padding: 140px 0 0 0;                
            }   
        }        
    }     
    .site-header{
        &.fixed{
        }
    }
    .na-cart{
        .icon-cart{
            .mini-cart-items {
                right: -30px;
            }
        }         
    }         
    .nano-intro{
        .content-box {
            height: 450px;
            min-height: 450px;
            padding: 0 50px;        
            @include flexbox();
            @include align-items(center);
            @include justify-content(flex-start);           
        }
        .title-text{
            margin-top: 0;
        }        
    }         
    .bg-cover.bg-lg{
        min-height: 450px;
    }
    .product-grid{
        .ground-addcart{
            a {
                padding: 0px 20px;
            }
        }         
    }             
    .nano-showcase{
        .sub_box {
            top: 26%;
        }
        .cat-menu{
            h2 {
                font-size: 76px;
                line-height: 130px;
            }
        }     
        .content-left{
            padding-right: 50px;
            .content-box {
                padding-left: 22%;
            }
            .title-box{
                left: 26%;            
                max-width: 54%;                             
                a {
                    font-size: 36px;
                    line-height: 46px;
                }
            }             
            .col-item {
                padding-top: 25%;
            }
            .gallery-top {        
                height: auto;
                display: block;
                width: 100%;                                
                @include vertical-align(absolute);
            }
        }     
        .content-right {
            width: 45%;
            padding-left: 60px;
            padding-right: 60px;
        }    
        .border-mask{
            &.center {    
                top: 35%;
            }
            &.center-right {
                right: -14%;
            }
        }        
        .content-left{
            .col-item {                
                .entry-image{
                    padding-left: 22%;
                    padding-right: 16%;                
                }
            }
        }         
    }        
    .block-products-widgets{
        &.block-duel {    
            padding-left: 10%;
            padding-right: 10%;
        }       
    }    
    .page-header {
        padding: 180px 0;
    } 
    .user-mini, .btn-intro, .btn-mini-search, .mini-cart {
        line-height: 80px;
    }
    .na-cart .icon-cart {
        line-height: 80px;
    }
    .na-cart .icon-cart .mini-cart-items {    
        top: 20px;
    }
    .button.single_add_to_cart_button {
        padding-left: 30px;
        padding-right: 30px;
    }
    .product-layout-sticky{
        .sticky-product-detail {            
            padding: 110px 65px 0 65px;
        }
    } 
    .meta-sizeguide{
        .modal-header {
            min-height: 0;
            padding: 0;     
            i{
                font-size: 80px;
            }
        }
        .modal-body {
            padding-left: 140px;
            padding-right: 140px;
            .modal-inner {
                max-width: 800px;    
                margin: 10% auto 0 auto;
            }
        }
    }
    .modal-header{
        .close {
            width: 70px;        
            height: 70px;
            top: 70px;
            right: 70px;
            color: $color-title;
        }
    } 
    .page-title{
        line-height: 114px;
    }
    .error-404{
        padding-bottom: 0;   
    }
    .post-list{
        .article-content {
            width: 55%;
            padding-left: 0;
            padding-right: 0;
        }
    } 
    .sidebar{
        .entry-title {
            font-size: 16px;
            line-height: 26px;    
        }
    } 
    .address-contact{
        padding-left: 36px;
    }
    .wpcf7-form{
        padding-right: 36px;
    }
}