// add class

//no padding
.no-padding {
  padding: 0;
}
.no-margin {
    margin: 0 !important;
}
.row-nopadding{
    margin: 0;
    [class*="col-"]{
        padding: 0;
    }
}

.has-padding-top{
    padding-top: 50px;
}

.balance-padding-left{
    @include rtl-padding-left(7.5px);
}

.balance-padding-right{
    @include rtl-padding-right(7.5px);
}

.archive-blog.padding-balance article{
    margin-bottom: 15px;
}

.balance-padding{
    //padding: 7px;
    .row{
        margin: 0 -7.5px;
    }
    [class*="col-"]{
        padding: 0 7.5px;
    }
    .col-md-6:nth-child(2n+1){
        clear: left;
    }
}
//padding vc
.no-hidden{
    overflow: inherit !important;
}
.vc_row-fluid{
    &[data-vc-stretch-content="true"]{
        padding: 0 60px;
        &.vc_row-no-padding{
            padding: 0;
        }
    }
    > .wpb_column >.vc_column-inner{
        padding: 0;
    }
}
.vc_column-inner{
    .wpb_button,
    .wpb_content_element, ul.wpb_thumbnails-fluid > li{
        margin-bottom: 0px;
    }
}
.site-main{
    .vc_col-has-fill > .vc_column-inner,
    .vc_row-has-fill + .vc_row-full-width + .vc_row > .vc_column_container > .vc_column-inner,
    .vc_row-has-fill + .vc_row > .vc_column_container > .vc_column-inner,
    .vc_row-has-fill > .vc_column_container > .vc_column-inner{
        padding-top: 0px;
    }
}

.inline{
    input{
        height:auto;
    }
}
.bg-cover{
    position: relative;
    text-align: center;
    margin-top: 0;
    margin-bottom: 0px;
    background-size: cover;
    background-position: center center;
    min-height: 450px;
    &.bg-lg{
        min-height: 650px;
    }
}
// fix padding 14px (banner single)
.has-padding{
    margin: 0 -7.5px !important;
    .wpb_column{
        padding: 0 7.5px;
    }
    .vc_row{
        margin-left:-7.5px ;
        margin-right: -7.5px;
    }
    .vc_column-inner {
        padding-left:0 !important;
        padding-right:0 !important;
    }
    .wpb_content_element{
        margin-bottom:14px;
    }
}
.padding-big{
    padding: 400px 0;
}
.table-inner{
    @include clearfix();
    position: relative;
    display: table;
    width: 100%;
    height: auto;
    >*{
        display: table-cell;
        vertical-align: middle;
        @include rtl-padding-left(15px);
        &:first-child{
            @include rtl-padding-left(0px);
        }
    }
}
.close{
    font-size: 14px;
}
.link{
    color: $white;
    &:hover{
        color: $theme-color;
    }
    .fa{
        @include rtl-margin-left(7px);
    }
}

.box-equal{
    display: table;
    width: 100%;
    >*{
        width: 50%;
        float: none !important;
        display: table-cell;
        vertical-align: top;
        vertical-align: middle;
    }
    .wpb_content_element{
        margin-bottom: 0px;
    }

    .wpb_text_column{
        bottom: 0;
        left: 0;
//        margin-left: 20px;
        position: absolute;
        @include rtl-right(0px);
        top: 0;
        .wpb_wrapper{
            bottom: 0;
            height: 30%;
            left: 0;
            margin: auto !important;
            position: absolute;
            right: 0;
            top: 0;
            vertical-align: middle;
            width: 70%;
        }
    }
}
// Medium screen / desktop 992-1199
@media (min-width: $screen-md) and (max-width: $screen-md-max) {
    .box-equal{
        display: block;
        >.wpb_column{
            width: 100%;
            @include rtl-float-left();
            display: block;
            vertical-align: top;
            vertical-align: middle;
        }
        .wpb_text_column{
            position: relative;
            .wpb_wrapper{
                position: relative;
            }
        }
    }
}
// Small screen / tablet 768-991
@media (min-width: $screen-sm) and (max-width: $screen-sm-max) {
    .box-equal{
        display: block;
        >.wpb_column{
            width: 100%;
            @include rtl-float-left();
            display: block;
            vertical-align: top;
            vertical-align: middle;
        }
        .wpb_text_column{
            position: relative;
            .wpb_wrapper{
                position: relative;
            }
        }
    }
}

// Small screen / phone 480px-767px
@media (max-width: $screen-xs-max) {
    .box-equal{
        display: block;
        >*{
            width: 100%;
            @include rtl-float-left();
            display: block;
            vertical-align: top;
            vertical-align: middle;
        }
        .wpb_text_column{
            position: relative;
            .wpb_wrapper{
                position: relative;
            }
        }
    }
}

.js-vc_parallax-o-image {
    background-attachment: fixed;
    background-clip: padding-box;
    background-position: center center;
    height: 100%;
    top: 0;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
}


.icon-plus{
    position: relative;
}
.center{
    text-align: center;
}

#infscr-loading{
    display:none !important;
}

/** initial setup **/
.nano {
    position : relative;
    width    : 100%;
    height   : 100%;
    overflow : hidden !important;
}
.nano > .nano-content {
    position      : absolute;
    overflow      : scroll;
    overflow-x    : hidden;
    top           : 0;
    right         : 0;
    bottom        : 0;
    left          : 0;
    padding       :0 20px 20px;
}
.nano > .nano-content:focus {
    outline: thin dotted;
}
.nano > .nano-content::-webkit-scrollbar {
    display: none;
}
.has-scrollbar > .nano-content::-webkit-scrollbar {
    display: block;
}
.nano > .nano-pane {
    background : rgba(0,0,0,.25);
    position   : absolute;
    width      : 10px;
    @include rtl-right(0);
    top        : 0;
    bottom     : 0;
    visibility : hidden\9; /* Target only IE7 and IE8 with this hack */
    opacity    : .01;
    -webkit-transition    : .2s;
    -moz-transition       : .2s;
    -o-transition         : .2s;
    transition            : .2s;
    border-radius: 2px;
}
.nano > .nano-pane > .nano-slider {
    background: #444;
    background:$theme-color;
    position              : relative;
    margin                : 0 1px;
    border-radius: 2px;
}
.nano:hover > .nano-pane, .nano-pane.active, .nano-pane.flashed {
    visibility : visible\9; /* Target only IE7 and IE8 with this hack */
    opacity    : 0.99;
}
.bgr-item {
    background: black none repeat scroll 0 0;
    content: "";
    height: 100%;
    left: 0;
    opacity: 0.5;
    position: absolute;
    width: 100%;
    top: 0;
}
.modal-header{
    border: none;
    .close{
        opacity: 1;
        z-index: 999;
        text-shadow: none;
        width: 100px;
        height: 100px;
        position: absolute;
        right: 100px;
        top: 100px;
        color:black;
        border-radius: 100%;
    }
    i{
        font-size: 100px;
        z-index: 999;
        opacity: 1;
        border: none;
    }
}
.overlay{
    .vc_item{
        opacity: 0.5;
        @include transition(0.4s);
        &:hover{
            opacity: 1;
            @include transition(0.4s);
        }
    }
}
.space{
    display: block;
    min-height: 1px;
    position: relative;
}

