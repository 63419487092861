//style widget Slider
.nano-showcase{
    height:100vh;
    margin-left: calc(50% - 50vw);
    margin-right: calc(50% - 50vw);
    .content-left{
        position: relative;
        float: left;
        width: 50%;
        padding-top: 0;
        padding-bottom: 0;        
        padding-right: 75px;
        text-align: right;
        height: 100vh;
        background: #f9e2d6;
        .gallery-top{
            overflow: hidden;
            margin: 0 auto;
            position: relative;
            list-style: none;
            padding: 0;
            z-index: 1;            
        }
        .col-item{
            padding: 24% 0 10% 0;
        }
        .image-box{
            position: relative;
            display: inline-block;
            .border-mask{
                position: absolute;
                z-index: -1;
                width: 320px;
                height: 200px;
                top: -62px;
                left: -62px;
            }
        }        
        .content-box{
            text-align: left;
        }
        .title-box{
            position: absolute;
            bottom: 90px;
            left: 40px;
            z-index: 2;
            margin: 0;
            max-width: 80%;
            text-align: left;
            a{
                font-size: 48px;
                line-height: 60px;
                color: #FFF;
                font-weight: 400;
            }
        }

    }

    .content-right{
        position: relative;
        float: left;
        width: 50%;
        padding-left: 100px;
        padding-right: 100px;
        height: 100%;
        .gallery-thumbs{
            overflow: hidden;
            margin: 0 auto;
            position: relative;
            list-style: none;
            padding: 0;
            z-index: 1;
        }
    }
    .sub_box{
        font-weight: 300;
        position: absolute;
        top: 30%;
        left: 50px;
        text-align: center;
        -ms-writing-mode: tb-rl;
        -webkit-writing-mode: vertical-rl;
        writing-mode: vertical-rl;
        -webkit-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
        -o-transform: rotate(180deg);
        transform: rotate(180deg);
        white-space: nowrap;
        vertical-align: middle;
        text-transform: uppercase;
        color: #efb292;
        letter-spacing: 3px;
    }
    .cat-menu h2{
        text-transform: uppercase;
        cursor: pointer;
        height: 100%;
        padding-top: 150px;
        padding-bottom: 150px;
        padding-left: 50px;
        padding-right: 50px;
        margin: 0;
        text-align: center;
        color: #f9e2d6;
        font-size: 94px;
        line-height: 150px;
        -ms-writing-mode: tb-rl;
        -webkit-writing-mode: vertical-rl;
        writing-mode: vertical-rl;
        -webkit-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
        -o-transform: rotate(180deg);
        transform: rotate(180deg);
        white-space: nowrap;
        font-weight: 700;
        color: #efb292;
        -webkit-text-stroke: 1px #efb292;
        -webkit-text-fill-color: transparent;
        &::after{
            text-align: center;
            height: 100%;
            margin: 0;
            content: attr(data-title);
            clip-path: inset(0 100% 0 0);
            -webkit-clip-path: inset(0 100% 0 0);
            -webkit-transition: 1s;
            -moz-transition: 1s;
            transition: 1s;
            position: absolute;
            top: 0;
            color: currentColor;
        }
        &:hover{
            color: #efb292;
            &::after{
                clip-path: inset(0 0 0 0);
                -webkit-clip-path: inset(0 0 0 0);
                -webkit-text-fill-color: #efb292;
            }
        }
    }
    .swiper-slide-thumb-active{
        .cat-menu h2{
            color: #efb292;
            &::after{
                clip-path: inset(0 0 0 0);
                -webkit-clip-path: inset(0 0 0 0);
                -webkit-text-fill-color: #efb292;
            }
        }
    }
    .border-mask{
        position: absolute;
        z-index: -1;
        width: 320px;
        height: 320px;
        &:before{
            position: absolute;
            top:0;
            left: 0;
            content: "";
            width: 100%;
            height: 100%;
            background:
                    -moz-radial-gradient(#fff 25%, transparent 25%),
                    -moz-radial-gradient(#fff 25%, transparent 25%),
                    transparent;
            background:
                    -webkit-radial-gradient(#fff 25%, transparent 25%),
                    -webkit-radial-gradient(#fff 25%, transparent 25%),
                    transparent;
            background-position: 0 0,20px 20px;

            -webkit-background-size:20px 20px;
            -moz-background-size:20px 20px;
            background-size:20px 20px;
        }
        &.bottom{
            z-index: 0;
            left: -200px;
            height: 250px;
            bottom: 0px;
        }
        &.center{
            height: 200px;
            z-index: 0;
            left: 82px;
            right: 0;
            margin: auto;
            margin-top: auto;
            margin-top: -160px;
            top: 27%;
        }
        &.center-right{
            right: 0;
            bottom: 0;
            width: 50%;
            height: 40px;
            &:before{
                background:
                        -moz-radial-gradient(#efb292 25%, transparent 25%),
                        -moz-radial-gradient(#efb292 25%, transparent 25%),
                        transparent;
                background:
                        -webkit-radial-gradient(#efb292 25%, transparent 25%),
                        -webkit-radial-gradient(#efb292 25%, transparent 25%),
                        transparent;
                background-position: 0 0,20px 20px;

                -webkit-background-size:20px 20px;
                -moz-background-size:20px 20px;
                background-size:20px 20px;
            }
        }
    }
}
