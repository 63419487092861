@import "theme/responsive/1440";

// Large screen / wide desktop 1200-1400
@media (min-width: $screen-lg) and (max-width: 1400px){
    .padding-big{
        padding: 300px 0;
    }
    .container-fluid{
        padding-left: 50px;
        padding-right: 50px;
    }
    .product-gallery .caption{
        padding-top: 50px;
    }
    .na-infoCategory .entry-content .des_t{
        display: none;
    }
    .vc_row-fluid[data-vc-stretch-content="true"]{
        padding-left: 50px;
        padding-right: 50px;
    }
    .nano-slider .article-carousel .col-item{
        padding: 0 15px;
    }
    .nano-slider .article-carousel{
        margin: 0 -30px;
    }
    .category-tabs .cat-title.left-fixed{
        display: none;
    }
}

// Large screen / wide desktop 1200
@media (max-width: $screen-lg) {
    .archive-blog{
        .col-md-6:nth-child(2n+1),.col-md-4:nth-child(3n+1),.col-md-3:nth-child(4n+1){
            clear: left;
        }
    }
    .container-fluid{
        padding-left: 50px;
        padding-right: 50px;
    }
    .na-infoCategory .entry-content .des_t{
        display: none;
    }
    .vc_row-fluid[data-vc-stretch-content="true"]{
        padding-left: 50px;
        padding-right: 50px;
    }
    .nano-slider .article-carousel .col-item{
        padding: 0 15px;
    }
    .nano-slider .article-carousel{
        margin: 0 -30px;
    }
    .rev_slider_wrapper{
        .tparrows{
            display: none;
        }
    }
    .footer-vertical{
        width: 100%;
        height: 70px;
        bottom: 0;
        right: 0;
        text-align: center;
        display: inline-block;
        z-index: 0;
        ul{
            margin: 0;
            padding: 0;
        }
        ul li{
            line-height: 70px;
            display: inline-block;
            width: 70px;
        }
    }
}

// Large screen / wide desktop 1024
@media (max-width: $screen-lg) {
    .widget.recent_blog .post-image{
        width: auto;
    }
    //short codes
    .nano-textBlock .image-box{
        padding: 0 50px;
    }
    .scattered-box .title-box{
        font-size: 36px;
        line-height: 42px;
    }
    //imageGallery
    .na-imageGallery .entry-content{
        margin-top: 0;
    }
    .na-imageGallery .title-block{
        font-size: 36px;
        line-height: 42px;
    }
    .na-imageGallery .entry-image{
        @include rtl-padding-right(15px);
    }
    .na-imageGallery .gallery-nav{
        width: auto;
    }
    //text
    .text-responsive {
        h2,h3,h4,h5{
            font-size: 32px !important;
            line-height: 36px !important;
            margin-top: 0;
            @include rtl-text-align-left();
            @include rtl-padding-left(0!important);
        }
        a{
            @include rtl-padding-left(0!important);
        }
    }
    //slider
    #slider-showcase_wrapper,#slider-showcase_forcefullwidth,.tp-fullwidth-forcer{
        max-height: 720px!important;
    }
    .nano-slider .article-carousel .title-box{
        font-size: 26px;
    }
    //product
    .product-list .caption-product .button-groups{
        padding-top: 30px;
        @include rtl-padding-left(0!important);
    }
    .product-gallery .caption{
        padding-top: 0;
    }
    .product-trans{
        .ground-addcart{
            display: none;
        }
        .caption-image:before {
            opacity: 1;
            visibility: visible;
            background: rgba(0, 0, 0, 0.2);
        }

        .caption-product ,.caption-product.show-cart{
            text-align: left;
            opacity: 1;
            bottom: 10px;
            margin: 0;
            left: 20px;
            right: auto;
        }
        .caption{
            text-align: left;
        }
        .shopswatchinput{
            padding-left: 15px;
        }
    }
    //detail
    .button.single_add_to_cart_button{
        min-width: auto;
    }
    .product-layout-carousel-left .entry-summary-inner{
        padding-top: 60px;
        padding-left: 60px;
        padding-right: 30px;
    }
    .product-layout-carousel-left .woocommerce-product-gallery__image{
        min-height: 600px;
    }
    //single
    .post-tran .entry-content p {
        display: none;
    }
    .post-list{
        .article-image{
            width: 90%;
        }
        .article-content{        
            padding-top: 25px;
            @include rtl-padding-left(30px);
        }
        .entry-title{
            font-size: 28px;
            line-height: 32px;
        }
    }
    .header-content-menu{
        padding-left: 50px;
    }
}
@media screen and (max-width: 600px) {

    .switcher.notranslate{

        visibility: hidden;
    }
}
.addon-name{
    font-size: 16px;
}
.product-detail-wrap .cart{
    display:block;
}

.product-addon select{
    display: block;
    width: 100%;
}
@media screen and (max-width: 600px){
    .single-product .form-group{
        margin:0;
    }
    .product-detail-wrap form.cart .form-inline{
        display:inline-block;
    }
    .button.single_add_to_cart_button{
        display: block;
        float:right;
    }
}
@import "theme/responsive/tablet";
@import "theme/responsive/mobile";