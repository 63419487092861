
.widget{
    select{
    }
    &.center{
        text-align: center;
    }
    &.left{
        @include rtl-text-align-left();
    }
    &.right{
        text-align: center;
    }
    select{
        width: 100%;
    }
}
//title widget
.widgettitle{
    position: relative;
    font-size: 26px;
    margin-top: 0;
    margin-bottom: 0px;
    font-weight: 400;
    display: inline-block;
    @include rtl-text-align-left();
    img{
         width:auto;
         height: auto;
    }
 }

.block{
    margin: 60px 0;
}
.block-title{
    font-size: 36px;
    line-height: 40px;
    color: $color-title;
    text-align: center;
    display:block;
    margin-bottom: 42px;
}
.block-products-widgets .title-ground{
    .block-title{
        float: left;
        margin: 0;
    }
    .btn-unline{
        float: right;
        margin: 0;
        padding-top: 10px;
    }
}
//recent-content sidebar
.block-products-widgets{
    .block-title{
        margin-bottom: 10px;
    }
    .widgetcontent{
        margin-top: 50px;
    }
    .infload-controls{
        padding-top: 30px;
    }
    .items-duel{
        padding-top: 80px;
        .col-item{
            padding-right: 70px;
            padding-left: 70px;
        }
    }
    &.block-duel{
        margin-bottom: 0;
        .block-title{
            font-size: 48px;
            line-height: 68px;
            font-weight: 300;
        }
        .box_des{
            padding-top: 10px;
            font-size: 18px;
            line-height: 34px;
            color: $color-title;
        }
        .product-cat-duel.products-block{
            margin-bottom: 100px;
        }
        .btn-readmore {
            padding: 17px 32px;
            max-width: 250px;
            display: block;
            text-align: center;
            margin: auto;
        }
    }
}
.recent-content {
    img {
        box-sizing: content-box;
    }
    .entry-title{
        padding: 0;
    }
    .posts-listing{
        .post-inner{
            padding-bottom:30px;
            &:last-child{
                padding-bottom: 0;
            }
        }
    }
}
//calendar
.widget_calendar{
    table > thead > tr > th{
        border-bottom: $border-color-base;
    }
}
//widget_layered_nav
.widget_layered_nav,.widget_layered_nav_filters{
    ul li{
        clear: both;
        &.chosen{
            vertical-align:middle;
            color: $color-title;
             > a{
                 color: $color-title;
                 &:before {
                     border: none;
                     background: #cf3b3d;
                 }
                 &:after{
                     color: #FFF;
                     content: "\f2d7";
                     display: block;
                     font-family: "Ionicons";
                     font-size: 8px;
                     height: 18px;
                     @include rtl-left(0px);
                     position: absolute;
                     text-align: center;
                     top: 0;
                     width: 18px;
                     line-height: 20px;
                     z-index: 10;
                 }
             }
            &:hover{
                color: #cf3b3d;
                > a{
                    color: #cf3b3d;
                    &:before {
                        border: none;
                        background: #cf3b3d;
                    }
                    &:after{
                        content: "\f2d7";
                        border: none;
                        background: #cf3b3d;
                        border-radius: 100%;
                    }
                }

                .count{
                    color: #cf3b3d ;
                }
            }
        }
        span{
            @include rtl-float-right();
        }
        a{
            line-height: 20px;
            position: relative;
            @include rtl-padding-left(30px);
            display: inline-block;
            &:before {
                content: "" !important;
                width: 18px;
                height: 18px;
                border: 1px solid #dfdfdf;
                display: block;
                position: absolute;
                @include rtl-left(0);
                border-radius: 100%;
                top: 0px;
                z-index: 5;
                background: transparent;
            }
            &:hover,
            &:focus{
                color: $color-title;
            }
            &:hover:before {
                border: none;
                background:$color-title;
            }
        }
        &:hover{
            .count{
                color: $color-title;
            }
            a:after{
                color: #FFF;
                content: "\f2d7";
                display: block;
                font-family: "Ionicons";
                font-size: 8px;
                height: 18px;
                @include rtl-left(0px);
                position: absolute;
                text-align: center;
                top: 0;
                width: 18px;
                line-height: 20px;
                z-index: 10;
            }
        }
    }
}
.widget_layered_nav_filters{
    ul li{
        &.chosen{
            > a {
                &:after {
                    color: #FFF;
                    content: "\f2d7";
                    display: block;
                    font-family: "Ionicons";
                    font-size: 12px;
                    height: 18px;
                    @include rtl-left(0px);
                    position: absolute;
                    text-align: center;
                    top: 0;
                    width: 18px;
                    line-height: 19px;
                    z-index: 10;
                }
                &:hover{
                    color: #cf3b3d;
                }
            }
        }
    }
}

//sok_layered_nav
.sok_layered_nav{
    div.wcvashopswatchlabel.wcvaround{
        width: 22px;
        height: 22px;
        vertical-align: middle;
        border: 2px solid #fff;
        &:before{
            opacity: 0;
            top: -3px;
            left: -3px;
            width: calc(100% + 6px);
            height: calc(100% + 6px);
            border: 1px solid #000;
            content: "";
            display: block;
            border-radius: 100%;
            text-align: center;
            position: absolute;
        }

    }
    .wcvaswatchinput{
        display: block;
        line-height: 36px;
    }
    div.wcvashopswatchlabel{
        outline: none;
    }
    div.wcva-selected-filter.wcvaround,.wcvaswatchinput:hover {
        .wcvashopswatchlabel {
            outline: none !important;
            &:before{
                opacity: 1;
            }
        }
        span{
            text-decoration: underline;
        }
    }

    div.wcvashopswatchlabel{
        vertical-align: middle;
        width: 22px;
        height: 22px;
        line-height: 22px;
        margin-bottom: 5px;
        position: relative;
        @include rtl-margin-left(0);
        border-radius: 100%;
        &:before{
            opacity: 0;
            top: -3px;
            left: -3px;
            width: calc(100% + 6px);
            height: calc(100% + 6px);
            border: 1px solid #000;
            content: "";
            display: block;
            border-radius: 100%;
            text-align: center;
            position: absolute;
        }
        &:hover{
            &:before{
                opacity: 1;
            }
        }
    }
    div.wcva_filter_textblock{
        width: 30px;
        height: 30px;
        line-height: 30px;
        font-size: 12px;
    }
    .wcvaswatchinput:hover div.wcvashopswatchlabel.wcva_filter_textblock{
        line-height: 30px;
        width: 30px;
        height: 30px;
    }
    .wcvaswatchinput div.wcva-selected-filter.wcvasquare{
        width: 22px;
        height: 22px;
        border: 2px solid #fff;
        outline:none;
        &:before{
            opacity: 1;
            top: -3px;
            left: -3px;
            width: calc(100% + 6px);
            height: calc(100% + 6px);
            border: 1px solid #000;
            content: "";
            display: block;
            border-radius: 100%;
            text-align: center;
            position: absolute;
        }
        &.wcva_filter_textblock{
            line-height: 30px;
            width: 30px;
            height: 30px;
        }
    }
}
.shopswatchinput .wcvaswatchinput{
    width: 22px !important;
    height: 22px !important;

}

div.shopswatchinput .wcvashopswatchlabel{
    width: 20px !important;
    height: 20px !important;
    outline: none;
    border: 2px solid #fff;
    border-radius: 100%;
    position: relative;
    &:before{
        opacity: 0;
        top: -3px;
        left: -3px;
        width: calc(100% + 6px);
        height: calc(100% + 6px);
        border: 1px solid #000;
        content: "";
        display: block;
        border-radius: 100%;
        text-align: center;
        position: absolute;
    }
    &:hover{
        outline: none !important;
        &:before{
            opacity: 1;
        }
    }
    &.wcva_shop_textblock{
        width: 30px !important;
        height: 30px !important;
        line-height: 10px;
        background: transparent;
        border-color: transparent !important;
        font-size: 14px;
        &:hover{
            text-decoration: underline;
            &:before{
                opacity: 0;
            }
        }
    }
}


//widget_search
.sidebar .widget.widget_search{
    .input-group {
        border:none;
        padding: 0;
    }
    .ti-search {
        color: $color-meta;
    }
    .btn-primary {
        border-left: none;
        background:transparent;
    }
    .form-control{
        border: 1px solid $color-border;
    }

}

//information_widget
.information_widget{
    .logo-footer{
        width: auto;
        margin-top: 15px;
    }
    .description{
        margin: 20px 0 15px;
        line-height: 30px;
    }
}
.page-content .wpb_revslider_element{
    margin-bottom: 0;
}

//widget-related
.widget-related {
    text-align: center;
    .products-block{
        margin: 0;
    }
    .widgettitle{
        font-weight: 500;
        font-size: 36px;
        margin-bottom: 60px;
        margin-top: 0px;
    }
}

.widget.recent_blog{
    padding-bottom: 0;
    .post-item{
        position: relative;
    }
    .widgettitle{
        font-size: 20px;
        color:$color-title;
        font-weight: 500;
        text-transform: uppercase;
        border: none;
        padding-bottom: 20px;
    }
    ul > li {
        @include rtl-padding-left(30px  !important);
    }
    li > a{
        font-weight: 500;
        color:$color-title;
    }

    .post-date{
        display: block;
    }
    .entry-title{
        padding: 0;
    }
    .entry-header{
        padding:15px 0;
    }
    .entry-title a{
        color: $color-title;
        font-size: 14px;
        font-weight: 500;
        line-height: 22px;
        @include rtl-padding-left(10px);
        display: block;
        &:hover{
            color: $theme-color;
        }
    }
    .entry-meta{
        font-size: 12px;
        color: $font-color;
        @include rtl-padding-left(10px);
        display: block;
    }
    .number-post{
        background: #ff4422 none repeat scroll 0 0;
        border: 2px solid #fff;
        border-radius: 100%;
        color: white;
        font-size: 14px;
        font-weight: 500;
        height: 35px;
        @include rtl-left(-15px);
        line-height: 34px;
        position: absolute;
        text-align: center;
        top: 40px;
        width: 35px;
        z-index: 99;
    }
    .size-sok-sidebar{

    }
    .entry-header-title{
        @include rtl-float-left();
        width: 70%;
    }
    .post-item:last-child{
        border: none;
    }
    .post-image{
        @include rtl-float-left();
        width: 30%;
    }
    .no-image{
        .number-post{
            background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
            color: #f2756f;
            font-size: 30px;
            font-weight: 300;
            @include rtl-left(-5px);
            top: 15px;
        }
        .entry-header-title{
            @include rtl-padding-left(40px);
            width: 100%;
        }
    }

}
.help-phone {
    color: $color-black;
    font-weight: 500;
}
.widget_price_filter {
    margin-bottom: 10px !important;
    .widgettitle{
        margin-bottom:0px;
    }
    .widgettitle + *{
        padding-top: 10px;
    }
    .price_slider {
        margin: 1em 0 2em;
    }

    .price_slider_amount {
        line-height: 2.4;
        font-size: 0.8751em;

        .button {
            font-size: 14px;
            color: #fff;
            box-shadow: none;
            border-radius: 0;
            text-transform: uppercase;
            background: #1A1A26;;
            padding: 3px 25px 0;
        }
    }

    .ui-slider {
        position: relative;
        text-align: left;
        margin-left: .5em;
        margin-right: .5em;
    }

    .ui-slider .ui-slider-handle {
        background-color: white;
        border-radius: 100%;
        box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.3);
        cursor: ew-resize;
        height: 20px;
        margin-left: -0.5em;
        outline: medium none;
        position: absolute;
        top: -9px;
        width: 20px;
        z-index: 2;
    }

    .ui-slider .ui-slider-range {
        position: absolute;
        z-index: 1;
        font-size: .7em;
        display: block;
        border: 0;
        border-radius: 0;
        background-color: #1a1a26;
    }

    .price_slider_wrapper{
        .ui-widget-content {
            border-radius: 3px;
            background-color: #eaeaea;
            border: 0;
        }
        .price_label{
            font-size: 12px;
            float: right;
            padding: 5px 0;
        }
    }

    .ui-slider-horizontal {
        height: 4px;
    }

    .ui-slider-horizontal .ui-slider-range {
        top: 0;
        height: 4px;
    }

    .ui-slider-horizontal .ui-slider-range-min {
        left: -1px;
    }

    .ui-slider-horizontal .ui-slider-range-max {
        right: -1px;
    }
}
.widget_product_categories{
    .product-categories{
        a:hover{
            text-decoration: underline;
        }
        > li > a{
            font-size: 18px;
            font-weight: 500;
        }
        .cat-parent{
        }
        ul.children{
            display: none;
            padding-bottom: 20px;
            padding-top: 10px;
            li{
                line-height: 30px;
            }
            a{
                font-size: 16px;
                font-weight: 300;
            }
        }
        .current-cat-parent ul.children,.current-cat ul.children{
            display: block;
        }
        .current-cat > a{
            text-decoration: underline;
        }
    }
}