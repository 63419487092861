/*Style category blog*/

.archive_description{
    text-transform: capitalize;
    color: #f2f2f2;
}

.archive-blog{
    .item-post{
        //margin-bottom: 40px;
        &:last-child{
            margin-bottom: 0;
        }
        &:last-child article{
            margin-bottom: 0;
        }
    }
    .entry-content{
        padding-bottom: 20px;
        color: $font-color;
        font-size: 18px;
        line-height: 34px;
        h1,h2,h3,h4,h5{
            margin-top: 10px;
        }
        p{
            margin-bottom: 10px;
        }
        .page-links{
            display: none;
        }
    }

    .entry-action{
        padding-top: 10px;
        @include rtl-padding-left(30px);
    }

    .entry-title{
        font-size: 24px;
        padding: 0;
        margin-top: 10px;
        line-height: 38px;
        font-weight: 500;
    }

    .posted-on{
        padding: 0;
    }

    .author {
        text-transform: capitalize;
        font-size: 12px;
    }

    .icon-icon_tag{
        @include rtl-padding-right(5px);
    }

    .post-image{
        padding-bottom: 0;
    }

    .post-cat{
        font-size: 12px;
        font-weight: 400;
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .comments-link{
        i{
            @include rtl-padding-right(1px);
        }
    }

    .article-meta{
        vertical-align: middle;
        .meta-author,.meta-date,.meta-cat{
            display: inline-block;
            font-size: 12px;
            position: relative;
            padding-left: 15px;
            padding-right: 15px;
            text-transform: uppercase;
            color: $color-meta;
            a{
                color:$color-meta;
                &:hover{
                    color: $color-title;
                }
            }
            &:hover{
                color: $color-title;
            }


        }
        .meta-author,.meta-date{
            &::after {
                content: '';
                position: absolute;
                height: 12px;
                width: 1px;
                background: #d4d4d4;
                top: 12px;
                right: -4px;
            }
        }
        .entry-date{
            color:$color-meta;
            &:hover{
                color: $color-title;
            }
        }

        > a{
            @include rtl-padding-right(0px);
        }
        >*{
            @include rtl-padding-right(30px);
        }
        span{
            font-size: 12px;
            &:first-child{
                @include rtl-padding-left(0px);
            }
        }
        .tags-links{
            display: none;
        }
        .sticky-post .fa{
            @include rtl-padding-right(5px);
        }
    }
    .entry-meta-right > span{
        @include rtl-padding-right(10px);
        .by{
            @include rtl-padding-right(5px);
        }
        .author  a{
            text-transform: capitalize;
        }
    }


    .col-lg-4:nth-child(3n+1){
        clear: left;
    }

    &.column3{
        .col-md-6:nth-child(2n+1){
            clear: none;
        }
        .col-md-6:nth-child(2n+2){
            clear: left;
        }
    }

    &.column5{
        .col-md-3:nth-child(4n+1){
            clear: none;
        }
    }

    .full-width{
        .article-meta{
            position: relative;
            width: 100%;
            display: block;
        }
    }

}

//hidden meta
.meta-hidden{
    .article-meta{
        display: none;
    }
}

.description-hidden .entry-content{
    display: none;
}

.description-hidden .full-width .entry-content{
    display: block;
}

//resize subcategory_thumbnail
.cats-block{
    display: block;
    position: relative;
    padding: 0;
    list-style: none;
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 45px;
    &:after {
        content: '';
        display: block;
        clear: both;
    }
}
.category-thumb{
    width: 20%;
}

.col-item >a{
    position: relative;
    display: block;
}
.cat-grid{
    padding-top: 30px;
    .category-thumb{
        padding:0 15px;
    }
}
.cat-carousel{
    padding-top: 10px;
    &:hover{
        .slick-next {
            opacity: 1;
        }
    }
    .slick-next{
        background: #FFF;
        width: 50px;
        height: 50px;
        line-height: 50px;
        box-shadow: none;
        margin-top: -20px;
    }
    .slick-prev{
        display: none;
        margin-top: -50px;
    }
    .slick-next::before {
        content: '\f3d6';
        font-size: 36px;
        line-height: 50px;
        font-family: Ionicons;
    }
    .category-thumb{
        padding:0 5px;
    }
}
.cats-block{
    .product-category .woocommerce-loop-category__title{
        position: absolute;
        bottom: 30px;
        top: auto;
        left: 45px;
        margin: auto;
        text-align: center;
        font-size: 18px;
        color: #1a1a26;
        background: rgba(255, 255, 255, 0.9);
        padding: 15px 20px;
        right: 45px;
    }
}