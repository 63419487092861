//style widget Testimonials

.nano-testimonial{
    text-align: center;
    .image-box{
        display: inline-block;
        > img{
            border-radius: 100%;
        }
    }
    .des-box{
        padding-top: 30px;
        padding-bottom: 60px;
        max-width: 800px;
        margin: auto;
        display: block;
        font-size: 16px;
        line-height: 24px;
        color: $color-title;
    }
    .name-box{
        font-size: 18px;
        color: $color-title;
        display: block;
        @include rtl-float-left();
        width:50%;
        @include rtl-text-align-right();
        @include rtl-padding-right(35px);

    }
    .position-box{
        display:block;
        font-size: 14px;
        @include rtl-float-left();
        width:50%;
        @include rtl-text-align-left();
        @include rtl-padding-left(35px);
        line-height: 28px;
        position: relative;
        &:before{
            content: "";
            position: absolute;
            @include rtl-left(-22px);
            top:15px;
            width: 50px;
            height: 1px;
            background: $color-meta;
        }
    }
}

