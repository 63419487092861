//Style
.padding-content-right {
    padding-right:85px;
}
.padding-content-left {
    padding-right:85px;
}
.sidebar{
    padding-bottom: 50px;
    color: $font-color;
    font-size: 16px;
    position: -webkit-sticky ;
    position: sticky ;
    top: 100px;
    .content-inner{
        background: none;
        box-shadow: none;
    }
    .widget{
        margin-bottom: 80px;
        &:last-child{
            margin-bottom: 0px;
        }
        &.widget_layered_nav{
            margin-bottom: 80px;
        }
    }
    //element
    a{
        &:hover{
            color: $theme-color;
        }
    }
    ul{
        list-style: none;
        padding: 0;
        li{
            color: $font-color;
            font-size: 16px;
            font-weight: 400;
            line-height: 40px;
            a{
                letter-spacing: 0.6px;
                &:hover{
                    color: $color-title;
                }
            }
            &:first-child{
                padding-top: 0px;
            }
            &:last-child{
                padding-bottom: 0;
                border-bottom:none;
            }
            &.current-cat >a{
                color: $color-title;
            }
            .children{
            }
            ul li{
                @include rtl-padding-left(20px);
            }

        }
    }
    //widget
    .widget{
        overflow: visible;
        &:first-child{
            .widgettitle{
                border-top:none;
            }
        }
    }
    .widgettitle{
        font-size: 24px;
        font-weight: 500;
        display: block;
    }
    .widget  > h3 {
        position: relative;
        margin: 0;
        font-size: 24px;
        font-weight: 500;
        text-transform: capitalize;
        display: inline-block;
        @include rtl-text-align-left();
        + *{
            margin: 0;
            padding-top:30px;
        }
    }
    .widgettitle + *{
        margin: 0;
        padding-top:35px;
    }
    select{
        margin:20px 0 0;
        width: calc(100%);
    }
    //---------------------------------------------------------------------------------------
    .products{
        padding-bottom: 30px;
        [class*="col-md-"]{
            float: none;
        }
    }
    .product-block{
        padding-top: 15px;
        margin-bottom: 15px;
        border: none;
        &:hover{
            border: none;
            .caption-product{
                @include translateY(0px);
            }
        }
        &.border{
            border:none;;
            &:hover{
                border:none;
            }
        }
        .caption-product{
            background: none;
        }
    }

    .button-groups,.quick-view,.labels{
        display: none;
    }
    .productid-content-inner{
        margin-bottom: 20px;
    }
    .name{
        padding-top: 0;
        margin-top: 0;
        @include rtl-text-align-left();
    }
    .price{
        color: #373636;
        font-size: 14px;
        font-weight: 400;
    }
    .price-review{
        @include rtl-text-align-left();
    }
    .grid-price{
        margin-top: 0;
    }
    .product-image{
        width: 80px;
        @include rtl-float-left();
        @include rtl-margin-right(10px);
        .inner{
            margin: 0;
        }
    }
    .product-details{
        padding-top: 15px;
        a{
            font-weight: 400;
            display: block;
        }
    }
    ul.product_list_widget{
        padding:0 20px;
        .product-title{
            display: block;
            margin-top: 20px;
            margin-bottom: 10px;
        }
        >li{
            clear: both;
            border: none;
            &:first-child{
                padding-top: 20px;
            }
            &:after{
                clear: both;
                content: " ";
                display: table;
            }
            ins{
                text-decoration: none;
            }
        }

    }
    .caption{
        min-height: 75px;
    }

    &.product-list{
        .products{
            padding-bottom: 0;
        }
    }

    .product-block:hover .price {
        opacity: 1;
        transition: all 0.4s ease 0s;
        visibility: visible;
    }
    .products{
        [class*="col-"]{
            padding: 0;
        }
    }
    del{
        font-size: 12px;
        color: #8e8e8e;
    }
    //
    .widget.dropdown{
        .widgettitle{
            &:after{
                color: #282828;
                content: "\f489";
                font-family: "ionicons";
                font-size: 12px;
                position: absolute;
                @include rtl-right(15px);
                top: 0px;
            }
        }
        .widgetcontent.dropdown-menu{
            position: relative;
            float: none;
            border: none;
            box-shadow: none;
        }
    }
    //
    .vc_column_container > .vc_column-inner{
        padding: 0;
    }
    //recentcomments
    #recentcomments{
        li > a{
            color: $theme-color;
        }
    }

    &.shop-sidebar{

    }
    //widget blog ----------------------
    .post-image{
        @include rtl-float-left();
        padding-bottom: 0;
    }
    .side-item-text{
        @include rtl-float-left();
        width: 65%;
        &.full-width{
            width: 100%;
        }
    }
    .entry-title{
        font-size: 18px;
        line-height: 26px;
        max-width: initial;
        margin: 0;
        padding-top:5px;
        padding-bottom:0px;
    }
    .article-meta,.article-meta-date,.entry-date{
        color:$color-meta;
        font-size: 14px;
        line-height: 20px;
        margin: 0;
        padding-top: 15px;
        background: none;
        display: none;
        border-top: none;
        a{
            color: $color-meta;
        }
    }
    .entry-header{
        padding: 0;
    }
    article{
        padding: 10px 0;
        &:first-child{
            padding-top: 0;
        }
    }
    .post-cat{
        font-size: 12px;
        font-weight: 400;
    }
    .recent-post-widgets li{
        padding: 15px 0;
    }

    .information_widget{
        padding: 0px;
        box-shadow: none;
    }
    .post-sidebar{
        min-height: auto;
        margin-bottom: 0;
        .article-image{
            width: 35%;
        }
    }
}
.canvas-overlay.show {
    background-color: rgba(0, 0, 0, 0.4);
    cursor: pointer;
    height: 100%;
    opacity: 1;
    position: fixed;
    visibility: visible;
    width: 100%;
    z-index: 998;
}


//Filter
.filter-header{
    position: relative;
    border-bottom: 1px solid #e9e9e9;
    padding-bottom: 30px;
}
.title-filter{
    font-size: 30px;
    font-weight: 500;
    color: #1a1a26;
    line-height: 50px;
}
.shop-sidebar{
    background: #fff none repeat scroll 0 0;
    border-radius: 0;
    height: 100%;
    opacity: 1;
    position: fixed;
    @include rtl-left(0);
    top: 0;
    transform: translateX(-600px);
    transition: all 0.5s ease 0s;
    visibility: visible;
    width: 360px;
    padding:60px 30px;
    z-index: 999;
    overflow-y: auto;
    .widget{
        margin: 40px 0px 30px;
        padding-bottom: 30px;
        border-bottom: 1px solid #e9e9e9;
        .widgettitle , > h3{
            color: #282828;
            display: block;
            font-size: 16px;
            font-weight: 500;
            padding: 0;
            margin: 0;
            @include rtl-text-align-left();
            text-transform: capitalize;
            &:after{
                content:none;
            }
        }
        > * + *{
            padding-top: 20px;
        }
    }
    .widgettitle + ul{
        padding-top: 15px;
    }
}
.filter-open .shop-sidebar {
    transform: translateX(0px);
    z-index: 10000;
}
.admin-bar .shop-sidebar{
    padding-top: 90px;
}
