html{
    height: 100%;
}

body{
    margin: auto;
    font-size: 18px;
    font-weight: 300;
    color: $font-color;
    background: $theme-bg;
    overflow-x:hidden;
    line-height: 34px;
    height: 100%;
    word-wrap: break-word;
}
#page{
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    min-height: 100%;
}
.site-content {
    -webkit-flex: 1 0 auto;
    -ms-flex: 1 0 auto;
    flex: 1 0 auto;
    height: auto;
    min-height: 0;
}
footer{
    clear: both;
    @include rtl-float-left();
    position: relative;
    @include rtl-right(0px);
    width: 100%;
}

a{
	line-height: 1;
    color: $font-color;
	&:hover,
	&:focus{
            outline: none;
            text-decoration: none;
            color: $theme-color;
	}
}
btn{
    @include transition(0.4s);
    &:hover,
    &:focus{
        @include transition(0.4s);
    }
}
h1,h2,h3,h4,h5,h6{
    margin-bottom: 20px;
    margin-top: 50px;
    font-weight: 500;
    color: $color-title;
}
h1,h2,h3,h4,h5,h6{
    > a{
        color:$color-title;
    }
}


/* Mozilla based browsers */
::-moz-selection {
    background-color: $color-title;
    color: #fff;
}

/* Works in Safari */
::selection {
    background-color: $color-title;
    color: #fff;
}

th{
  font-weight: 500;
}
ul{
    li{
        line-height: 36px;
    }
}
select{
    height:38px;
    padding: 5px 10px;
    border: $border-color-base;
    font-size: $font-size-base;

}

label{
    color:$color-title ;
    font-size: $font-size-base;
    display:inline-block;
    vertical-align: middle;
    font-weight: 500;
    line-height: 24px;
}
address{
    line-height: 38px;
}
b,strong{
    font-weight: 500;
    color: $color-title;
}
.radio, .checkbox{
    display: inline-block;
}
blockquote,.wp-block-quote:not(.is-large):not(.is-style-large)  {
    font-size: 22px;
    line-height: 38px;
    font-weight: 400;
    position: relative;
    margin-bottom: 35px;
    color: #1a1a26;
    margin-top: 35px;
    text-align: center;
    padding: 120px 100px 20px 100px;
    border: none;
    &:before{
        position: absolute;
        top:40px;
        left: 0;
        right: 0;
        margin: auto;
        content: "\f347";
        font-family: Ionicons;
        font-size: 24px;
        color: #FFF;
        width: 50px;
        height: 50px;
        line-height:50px;
        border-radius: 100%;
        text-align: center;
        background:$color-title;
    }
    p{
        margin-bottom: 10px;
    }
    cite{
        color: #7e7a7a;
        font-weight: 400;
    }
}
:focus {
    outline: none;
}
p{
    margin: 0 0 30px;
}
img{
    max-width: 100%;
    height: auto;
}
.img.size-full{
    height: auto;
    max-width: 100%;
}
.alignright{
    @include rtl-float-right();
}
.alignleft{
    @include rtl-float-left();
}
.aligncenter{
    text-align: center;
    display: block;
    margin-left: auto;
    margin-right: auto;
}
textarea.form-control  {
    padding-top: 20px;
    padding-left: 15px;
    background-color: #FFF;
    background-image: none;
    border: 1px solid #e6e5e1;;
}
input{
    height: 50px;
    -webkit-appearance: none;
    appearance: none;
    background-image: none;
    border: 1px solid #e6e5e1;;
}
.form-control{
    -webkit-appearance: none;
    appearance: none;
    background-image: none;
    border: 1px solid #e6e5e1;;
}
input[type="submit"]{
    background-color: #1a1a26;
    border-color: #1a1a26;
    border-style: solid;
    color:#fff;
    cursor: pointer;
    font-size: 14px;
    padding:15px 20px;
    font-family: $font-family-two;
    font-weight: 400;
    letter-spacing: 0.5px;
    height: 50px;
    vertical-align: top;
    text-transform: uppercase;
    &:hover,&:focus,&:active{
        background: $color-title;
    }
}
input[type="hidden"]{
    height: 0;
}

input[type="checkbox"], input[type="radio"]{
    height: auto;
}
input[type="checkbox"] {
    -webkit-appearance: checkbox;
    box-sizing: border-box;
    margin-right: 5px;
    margin-top: 0;
    display: inline-block;
    vertical-align: middle;
}

/*video responsive*/
.video-responsive {
	height: 0;
	padding-top: 0;
	padding-bottom: 56.25%;
	position: relative;
	overflow: hidden;
	embed,
	iframe,
	iframe,
	video{
		top: 0;
        @include rtl-left(0);
		width: 100%;
	  	height: 100%;
	  	position: absolute;
	  	border: none;
	}
}
.audio-responsive iframe {
  width: 100%;
  height: 126px;
}
// .dropdown-toggle
.btn-group.open .dropdown-toggle{
    box-shadow: none;
}
.alert{
    margin: 20px 0;
    padding: 20px;
}

.tags-list a, .tagcloud a {
    background:#FAFAFA;
    border: 1px solid #ddd;
    color: $font-color;
    display: inline-block;
    margin-bottom: 5px;
    @include rtl-margin-left(3px);
    padding: 7px 10px;
    font-size: 14px;
    &:hover{
        color: #fff !important;
        background: $color-title;
        border: 1px solid $color-title;
    }
}
dt{
    font-weight: 500;
    color: $color-title;
}
.required {
    border: 0 none;
    color: #ff0000;
    font-weight: 700;
}

//Tag
.tagcloud a{
    font-size: 12px !important;
    color: $font-color;
    &:hover{
        color:$white;
    }
}

pre{
    border: none;
    border-radius: 0;
}

//table
table{
    color: #212121;
    border: 1px solid #e5e5e5;
    thead{
        background: #FAFAFA;
        > tr > th{
            border-bottom: 1px solid #e5e5e5;
        }
    }
    th{
        font-weight: 400;
    }
    tbody{
        font-size: 14px;
    }
}
td,th{
    p{
        margin-bottom:15px;
    }
}
#today{
    a{
        color: #FFF;
    }
}

.mo-openid-app-icons{    
    > a{        
        img{
            margin-left: 0 !important;
            margin-right: 5px !important;
        }
    }    
}