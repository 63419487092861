//style widget Short Code Basic
.address-contact{
    .title-address{
        font-weight: 500;
        font-size: 20px;
        line-height: 36px;
        color: $color-title;
    }
    .content-address{
        font-size: 16px;
    }
}
.box-fixed{
    p{
        font-size: 18px;
        line-height: 24px;
        color: $color-title;
    }
    h2,h1,h3,h4{
        font-size: 60px;
        color: $color-title;
        line-height:60px;
        padding-bottom: 20px;
        margin: 0;
    }
    .btn{
        margin-top: 40px;
        padding: 15px 50px;
    }

}
.box-text{
    padding: 190px 0;
    >*{
        background: #fff;
        padding: 70px 100px;
        max-width: 780px;
        margin: auto;
        text-align: center;
    }
    h2,h1,h3,h4{
        font-size: 42px;
        color: $color-title;
        line-height:40px;
        padding-bottom: 20px;
        margin: 0;
    }
    p{
        font-size: 16px;
        line-height: 26px;
        color: $color-meta;
    }
    .btn{
        margin-top: 40px;
    }
}
//text-list
.list-text{
    h3,h4,h5{
        font-size:18px;
        margin-bottom: 20px;
    }
    ul{
        padding: 0;
        list-style: none;
    }
    li{
        line-height: 30px;
    }
}
.list-address{
    padding: 0;
    list-style: none;
    li{
        line-height: 40px;
        font-size: 16px;
        i{
            @include rtl-padding-right(15px);
            font-size: 20px;
        }
    }
}
//Slider
.tp-leftarrow .right-arrow,.tp-rightarrow .left-arrow{
    display: none;
}
#slider-showcase_wrapper{
    height: calc(100vh - 100px) !important;
}
.over-slider-showcase-tabs .tabs-name{
    background: #fff;
    font-size: 16px;
    letter-spacing: 2px;
    text-transform: uppercase;
    line-height: 100px;
    color: #1a1a26;
    font-weight: 500;
}
.selected .tabs-name{
    color: #1a1a26;
    text-decoration: underline;
}
.over-slider-showcase-tabs{
    text-align: center;
}
.tp-tab{
    opacity: 1;
}
//text-responsive
.text-responsive{

}
.box_des{
    text-align: center;
    font-size: 18px;
    color: $color-meta;
}