// Style Product

/**
 * Star ratings
 */
.star-rating {
  float: right;
  overflow: hidden;
  position: relative;
  height: 16px;
  line-height: 1;
  font-size: 12px;
  letter-spacing: 5px;
  width: 7.4em;
  font-family: 'star';
  &::before {
    content: '\f4b2\f4b2\f4b2\f4b2\f4b2';
    color: $theme-color;
    float: left;
    font-size: 16px;
    top: 0;
    left: 0;
    position: absolute;
    font-family: Ionicons;
  }

  span {
    font-size: 12px;
    letter-spacing: 5px;
    overflow: hidden;
    float: left;
    top: 0;
    left: 0;
    position: absolute;
    padding-top: 1.5em;
    color: $theme-color;
  }

  span::before {
    content: '\f4b3\f4b3\f4b3\f4b3\f4b3';
    top: 0;
    position: absolute;
    font-size: 16px;
    font-family: Ionicons;
    left: 0;
  }
}
.stars{
    line-height: 16px;
}

.woocommerce-product-rating {
    padding: 15px 0;
    @include clearfix();
    line-height: 2;
    display: block;

    .star-rating {
        float: left;
    }
}

.products .star-rating {
  display: block;
  margin: 0 0 0.5em;
  float: none;
}

.hreview-aggregate .star-rating {
  margin: 10px 0 0;
}

#review_form #respond {
  @include clearfix();
  width: auto;
  background: transparent none;
  border: 0;
  .form-submit input {
    left: auto;
  }

  textarea {
    box-sizing: border-box;
    width: 100%;
  }
}

p.stars {
  a {
    position: relative;
    height: 16px;
    width: 1.4em;
    text-indent: -999em;
    display: inline-block;
    text-decoration: none;
    font-size: 14px;
    letter-spacing: 5px;

    &::before {
      font-size: 20px;
      letter-spacing: 5px;
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 1em;
      height: 1em;
      line-height: 1;
      font-family: Ionicons;
      content: '\f4b2';
      text-indent: 0;
      color: #d9d9d9;
    }
    &:hover ~ a::before {
      content: '\f4b2';
      color: $color-title;
    }
  }

  &:hover a::before {
    content: '\f4b3';
    color: $color-title;
  }

  &.selected {
    a.active {
      font-size: 14px;
      letter-spacing: 5px;
      color: $color-title;      
      &::before {
        color: $color-title;
        content: '\f4b3';        
      }

      ~ a::before {
        content: '\f4b2';
        color: $color-title;
      }
    }

    a:not( .active )::before {
      content: '\f4b3';
      color: $color-title;
    }
  }
}

/**
* Product
*/
.product-block{
    display: block;
    position: relative;
    margin-bottom: 50px;
}
/**
* Product affect hover
*/
.product-block{
    background: #FFF;
    overflow: hidden;
    @include transition(0.4s);
    &:hover{
        @include transition(0.4s);
    }
}
.hreview-aggregate {
    .star-rating {
      @include rtl-margin(10px,0px, 0 ,0);
    }
}

/**
* Image product responsive
*/

.product-thumbnail{
    text-align: center;
}

.product-layout-horizontal{
    .product-detail-wrap .product-thumbnails{
        margin: 15px 0px;
    }
    .gallery-nav .slick-list{
        margin: 0 -7.5px;
    }
    .gallery-nav .gallery-cell{
        padding: 0 7.5px;
    }
}
.product-image{
    display: block;
    overflow: hidden;
    position: relative;
    .inner{
        position: relative;
        overflow: hidden;
        display: block;
        text-align: center;
        .wp-post-image,.hsok-image{
            display: inline-block;
        }
    }
    .shopswatchinput{
        position: absolute;
        @include rtl-right(5px);
        bottom: 0;
        .wcvashopswatchlabel{
            border: none;
            width: 12px !important;
            height: 12px !important;
        }
    }
}
.wp-post-image{
    display: inline-block;
    height: auto;
}

/**
* Caption product image
*/
.caption-image{
    position: relative;
}

/**
* Caption product
*/
.caption{
    position: relative;
    display: block;
    z-index: 2;
}

/**
* Name product
*/
.product-name{
  margin:0;
  @include rtl-text-align-left();
  line-height: 20px;
  display: inline-block;
  a{
      @include transition(0s);
      font-size: 18px;
      line-height: 26px;
      font-weight: 500;
      color:$color-title;
      &:hover{
          color:#888890;
      }
  }
}
/**
* Price product
*/

.product-grid{
  .product-price{
    width: 100%;
    @include flexbox();
    @include flex-wrap(wrap);  
    @include align-items(center);
    .price{
      max-width: 70%;
      @include flex-basis(70%);      
      @include flexbox();
      @include justify-content(flex-start);
    }  
    .shopswatchinput{
      max-width: 30%;
      @include flex-basis(30%);    
      @include flexbox();
      @include justify-content(flex-end);
    }
  }
}

.price-review{
  padding: 0 15px 10px;
}
.product-price{
    padding-top: 0px;    
    .shopswatchinput{
      @include rtl-float-right();
    }
}
.price{
    @include transition(0.4s);
    visibility: visible;
    opacity: 1;
    font-size: 16px;
    color:$color-title;
    line-height: 30px;
    ins{
        text-decoration:none;
        color:$color-title;
    }
    del{
        font-size: 16px;
        @include rtl-padding-right(5px);
        color:$color-meta;
    }
}
/**
* Review product
*/
.product-review,.woocommerce-review-link{
    color: $color-meta;
}

/**
* Add review product
*/
.product-add-review,.woocommerce-write-review-link{
    padding:0 5px;
    color: $black;
    a{
        color: $black;
    }
}
/**
* Button sale
*/
.onsale{
    position: absolute;
    color:$color-title;
    font-size: 12px;
    text-transform: uppercase;
    top:15px;
    letter-spacing: 1px;
    @include rtl-left(15px);
    text-align: center;
    border:1px solid $color-title;
    padding: 7px 12px 5px;

}
/**
* Button hot
*/
.onhot{
    @include size(40px,40px);
    border-radius: 100%;
    background: #F56032;
    position: absolute;
    color: #ffffff;
    z-index: 1;
    font-size: 14px;
    top:-5px;
    @include rtl-right(5px);
    text-align: center;
    line-height: 40px;
    font-weight: bold;
    @include rotate(-30deg);

}
.out-of-stock{
    color: #fff;
    font-size: 14px;
    padding: 14px 50px;
    display: block;
    text-transform: uppercase;
    text-align: center;
    margin: 30px 0;
    background: none repeat scroll 0 0 $out-of-stock;
    font-weight: 400;
    z-index: 99;
}
/**
* Product button
*/
.caption-product{
    padding: 20px 0px 0px;
    position: relative;
    text-align: left;
}
.button-groups{
    top: 15px;
    position: absolute;
    z-index: 2;
    @include rtl-right(15px);
    .add_to_wishlist{
        font-size: 0;
    }
    .feedback{
      display: none;
    }
    .yith-wcwl-wishlistexistsbrowse > a{
        font-size: 0;
    }
    .yith-wcwl-add-to-wishlist a{
      font-size: 0;
    }
}
.quantity{
    .qty{
        appearance: textfield;
    }
}

//layout product Taxonomy
.product-taxonomy{
    .layout-large{
        .caption-product{
            position: absolute;
            bottom: 30px;
          @include rtl-left(50px);
            background:transparent;
        }
        .caption{
          border-top: 0;
        }
    }
    .product-block{
      margin-bottom: 30px;
    }
    .layout-large .product-block{
        box-shadow: none;
    }
}

//layout product align
.widgetcontent.center{
    .name{
        text-align: center;
    }
    .caption{
        text-align: center;
    }
    .caption-product .ground-addcart{
        text-align: center;
    }
    .product-block:hover .ground-addcart{
      @include rtl-right(15px);
    }
    .layout-large{
        .name{
          @include rtl-text-align-left();
        }
        .product-block:hover .ground-addcart{
            right:auto;
        }
        .caption-product .ground-addcart{
          @include rtl-text-align-left();
        }
        .caption{
          @include rtl-text-align-left();
        }
    }
}
.product-cat-grid{
    &.col2{
        .col-item{
            padding-left: 50px;
            padding-right: 50px;
        }
        .product-block{
            margin-bottom: 50px;
        }
    }
}
.type-loadShop{
    //background: #ccc;
}
.product-cat-duel{
    list-style: none;
    &.products-block{
        margin-right: -15px;
        margin-left: -15px;
        margin-bottom: 80px;
    }

    &.col2{
        .col-item{
            width: 50%;
            padding-top: 160px;
            &.duel-right{
                text-align: right;
                padding-left: 10%;
                padding-right: 15px;
            }
            &.duel-left{
                text-align: left;
                padding-left:15px;
                padding-right: 10%;
            }
            &.duel-top{
                padding-top: 320px;
            }
            &:first-child{
                padding-top: 0;
            }
            &:nth-child(2){
                padding-top: 160px;
            }
        }
        .product-block{
            margin-bottom: 0;
        }
    }
}
.canvas-header .btn-close{
    z-index:99;
}
.variations_form.cart label.selectedswatch{
    display:flex;
}