//style widget About
.widget.about{
    text-align: center;
    .widgettitle{
        text-align: center;
    }
    .about-image{
        border-radius: 100%;
        max-width: 250px;
        text-align: center;
        margin-bottom: 40px;
    }
    .about-description{
        padding-top: 20px;
        text-align: center;
        line-height: 30px;
        display: inline-block;
        padding-bottom: 20px;
    }
    .sok-social-icon{
        padding-top: 0px;
        [class*="ion-social-"]{
            @include rtl-margin-left(20px);
            width: 40px;
            height: 40px;
            background: transparent;
            color: $color-title;
            font-size: 18px;
            display: inline-block;
            text-align: center;
            -webkit-transition: 0.4s;
            -o-transition: 0.4s;
            transition: 0.4s;
            border-radius: 100%;
            border: 1px solid $color-title;
            line-height: 38px;
        }
        a{
            color: $color-title;
            &:hover{
                color: $theme-color;
            }
        }
    }
}
#na-footer {
    .widget.about {
        @include rtl-text-align-left();
        .about-image{
            margin:0;
            border-radius: 0;
            max-width:inherit;
        }
        .about-description{
            @include rtl-text-align-left();
        }
        .sok-social-icon{
            padding-top:0px;
            border-top:none;
            a{
                @include rtl-margin(0px,10px, 0 ,0);
                border: 1px solid #eee;
            }
        }

    }
}
