//style widget Asymmetric
.block-asymmetric-products{
    .asymmetric-title{
        .block-title{
            text-align: left;
            font-size: 42px;
            line-height: 52px;
            font-weight: 500;
            margin-top: 70px;
            margin-bottom: 30px;
        }
        .block-content{
            margin-bottom: 30px;
        }
    }
    .asymmetric-product{
        position: relative;
        padding-left: 30px;
    }
    .products-blocks{
        padding: 0;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: row;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-flex-wrap: nowrap;
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-align-content: stretch;
        -ms-flex-line-pack: stretch;
        align-content: stretch;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
        margin: 0 -50px;
        //background: #ccc;
        li{
            float: none;
            width: 40%;
            padding:0 50px;
            &:first-child{
                width: 60%;
                .product-block{
                    padding-left: 50px;
                }
            }
            .product-name a{
                font-size: 16px;
            }
            .price{
                font-size: 16px;
            }
            .caption{
                text-align: center;
            }
            .wishlist-buttom{
                display: none;
            }
        }
        .product-block{
            margin-bottom: 0;
        }
    }
    &.style-2{
        .asymmetric-product{
            position: relative;
            padding-left: 0px;
        }
        .asymmetric-title{
            padding-left: 30px;
        }
        .products-blocks{
            li {
                float: none;
                width: 40%;
                padding: 0 50px;
                &:first-child {
                    width: 40%;
                    .product-block{
                        padding-left: 0px;
                    }
                }
                &:last-child {
                    width: 60%;
                    .product-block{
                        padding-right: 50px;
                    }
                }
            }
        }
    }

}
//.container{
//    background: #0b8173;
//}