//style Intro
.nano-intro{
    position: relative;
    margin-bottom: 0;
    .image-box{
        @include rtl-float-left();
        padding: 0;
        width: 60%;
    }
    .content-box{
        @include rtl-float-left();
        padding: 0 60px;
        width: 40%;        
        @include flexbox();
        @include align-items(center);
        min-height: 650px;        
    }
    .content{
        max-width: 500px;
    }
    .title-text{
        font-size: 84px;
        line-height: 110px;
        color: $color-title;
        margin-bottom: 20px;
        margin-top: 0;
    }
    .content-text{
        font-size: 18px;
        line-height: 34px;
        margin-bottom: 40px;
    }
}