//style widget Masonry
.block-masonry-products{
    margin: 0 !important;
    .grid-sizer{
        width: 20%;
        line-height: 0;
    }
    .masonry-product .col-item{
        @include rtl-float-left();
        width: 20%;
        display: flex;
        padding: 5px;
        .product-block{
            margin: 0;
            width: 100%;
            background: #f1f1f1;
            .wp-post-image{
                width: 100%;
            }
        }
    }
    .masonry-product ul{
        padding: 0;
        margin: 0 -5px;
        list-style: none;
    }
    .grid-banner{
        width: 20%;
        a,img{
            width: 100%;
        }
    }
    &.na-left{
        .masonry-product .col-item{
            &:nth-child(4){
                width: 40%;
            }
        }
    }
    &.na-right,&.na-center{
        .masonry-product .col-item{
            &:nth-child(3){
                width: 40%;
            }
        }
    }
}
