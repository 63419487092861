//style widget Scattered Category

.na-scattered-category{

}
.scattered-box{
    position: relative;
    padding: 60px 0;
    .entry-image{

    }
    .entry-content{
        position: absolute;
        top:50%;
    }
    .title-box{
        font-weight: 500;
        font-size: 48px;
        line-height: 58px;
        margin-bottom: 10px;
        color: $color-title;
    }
    .des-box{
        font-size: 18px;
        color: $color-meta;
        line-height: 24px;
        padding-top: 10px;
        padding-bottom: 40px;
    }
    .btn-box{
        font-weight: 500;
        position: relative;
        font-size: 16px;
        line-height: 24px;
        display: block;
        color: $color-title;
        padding-left: 50px;
        text-transform: uppercase;
        padding-top: 10px;
        -webkit-transition: 0.4s;
        -o-transition: 0.4s;
        transition: 0.4s;
        &:after{
            content: '';
            position: absolute;
            left: 0;
            bottom: 12px;
            width: 40px;
            height: 1px;
            background: $color-title;
        }
    }
    //position
    &.ver-left{
        padding-right: 15px;
        float: left;
        width: 50%;
        text-align: right;
        .entry-content{
            text-align: left;
            top:100px;
            right: 50%;
        }
    }
    &.ver-right{
        padding-left: 15px;
        float: left;
        width: 50%;
        text-align: right;
        .entry-content{
            text-align: left;
            top:100px;
            right: 50%;
        }
    }
    &.squ-right{
        float: left;
        width: 50%;
        text-align: right;
        .entry-image{
            padding-top: 80px;
        }
        .entry-content{
            text-align: left;
            top:60%;
            left:100px;
            width: 50%;
        }
    }
    &.squ-left{
        padding-right: 15px;
        float: left;
        width: 50%;
        text-align: center;
        .entry-image{
            padding-top: 80px;
        }
        .entry-content{
            text-align: left;
            top:60%;
            left:0;
            width: 50%;
        }
    }
    &.hoz-center{
        width: 100%;
        clear: both;
        text-align: center;
        .entry-content{
            margin-top: 0;
            text-align: left;
            top:100px;
            right:0;
            width: 27%;
        }
    }
}
.container{
    //background: #ccc;
}

