//style widget Instagram
.widget.widget_instagram{
    position: relative;
    .space-0{
        margin: 0px;
        .item-instagram{
            padding: 0;
        }
    }
    .space-5{
        margin: -5px;
        .item-instagram{
            padding:5px;
        }
    }
    .space-10{
        margin: -10px;
        .item-instagram{
            padding:10px;
        }
    }
    .space-15{
        margin: -15px;
        .item-instagram{
            padding: 15px;
        }
    }

    .item-instagram{
        float: left;
        text-align: center;
    }

    .col-3 {
        width: 33.33%;
    }
    .col-4 {
        width: 25%;
    }
    .col-5{
        width:20%;
    }
    .col-6 {
        width:16.66%;
    }
    .col-7 {
        width:14.28%;
    }
    .col-8 {
        width:12.5%;
    }
    .instagram-follow-link{
        margin: 0 !important;
    }
    .btn-follow{
        font-size: 18px;
        line-height: 28px;
        display: inline-block;
        text-align: center;
        box-shadow: 0 -1px 0 #ccc inset;
        margin: 0 auto 60px;
    }
}
