//logo
.site-logo{
    line-height: 50px;
    display: inline-block;
}
.site-title{
    font-size: 30px;
    margin: 0;
    a{
        color: $color-title;
        font-weight: 500;
        text-transform: uppercase;
        line-height: 40px;
    }
}
.site-description{
    margin: 0;
    font-size: 16px;
}
//language
#na-top-navbar{
    .language{
        position: relative;       
    }
    #language-switch{
        background: none;
        border: none;

        ul{
            margin-top: 0;
            background: $topbar-bg;
            border-radius:0;
            box-shadow: none;
            padding: 0;
            > li{
                border-top:$topbar-border-color;
                list-style:none;
                float: none;
                color: $topbar-color;
                border-left:none ;
                border-right:none ;
                a{
                    display: inline-block;
                }
                span{
                    color: $topbar-color;
                    cursor: pointer;
                    &:hover{
                        color: $theme-color;
                    }
                }
            }
        }
        img{
            @include rtl-margin-right(5px);
        }
        .fa{
            @include rtl-margin-left(5px);
        }
        .icl_lang_sel_translated{
            display: none;
        }
    }
}
.woocommerce-currency-switcher{
    display: none;
    height: 0;
}

.wrap-select-currency,.wrap-select-country{
  position: relative;
  &:after{
    background:transparent;
    color: $topbar-color ;
    content: "\f107";
    font-family: "FontAwesome";
    height: 100%;
    line-height: 26px;
    pointer-events: none;
    position: absolute;
    @include rtl-right(0);
    text-align: center;
    top: 0;
    width: 10px;
    font-size: 14px;
  }
}
.currency_switcher{
  .woocommerce-currency-switcher {
    background: none;
    border: none;
    @include  rtl-margin-right(10px);
  }
  .woocommerce-currency-switcher-form{

    .dd-pointer{
        display: none !important;
    }
    .dd-select{
      background: none !important;
      border: none;
    }
    .dd-desc{
      display: none;
    }
    .dd-selected{
      padding: 0 ;
      width: 40px;
      line-height: 24px;
    }
    .dd-selected-text,.dd-option-text{
        color: $topbar-color ;
        margin: 0;
        text-transform: capitalize;
        font-weight: 400 !important;
        cursor: pointer;
        font-size: 12px;
        &:hover{
            color: $theme-color;
        }
    }
    .dd-options{
      position: absolute;
      width: 95px !important;
      top:32px;
      @include rtl-left(-20px);
      box-shadow:none;
      background: $topbar-bg;
      border: $topbar-border-color;
      li{
        display: block;
        width: 100%;
        float: none;
        @include rtl-border-left(none !important) ;
        border-bottom: $topbar-border-color;
        &:last-child{
            @include rtl-border-right(none !important) ;
        }
      }
      a{
        background: none;
        border-bottom: none;
      }
    }
  }
}
//cart ==================
.na-cart{
    .icon-cart{
        position: relative;
        z-index: 1;
        display: block;
        color:$icon-bar ;
        line-height: 100px;
        .mini-cart-items{
            background:$icon-bar;            
            color: #fff !important;
            font-size: 12px;
            height: 24px;
            line-height: normal;
            position: absolute;            
            text-align: center;
            top: 36px;
            width: 24px;       
            @include rtl-right(-30px);
            @include flexbox();
            @include align-items(center);
            @include justify-content(center);            
            @include border-radius(50%); 
        }
        .ion-bag{
            margin-left: 0;
            margin-right: 10px;
        }
    }
    .group-mini-cart{
        position: relative;
        overflow: hidden;
        display: none;
    }
    .total{
        padding:40px 0px 0;
        font-weight: 500;
        border-top:1px solid #dddddd;
        .amount{
            color: $color-title;
            font-size: 26px;
            line-height: 30px;
        }
    }
    .quantity{
        margin: 0;
    }
}
//widget cart ==========================================================================================================
.icon-cart{
    font-size: 22px;
}
.cart-panel{
    padding:80px;
    min-width: 600px;
    background: #fff none repeat scroll 0 0;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    position:relative;
    justify-content: start;
}
.cart-panel-title{
    display: block;
    padding-bottom: 15px;
    overflow: hidden;
}
#cart-panel-loader.show {
    visibility: visible;
    +*{
        opacity: 0;
        visibility: hidden;
    }
}
.icon-cart{
    cursor: pointer;
}
#cart-panel-loader {
    visibility: hidden;
    position: absolute;
    @include rtl-left(105px);
    top: 70px;

    >*{
        font-size: 16px;
        font-weight: 400;
        position: relative;
        &:before{
            animation: 0.8s ease 0s normal none infinite running dotFade;
            background: $theme-color none repeat scroll 0 0;
            border-radius: 50%;
            color: $theme-color;
            content: "";
            height: 15px;
            @include rtl-left(-30px);
            position: absolute;
            top: 0;
            width: 15px;
        }
    }
}
@keyframes dotFade {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}
@keyframes spin {
    from {
        transform:rotate(0deg);
    }
    to {
        transform:rotate(360deg);
    }
}

.cart-header{
    padding: 0;
    z-index: 99;
    .cart-panel-title{
        display: block;
    }
    .mycart{
        color: $color-title;
        font-size: 26px;
        line-height: 42px;
        text-transform: uppercase;
        font-weight: 400;
        letter-spacing: 1px;
    }
}
.cart-bottom{
    bottom: 0;    
    position: absolute;
    width: 100%;
    background: #FFF;
    padding: 30px 80px 100px;    
    @include rtl-left(0);
    .buttons{
        margin: 30px 0 0;
    }
    .btn{
        display: block;
        margin-top: 15px;
        float: left;
        width: 48%;
        &.btn-variant{
            float: right;
        }
    }
}

.cart-box{
    background: #fff;
    position: fixed;
    top: 0;
    visibility: visible;
    z-index: 10000;
    height: 100%;
    border-radius: 0;
    width: 600px;
    @include opacity(1);        
    @include rtl-border-left(1px solid #e9e9e9);
    @include transition(0.5s);
    @include rtl-right(0);
    @include transform(translateX(600px)); 
    .empty{
        border: none !important;
    }
    .btn-close{
        position: absolute;
        top: 0;
        right: 0;
        outline: thin;
        background: transparent;
        @include square(80px);        
        @include flexbox();
        @include justify-content(center);
        @include align-items(center);
        @include opacity(1);        
        &:hover{
            background: transparent;
            @include opacity(1);
        }
    }
}
.admin-bar{
    .cart-box{
        padding-top: 32px;
    }
}
.product-remove{
    vertical-align: middle;
    text-align: center;
    a {
        width: 24px;
        height: 24px;
        font-size: 20px;
        border-radius: 100%;
    }
}
.remove,.aws-search-clear span,.quantity{
    font-weight: 400;
}

.cart_list{
    margin: 55px 0 145px;
    list-style: none;
    overflow-y: auto;
    max-height: 400px;    
    .product_list_widget{
        visibility: visible;
        padding: 0;
        margin-top: 10px;
    }
    .empty{
        font-size: 36px;
        line-height: 24px;
        border-bottom: none;
        padding-bottom: 0;
        font-weight: 500;
    }
    .variation{
        display: none;
    }
    .amount{
        @include rtl-float-right();
        color: $color-title;
    }
    li{
        position: relative;
        text-align: left;
        display: block;
        padding-bottom: 15px;
        padding-top: 15px;
        @include clearfix;
        .cart-image{
            width: 30%;
            @include rtl-padding-right(20px);
            @include rtl-float-left();
        }
        .cart-main-content{
            width: 70%;
            @include rtl-float-left();
            padding-bottom: 20px;
        }
        .name{
            margin: 0;
            padding: 0;
            @include rtl-padding-right(20px);
            a{
                font-size: 18px;
                font-weight: 500;
                color: $color-title;
            }
        }
        .cart-item{
            display:block;
            line-height: 24px;
            margin: 0;
            padding: 0;
            padding-top: 5px;
            vertical-align: top;
        }
        .woocommerce-Price-amount{
            position: absolute;
            top: 22px;
            right: 0;
            font-size: 16px;
            color: #201f1f;
        }
        .remove{
            padding-top: 20px;
            font-size: 14px;
            line-height: 20px;
            color:#ea7a64;
            text-transform: capitalize;
        }
        &.media{
            margin-top: 0;
        }
    }
    .wp-post-image{
        width: 80px !important;
        height: 100%;
    }
    .variation{
        dt{
            display: inline-block;
            font-weight: 400;
            font-size: 12px;
            line-height: 10px;
            color:#a3a39b;
        }
        dt + dd{
            display: inline-block;
            font-size:12px;
            line-height: 10px;
            color:#a3a39b;
            >*{color:#a3a39b;}
        }
    }
}
.cart-bottom{
    strong{
        @include rtl-float-left();
        font-size: 22px;
        font-weight: 500;
        line-height: 30px;
    }
    .mini-cart-subtotal{
        display: inline-block;
        @include rtl-float-right();
        line-height: 20px;
        font-size: 26px;
    }
}

.btn-viewcart{
    @include rtl-margin-right(5px);
}
.cart-box-open{
    .cart-box{
        z-index: 99999;
        @include transform(translateX(0px));        
    }
    .canvas-overlay.show{
        background-color: rgba(0, 0, 0, 0.4);
        height: 100%;
        position: fixed;
        width: 100%;
        z-index: 1051;
        opacity: 1;
        visibility: visible;
        cursor: pointer;
    }
    .is-sticky #ri-header{
        &:after{
            content:"";
            background-color: rgba(0, 0, 0, 0.4);
            position: absolute;
            top:0;
            @include rtl-left(0);
            width: 100%;
            height: 100%;
            z-index: 999;
        }
    }
}

.btn-viewcart{
    @include rtl-margin-right(5px);
}


.country_select{
    >*{
        line-height: 30px;
    }
}

.woocommerce{
    form{
        .form-row{
            input.input-text,textarea{
                width: 100%;
                border: $border-color-base;
                padding: 5px 15px;
                background: #fff;
                font-size: 16px;
            }   
            input.input-text{
                height: 50px;
            }
        }         
    }    
}

.select2-container{
    .select2-choice{
        border:1px solid #ebebeb;
    }
} 
.woocommerce table.shop_table {
    width: 100%;
    @include rtl-margin(0,-1px,24px,0);
    @include  rtl-text-align-left;    
}
.select2-container .select2-choice{
    border-radius: 0;
    padding:5px;
    line-height: 24px;
    height: 36px;
}
.f-coupon-code{
    width: 240px;
    display: inline-block;
}

//breadcrumb
.wrap-breadcrumb-cover{
    position: relative;
    overflow: hidden;
    text-align: center;
    max-height: 400px;
    .breadcrumb{
        background: transparent;
        margin-bottom: 10px;
        padding-top: 10px;
    }
}
.breadcrumb-cover{
    position: relative;
    width: 100%;
    background-position: center center !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
}
.group-cover{
    height: 100%;
    position: absolute;
    text-align: center;
    width: 100%;
    z-index: 10;
    background: rgba(33, 33, 33, 0.6);
    &:before {
        content: "";
        display: inline-block;
        height: 100%;
        @include rtl-margin-right(-0.25em);
        vertical-align: middle;
    }
    .title-page{
        font-size: 36px;
        line-height: 40px;
        color: $white;
        letter-spacing: 2px;
        font-weight: bold;
        text-transform: uppercase;
        padding-bottom: 0;
    }
    .csok-inner{
        display: inline-block;
        max-width: 80%;
        position: relative;
        vertical-align: middle;
        text-transform: capitalize;
        color: #ccc;
    }
    a{
        color: #ccc;
        &:hover{
            color: $white;
        }
       &:after{
           content: "-";
           font-size: 10px;
           color: #ccc;
           @include rtl-margin-left(15px);

       }
    }
    .breadcrumb-cover{
        width: 100%;
    }
}
//navigation
.screen-reader-text{
    display: none;
}
.pagination{
    display: block;
    @include clearfix();
    margin:0;
    padding: 30px 0;
    margin-top: 30px;
    text-align: center;
    .page-numbers{
        border: 2px solid #FFF;
        width: 50px;
        height: 50px;
        line-height: 48px;
        display: inline-block;
        padding: 0;
        border-radius: 100%;
        @include rtl-margin-right(10px);
        color: $color-title;
        font-weight: 500;
        vertical-align: middle;
        @include transition(0.4s);
        &:hover{
            border: 2px solid $color-title;
            @include transition(0.4s);
        }
        i{
            font-size: 24px;
        }
    }
    .current,span.current{
        border: 2px solid $color-title;
        color: $color-title;
        width: 50px;
        height: 50px;
        line-height: 48px;
        cursor: pointer;
        @include transition(0.4s);
        &:hover{
            @include transition(0.4s);
            border: 2px solid $color-title;
        }
    }

    >li{
        display: inline-block;
    }
    > li:first-child > a, > li:first-child > span{
        border-radius:100%;
    }

    > li:last-child > a,
    > li:last-child > span{
        border-radius:100%;
    }

}
.paging_loadMore{
    display: none;
}
.image-pagination{
    .page-numbers{
        &:hover{
            border: none !important;
            background:none !important;
        }
        padding: 0;
        border: none;
    }
}
.page-links{
    padding-top: 40px;
    @include rtl-clear-left();
    .page-links-title{
        font-size: 14px;
        color: $color-title;
        text-transform: uppercase;
        @include rtl-padding-right(15px);
    }
    a{
        box-shadow: none !important;
    }
    span.page-numbers{
        text-align: center;
        border: 2px solid #FFF;
        width: 50px;
        height: 50px;
        line-height: 48px;
        display: inline-block;
        padding: 0;
        border-radius: 100%;
        margin-right: 10px;
        color: #222222;
        font-weight: 500;
        vertical-align: middle;
        -webkit-transition: 0.4s;
        -o-transition: 0.4s;
        transition: 0.4s;
        &:hover{
            border: 2px solid #222222;
            color: #222222;
            width: 50px;
            height: 50px;
            line-height: 48px;
            cursor: pointer;
            -webkit-transition: 0.4s;
            -o-transition: 0.4s;
            transition: 0.4s;
        }
    }
    .current > span.page-numbers{
        border: 2px solid #222222;
        color: #222222;
        width: 50px;
        height: 50px;
        line-height: 48px;
        cursor: pointer;
        -webkit-transition: 0.4s;
        -o-transition: 0.4s;
        transition: 0.4s;
    }

}
//calendar
#calendar_wrap{
    padding-bottom: 0;

    #wp-calendar{
        border: 1px solid #dddddd;
    }
    #today{
        background:$theme-color;
        color: $white;
    }
    thead{
        background: #FAFAFA;
    }
    td{
        padding: 8px;
        text-align: center;
    }
    table > thead > tr > th,
    table > tbody > tr > th,
    table > tfoot > tr > th{
        padding: 10px;
    }
}
.table-striped > tbody > tr:nth-of-type(2n+1){
    background: #FFF;
}
.table-striped{
    ul li{
        display: inline-block;
        margin: 0;
    }
    ul{
        padding: 0;
        margin: 0;
        list-style: none;
    }
    .sok-custom-simple{
        @include rtl-text-align-left();
        .att_label{
            text-align: center;
            line-height: 30px;
            color: $black;
            font-weight: 400;
        }
    }
    .att_img,.att_label,.att_color{
        margin: 0;
    }
}
//tabs
.woocommerce-tabs{
    .nav-tabs li{
        background: none;
        font-weight: 400;
        font-family:$font-family-once;
        font-size: 18px;
        padding: 0;
        @include rtl-margin-right(20px);
        &.active{
            color: #000000;
            background: none;
            a{

                color: #000000;
                position: relative;
                &::after{
                    content: '';
                    position: absolute;
                    bottom: -1px;
                    left: 0;
                    right: 0;
                    height: 1px;
                    background: #000000;
                }
                &:hover,&:focus{
                    color: #000000;
                }
            }
            &#tab-title-reviews span{
                background: #000000;
            }
        }
    }
    ul {
        margin: 0;
        padding: 0;
        text-align: center;
    }
    .nav-tabs li {
        display:block;
        margin: 0;
        list-style: none;
        cursor: pointer;
        @include rtl-margin-right(30px);
        a{
            padding: 0 0 10px;
            color:#aaa;
            &:hover,&:focus{
                color: #000000;
                background: none;
                span{
                    background: #000000;
                }
            }

        }
        &#tab-title-reviews:hover,&#tab-title-reviews:focus{
            span{
                background: #000000;
            }
            a:hover,a:focus{
                span{
                    background: #000000;
                }
            }
        }
    }
    .reviews_tab{
        span{
            background: #c0c0c0 none repeat scroll 0 0;
            border-radius: 100%;
            color: white;
            font-size: 12px;
            line-height: 10px;
            padding: 4px 6px;
            position: absolute;
            @include rtl-right(-20px);
            top: -6px;
        }
    }
}

//Category
.expand-icon {
    display: block;
    height: 10px;
    position: relative;
    width: 10px;
}
.expand-icon::after, .expand-icon::before {
    background: #212121 none repeat scroll 0 0;
    content: "";
    display: block;
    position: absolute;
}
.expand-icon:hover::after, .expand-icon:hover::before {
    background: $theme-color none repeat scroll 0 0;
}
.expand-icon::after {
    height: 100%;
    @include rtl-left(calc(50% - 1px));
    top: 0;
    width: 2px;
}
.expand-icon::before {
    height: 2px;
    @include rtl-left(0);
    top: calc(50% - 1px);
    width: 100%;
}
.triggernav.active .expand-icon::after {
    display: none;
}
.availability,.text-share{
    color:$color-title ;
    font-size: $font-size-base;
    font-weight: 400;
}
.alert{
    border-style:dashed;
    border-color: #cbcbcb;
    &.alert-success{
        background: none;
        color: $font-color;
        border-color: #e5e5e5;
        .close{
            display: none;
        }
    }
    .button{
        @include rtl-float-right();
        margin-top: -6px;
        font-weight: 400;
    }
    a{
        color: $theme-color;
        &.button{
            color: $white;
        }
    }

}


.widget  .product-categories span.triggernav {
    @include rtl-float-right();
    padding: 15px;
}
.widget  .product-categories .unvisible {
    height: 0 !important;
}
.widget .product-categories .children {
    display: list-item;
    overflow: hidden;
    transition: all 300ms ease 0s;
}
.contact-maps iframe{
    filter: grayscale(1);
    -webkit-filter: grayscale(1);
    -o-filter: grayscale(1);
    -o-filter: grayscale(1);
}
.wpb_single_image{
    margin: 0!important;;
}

.panel{
    border-radius: 3px;
    background: none;
    .shop_tables{
        border: none;
        background: none;
    }
}
.panel{
    border: none;
    .panel-heading{
        background: none !important;
        padding: 10px 0;
    }
}
.wrapper-breadcrumb{
    >*{
        position: relative;
    }
}
.breadcrumb {
    padding: 0;
    background: transparent;
    color: $theme-color;
    position: relative;
    font-size: 14px;
    margin: 0;
    z-index: 1;
    text-align: center;
    a{
        font-size: 16px;
        margin-right: 30px;
        position: relative;
        &:hover{
            color:$color-title;
        }
        &::after {
            content: '';
            position: absolute;
            height: 12px;
            width: 1px;
            background: $font-color;
            top: 3px;
            right: -16px;
        }
    }
}
.page-header .breadcrumb {
    a,span{
        color: #FFF;
        font-weight: 400;
        z-index: 1;
    }
    a::after {
        background: #FFF;
    }
}
.woocommerce-message,.woocommerce-error{
    border: 1px dashed $theme-color;
    margin: 30px 0;
    padding: 30px;
    font-size: 14px;
    list-style: none;
    .button{
        background: none;
        border: none;
        padding: 0;
        color: $color-title;
        font-size: 16px;
        box-shadow:none;
        @include rtl-margin-right(10px);
        text-decoration: underline;
        &:hover{
            color: $theme-color;
        }
    }
    a{
        color: $theme-color;
    }
}
.demo_store{
    position: fixed;
    top: 45%;
    margin: auto;
    background: #dbe9e6;
    left: 0;
    right: 0;
    max-width: 800px;
    min-height: 200px;
    z-index: 999;
    text-align: center;
    padding: 70px;
    font-size: 18px;
    color: #000;
    line-height: 30px;
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
    a{
        color: $color-meta;
        font-size: 14px;
        text-decoration:underline;
    }
}
.modal-lg{
    margin: 0;
    width: 100%;
    height: 100%;
    background: rgba(255,255,255,1);
    -moz-transition: all 200ms ease-in-out;
    -o-transition: all 200ms ease-in-out;
    -webkit-transition: all 200ms ease-in-out;
    transition: all 200ms ease-in-out;
    text-align: center;
}
.modal-body{
    background: #FFF;
    .modal-inner{
        max-width: 1200px;
        margin: 4% auto;
    }
}