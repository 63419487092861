//style widget Info Banner Customize
.nano-infobanner{
    position: relative;
    overflow: hidden;
    @include transition(0.4s);
    &:before{
        position: absolute;
        width: 100%;
        height: 100%;
        content: "";
        background: rgba(255,255,255,0);
        pointer-events: none;
        @include transition(0.4s);
    }
    .image-content-hover{
        position: absolute;
        opacity: 0;
        visibility: hidden;
        @include transition(0.4s);
    }
    .title-banner{
        font-size: 28px;
        color: $color-title;
        margin-bottom: 15px;
        margin-top: 0;
    }
    img{
        outline: thin;
    }
    &:hover{
        &:before{
            background: rgba(255,255,255,0.8);
            @include transition(0.4s);
        }
        .image-content-hover{
            @include transition(0.4s);
            position: absolute;
            opacity: 1;
            visibility: visible;
        }
    }
    .sub-banner{
        display: block;
        padding-bottom: 15px;
    }
    .btn-banner{
        position: relative;
        font-size: 14px;
        color: $color-title;
        @include transition(0.4s);
        text-transform: uppercase;
        font-weight: 400;       
    }
    &.left-under{
        .image-content-hover{
            bottom:50px;
            @include rtl-left(50px);

        }
    }
    &.left-between{
        .image-content-hover{
            top:50%;
            margin-top: -50px;
            @include rtl-left(50px);
        }
    }
    &.left-top{
        .image-content-hover{
            top:50px;
            @include rtl-left(50px);
        }
    }
    &.center-top{
        .image-content-hover{
            top:50px;
            left: 0;
            right: 0;
            margin: auto;
            text-align: center;
        }
    }
    &.center-center{
        .image-content-hover{
            top: 50%;
            margin-top: 0;
            left: 0;
            right: 0;
            text-align: center;    
            @include transform(translateY(-50%));
        }
    }
    &.center-under{
        .image-content-hover{
            bottom:50px;
            left: 0;
            right: 0;
            text-align: center;
        }
    }
    &.right-under{
        .image-content-hover{
            text-align: right;
            bottom:50px;
            @include rtl-right(50px);
        }
    }
    &.right-top{
        .image-content-hover{
            text-align: right;
            top:50px;
            @include rtl-right(50px);
        }
    }
    &.right-center{
        .image-content-hover{
            text-align: right;
            top:50%;
            margin-top: -50px;
            @include rtl-right(50px);
        }
    }
}
