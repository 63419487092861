//Affect Style

$image-theme-path : "../images/";

/**
* Hover image
*/
.wpb_single_image{
    overflow: hidden;
    display: block;
    a{
        display: block !important;
    }
    .vc_single_image-wrapper{
        display: block !important;
    }
    &:hover{
        cursor: pointer;
    }
}

.hsok-image{
    position: absolute;
    top:0;
    left:-100%;
    @include transition(0.4s);
    height: auto;
}


.img-effect{
    &:hover{
        .wp-post-image{
            @include rtl-margin-left(100%);
            @include transition(0.4s);
        }
        .hsok-image{
            right:0;
            left:0%;
            margin: auto;
            @include transition(0.4s);
        }

    }
}

//border
div.affect-border{
    &:hover{
        &:before{
            top: 1px;
            @include rtl-left(0px);
        }
        &:after{
            top: -1px;
            @include rtl-right(0px);
        }
        div.affect-border-inner{
            &:before{
                top: 0;
                @include rtl-left(0px);
            }
            &:after{
                bottom: 0;
                @include rtl-left(0px);
            }
        }
    }
}
div.affect-border,
div.affect-border-inner{
    position: relative;
    overflow: hidden;
    cursor: pointer;
    position: relative;
    display: block;
    &:before,&:after{
        @include transition(all 0.4s);
        content: " ";
        display: block;
        position: absolute;
        z-index: 99;
        background: $theme-color;
    }
}
div.affect-border{
    &:before,&:after{
        height: 100%;
        width: 2px;
    }
    &:before{
        top: -100%;
        @include rtl-left(0px);
    }
    &:after{
        top: 100%;
        @include rtl-right(0px);
    }
}
div.affect-border-inner{
    &:before,&:after{
        height: 2px;
        width: 100%;
    }
    &:before{
        top: 0;
        @include rtl-left(100%);
    }
    &:after{
        bottom: 0;
        @include rtl-left(-100%);
    }
}

//affect-zoo-border
.affect-zoo-border{
    position: relative;
}
.affect-zoo-border-inner{
    @include translate3d(-20px, 0px, 0px);
    @include transition-transform(0.4s);
    img{
        @include scale(1.1);
    }
    &:hover{
            @include translate3d(0px, 0px, 0px);
    }
}
.affect-zoo-border{
    &:before{
        content:'';
        display: block;
        position: absolute;
        z-index:10;
        pointer-events:none;
        border: 1px solid transparent;
        top: 20px;
        left: 20px;
        bottom: 20px;
        right: 20px;
        @include transition(0.4s);
    }
    &:hover:before{
        @include transition(0.4s);
        border: 1px solid #fff;
    }
}

//affect-border-2
.slide-border .overlay_border{
    position:absolute;
    left:0;
    top:0;
    width:100%;
    height:100%;
    z-index:1;
    pointer-events:none;
}
.slide-border .overlay_border:before,
.slide-border .overlay_border:after{
    content: '';
    display: block;
    position: absolute;
    z-index:10;
    top: 20px;
    left: 20px;
    bottom: 20px;
    right: 20px;
    -webkit-transition: -webkit-transform 0.4s ease 0s;
    transition: transform 0.4s ease 0s;
}
.slide-border .overlay_border:before{
    border-top: 1px solid #fff;
    border-bottom: 1px solid #fff;
    -webkit-transform: scale(0, 1);
    -ms-transform: scale(0, 1);
    transform: scale(0, 1);
}
.slide-border .overlay_border:after{
    border-left: 1px solid #fff;
    border-right: 1px solid #fff;
    -webkit-transform: scale(1, 0);
    -ms-transform: scale(1, 0);
    transform: scale(1, 0);
}
.slide-border:hover .overlay_border:before,
.slide-border:hover .overlay_border:after,
.slide-border.hover .overlay_border:before,
.slide-border.hover .overlay_border:after{
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
}
.slide-border .overlay_border:before,
.slide-border .overlay_border:before,
.slide-border .overlay_border:after,
.slide-border .overlay_border:after {
    top: 30px;
    @include rtl-right(15px);
    bottom: 30px;
    @include rtl-left(14px);
}


//plus
.affect-plus{
    position: relative;
    &::before{
        overflow: hidden;
        position: absolute;
        top: 0;
        content: "";
        z-index: 100;
        @include square(percentage(1));
        @include rtl-left(0);
        opacity:0;
        @include transition(all 0.4s ease 0s);
        @include transform(scale(0.4) rotateY(180deg));
        background: url('#{$image-theme-path}plus.png') no-repeat scroll center center / 60px 60px rgba(#000,.6);
        pointer-events:none;
    }
    &:hover{
        &::before{
            visibility: visible;
            opacity:0.6;
            @include transform(scale(1) rotateY(0deg));
        }
    }
}
//zoom
.affect-zoom{
    position: relative;
    @include transition(0.4s);
    &:after{
        position: absolute;
        @include rtl-left(0px);
        width: 100%;
        height: 100%;
        top:0;
        content: "";
        pointer-events:none;
    }
    img{
        @include scale(1);
        @include transition(0.4s);
    }
    &:hover{

        img {
            @include scale(1.05);
            @include transition(0.4s);
        }
    }
}

.affect-zoom-animation{
    position: relative;
    @include transition(0.4s);
    &:after{
        position: absolute;
        @include rtl-left(0px);
        width: 100%;
        height: 100%;
        top:0;
        content: "";

    }
    &:hover{
        &:after{
            @include transition(0.4s);
            background: rgba(0, 0, 0, 0.3);
        }
        img {
            animation: 10s linear 0s alternate none 2 running animation_zoom;
        }
    }
}

@keyframes animation_zoom {
    0% {
        transform: scale(1) translateX(0px);
    }
    50% {
        transform: scale(1.2) translateX(-10px);
    }
    75% {
        transform: scale(1.3) translateX(-20px);
    }
    100% {
        transform: scale(1.4) translateX(-30px);
    }
}

//flast
.affect-fast{
    position: relative;

    &:after{
        position: absolute;
        @include rtl-left(-100%);
        width: 100%;
        height: 100%;
        top:0;
        content: "";
        background: url('#{$image-theme-path}mlass.png') no-repeat;
        @include transition(1.5s);
        pointer-events:none;
    }

    &:before {
        border: 6px solid rgba(255, 255, 255, 0.5);
        bottom: 0;
        box-sizing: border-box;
        content: "";
        @include rtl-left(0px);
        overflow: visible;
        position: absolute;
        @include rtl-right(0px);
        top: 0;
        pointer-events:none;
    }

    &:hover{
        &:after{
            @include transition(1.5s);
            @include rtl-left(100%);
        }
    }

}

//button
.add-links,.quick-view{
    display: inline-block;
}


.product-block{
    overflow: hidden;
    .wp-post-image{
        @include rtl-margin-left(0);
        @include transition(0.4s);
    }
    .hsok-image{
        display: none;
        position: absolute;
    }
}

.list-view {
    .product-block{
        &:hover{
            .caption-product{
                background: transparent;
            }
            .name a{
                color: $color-title;
            }
        }
    }
}


@-webkit-keyframes button-enter {
    to {
        -webkit-transform: translateY(0);
        transform: translateY(0)
    }
}

@keyframes button-enter {
    to {
        -webkit-transform: translateY(0);
        transform: translateY(0)
    }
}

@-webkit-keyframes button-leave {
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0)
    }
    to {
        -webkit-transform: translateY(-100%);
        transform: translateY(-100%)
    }
}

@keyframes button-leave {
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0)
    }
    to {
        -webkit-transform: translateY(-100%);
        transform: translateY(-100%)
    }
}