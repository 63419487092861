// Automatically injected Bower dependencies via wiredep (never manually edit this block)

@import "variables-theme";
@import "variables";
@import "theme/mixins/rtl";
@import "bootstrap/mixins";
@import "theme/mixins/mixins";
@import "theme/mixins/affect";
@import "theme/common/add-class";
@import "theme/common/global";
@import "theme/common/font";
@import "theme/mixins/buttons";
@import "theme/components/buttons";
@import "theme/components/forms";
@import "theme/components/collapsed";
@import "theme/components/carousel";
@import "theme/components/search";
@import "theme/components/menu";
@import "theme/components/offcanvas";
@import "theme/components/element-woo";
@import "theme/components/social";

@import "theme/layouts/header";
@import "theme/layouts/sidebar";
@import "theme/layouts/footer";
@import "theme/layouts/pages";
@import "theme/layouts/home";
@import "theme/layouts/widget";

@import "theme/blog/single";
@import "theme/blog/category";
@import "theme/blog/post-layout";

@import "theme/products/product";
@import "theme/products/category";
@import "theme/products/detail";

@import "theme/widget/newsletter";
@import "theme/widget/social";
@import "theme/widget/na-recent-post";
@import "theme/widget/tabs-post";
@import "theme/widget/about";
@import "theme/widget/video";
@import "theme/widget/infobanner";
@import "theme/widget/contact_info";
@import "theme/widget/aws";
@import "theme/widget/instagram";
@import "theme/widget/filterSale";
@import "theme/widget/faq";
@import "theme/widget/tabsproduct";
@import "theme/widget/asymmetric-product";
@import "theme/widget/masonry-product";
@import "theme/widget/tabscategory";
@import "theme/widget/scattered-category";
@import "theme/widget/masonry-category";
@import "theme/widget/imageGallery";
@import "theme/widget/info-category";
@import "theme/widget/slider";
@import "theme/widget/slider-cats";
@import "theme/widget/intro_images";
@import "theme/widget/intro";
@import "theme/widget/shortcodes";
@import "theme/widget/testimonials";
@import "theme/widget/service";
@import "theme/widget/textBlock";
@import "theme/widget/teams";

@import "responsive";