// Vendor Prefixes
//
// All vendor mixins are deprecated as of v3.2.0 due to the introduction of
// Autoprefixer in our Gruntfile. They will be removed in v4.

// - Animations
// - Backface visibility
// - Box shadow
// - Box sizing
// - Content columns
// - Hyphens
// - Placeholder text
// - Transformations
// - Transitions
// - User Select


// Animations
@mixin animation($animation) {
  -webkit-animation: $animation;
       -o-animation: $animation;
          animation: $animation;
}
@mixin animation-name($name) {
  -webkit-animation-name: $name;
          animation-name: $name;
}
@mixin animation-duration($duration) {
  -webkit-animation-duration: $duration;
          animation-duration: $duration;
}
@mixin animation-timing-function($timing-function) {
  -webkit-animation-timing-function: $timing-function;
          animation-timing-function: $timing-function;
}
@mixin animation-delay($delay) {
  -webkit-animation-delay: $delay;
          animation-delay: $delay;
}
@mixin animation-iteration-count($iteration-count) {
  -webkit-animation-iteration-count: $iteration-count;
          animation-iteration-count: $iteration-count;
}
@mixin animation-direction($direction) {
  -webkit-animation-direction: $direction;
          animation-direction: $direction;
}
@mixin animation-fill-mode($fill-mode) {
  -webkit-animation-fill-mode: $fill-mode;
          animation-fill-mode: $fill-mode;
}

// Backface visibility
// Prevent browsers from flickering when using CSS 3D transforms.
// Default value is `visible`, but can be changed to `hidden`

@mixin backface-visibility($visibility){
  -webkit-backface-visibility: $visibility;
     -moz-backface-visibility: $visibility;
          backface-visibility: $visibility;
}

// Drop shadows
//
// Note: Deprecated `.box-shadow()` as of v3.1.0 since all of Bootstrap's
// supported browsers that have box shadow capabilities now support it.

@mixin box-shadow($shadow...) {
  -webkit-box-shadow: $shadow; // iOS <4.3 & Android <4.1
          box-shadow: $shadow;
}

// Box sizing
@mixin box-sizing($boxmodel) {
  -webkit-box-sizing: $boxmodel;
     -moz-box-sizing: $boxmodel;
          box-sizing: $boxmodel;
}

// CSS3 Content Columns
@mixin content-columns($column-count, $column-gap: $grid-gutter-width) {
  -webkit-column-count: $column-count;
     -moz-column-count: $column-count;
          column-count: $column-count;
  -webkit-column-gap: $column-gap;
     -moz-column-gap: $column-gap;
          column-gap: $column-gap;
}

// Optional hyphenation
@mixin hyphens($mode: auto) {
  word-wrap: break-word;
  -webkit-hyphens: $mode;
     -moz-hyphens: $mode;
      -ms-hyphens: $mode; // IE10+
       -o-hyphens: $mode;
          hyphens: $mode;
}

// Placeholder text
@mixin placeholder($color: $input-color-placeholder) {
  // Firefox
  &::-moz-placeholder {
    color: $color;
    opacity: 1; // Override Firefox's unusual default opacity; see https://github.com/twbs/bootstrap/pull/11526
  }
  &:-ms-input-placeholder { color: $color; } // Internet Explorer 10+
  &::-webkit-input-placeholder  { color: $color; } // Safari and Chrome
}

// Transformations
@mixin scale($ratio...) {
  -webkit-transform: scale($ratio);
      -ms-transform: scale($ratio); // IE9 only
       -o-transform: scale($ratio);
          transform: scale($ratio);
}

@mixin scaleX($ratio) {
  -webkit-transform: scaleX($ratio);
      -ms-transform: scaleX($ratio); // IE9 only
       -o-transform: scaleX($ratio);
          transform: scaleX($ratio);
}
@mixin scaleY($ratio) {
  -webkit-transform: scaleY($ratio);
      -ms-transform: scaleY($ratio); // IE9 only
       -o-transform: scaleY($ratio);
          transform: scaleY($ratio);
}
@mixin skew($x, $y) {
  -webkit-transform: skewX($x) skewY($y);
      -ms-transform: skewX($x) skewY($y); // See https://github.com/twbs/bootstrap/issues/4885; IE9+
       -o-transform: skewX($x) skewY($y);
          transform: skewX($x) skewY($y);
}
@mixin translate($x, $y) {
  -webkit-transform: translate($x, $y);
      -ms-transform: translate($x, $y); // IE9 only
       -o-transform: translate($x, $y);
          transform: translate($x, $y);
}
@mixin translate3d($x, $y, $z) {
  -webkit-transform: translate3d($x, $y, $z);
          transform: translate3d($x, $y, $z);
}
@mixin rotate($degrees) {
  -webkit-transform: rotate($degrees);
      -ms-transform: rotate($degrees); // IE9 only
       -o-transform: rotate($degrees);
          transform: rotate($degrees);
}
@mixin rotateX($degrees) {
  -webkit-transform: rotateX($degrees);
      -ms-transform: rotateX($degrees); // IE9 only
       -o-transform: rotateX($degrees);
          transform: rotateX($degrees);
}
@mixin rotateY($degrees) {
  -webkit-transform: rotateY($degrees);
      -ms-transform: rotateY($degrees); // IE9 only
       -o-transform: rotateY($degrees);
          transform: rotateY($degrees);
}
@mixin perspective($perspective) {
  -webkit-perspective: $perspective;
     -moz-perspective: $perspective;
          perspective: $perspective;
}
@mixin perspective-origin($perspective) {
  -webkit-perspective-origin: $perspective;
     -moz-perspective-origin: $perspective;
          perspective-origin: $perspective;
}
@mixin transform-origin($origin) {
  -webkit-transform-origin: $origin;
     -moz-transform-origin: $origin;
      -ms-transform-origin: $origin; // IE9 only
          transform-origin: $origin;
}
@mixin translate3d-scale($x, $y, $z,$ratio) {
  -webkit-transform: translate3d($x, $y, $z)  scale($ratio);
          transform: translate3d($x, $y, $z) scale($ratio);

}

// Transitions

@mixin transition($transition...) {
  -webkit-transition: $transition;
       -o-transition: $transition;
          transition: $transition;
}
@mixin transition-property($transition-property...) {
  -webkit-transition-property: $transition-property;
          transition-property: $transition-property;
}
@mixin transition-delay($transition-delay) {
  -webkit-transition-delay: $transition-delay;
          transition-delay: $transition-delay;
}
@mixin transition-duration($transition-duration...) {
  -webkit-transition-duration: $transition-duration;
          transition-duration: $transition-duration;
}
@mixin transition-timing-function($timing-function) {
  -webkit-transition-timing-function: $timing-function;
          transition-timing-function: $timing-function;
}

//== Translate X - Y - Z
//==========================================
@mixin translateX($x) {
    -webkit-transform: translateX($x);
    -ms-transform: translateX($x); // IE9 only
    -o-transform: translateX($x);
    transform: translateX($x);
}

@mixin translateY($y) {
    -webkit-transform: translateY($y);
    -ms-transform: translateY($y); // IE9 only
    -o-transform: translateY($y);
    transform: translateY($y);
}

@mixin translateZ($z) {
    -webkit-transform: translateZ($z);
    -ms-transform: translateZ($z); // IE9 only
    -o-transform: translateZ($z);
    transform: translateZ($z);
}

@mixin translate3d(
    $translate-x: 0,
    $translate-y: 0,
    $translate-z: 0) {
    -webkit-transform: translate3d($translate-x, $translate-y, $translate-z);
    -ms-transform: translate3d($translate-x, $translate-y, $translate-z);
    -moz-transform: translate3d($translate-x, $translate-y, $translate-z);
    transform: translate3d($translate-x, $translate-y, $translate-z);
}


@mixin transition-transform($transition...) {
  -webkit-transition: -webkit-transform $transition;
     -moz-transition: -moz-transform $transition;
       -o-transition: -o-transform $transition;
          transition: transform $transition;
}

@mixin transform($argument){
    -webkit-transform: ($argument);
    -moz-transform: ($argument);
    -ms-transform: ($argument);
    -o-transform: ($argument);
    transform: ($argument);
}


// User select
// For selecting text on the page

@mixin user-select($select) {
  -webkit-user-select: $select;
     -moz-user-select: $select;
      -ms-user-select: $select; // IE10+
          user-select: $select;
}

@mixin clearfix() {
    &:before,
    &:after {
        content: " "; // 1
        display: table; // 2
    }
    &:after {
        clear: both;
    }
}
@mixin size($width, $height) {
    width: $width;
    height: $height;
}

@mixin square($size) {
    @include size($size, $size);
}


@mixin flexbox {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
}

%flexbox { @include flexbox; }


@mixin inline-flex {
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -moz-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
}

%inline-flex { @include inline-flex; }


// Flexbox Direction
//
// The 'flex-direction' property specifies how flex items are placed in
// the flex container, by setting the direction of the flex container's
// main axis. This determines the direction that flex items are laid out in.
//
// Values: row | row-reverse | column | column-reverse
// Default: row
//
// http://w3.org/tr/css3-flexbox/#flex-direction-property

@mixin flex-direction($value: row) {
    @if $value == row-reverse {
        -webkit-box-direction: reverse;
        -webkit-box-orient: horizontal;
    } @else if $value == column {
        -webkit-box-direction: normal;
        -webkit-box-orient: vertical;
    } @else if $value == column-reverse {
        -webkit-box-direction: reverse;
        -webkit-box-orient: vertical;
    } @else {
        -webkit-box-direction: normal;
        -webkit-box-orient: horizontal;
    }
    -webkit-flex-direction: $value;
    -moz-flex-direction: $value;
    -ms-flex-direction: $value;
    flex-direction: $value;
}
// Shorter version:
@mixin flex-dir($args...) { @include flex-direction($args...); }

//----------------------------------------------------------------------

// Flexbox Wrap
//
// The 'flex-wrap' property controls whether the flex container is single-line
// or multi-line, and the direction of the cross-axis, which determines
// the direction new lines are stacked in.
//
// Values: nowrap | wrap | wrap-reverse
// Default: nowrap
//
// http://w3.org/tr/css3-flexbox/#flex-wrap-property

@mixin flex-wrap($value: nowrap) {
    // No Webkit Box fallback.
    -webkit-flex-wrap: $value;
    -moz-flex-wrap: $value;
    @if $value == nowrap {
        -ms-flex-wrap: none;
    } @else {
        -ms-flex-wrap: $value;
    }
    flex-wrap: $value;
}

//----------------------------------------------------------------------

// Flexbox Flow (shorthand)
//
// The 'flex-flow' property is a shorthand for setting the 'flex-direction'
// and 'flex-wrap' properties, which together define the flex container's
// main and cross axes.
//
// Values: <flex-direction> | <flex-wrap>
// Default: row nowrap
//
// http://w3.org/tr/css3-flexbox/#flex-flow-property

@mixin flex-flow($values: (row nowrap)) {
    // No Webkit Box fallback.
    -webkit-flex-flow: $values;
    -moz-flex-flow: $values;
    -ms-flex-flow: $values;
    flex-flow: $values;
}

//----------------------------------------------------------------------

// Flexbox Order
//
// The 'order' property controls the order in which flex items appear within
// their flex container, by assigning them to ordinal groups.
//
// Default: 0
//
// http://w3.org/tr/css3-flexbox/#order-property

@mixin order($int: 0) {
    -webkit-box-ordinal-group: $int + 1;
    -webkit-order: $int;
    -moz-order: $int;
    -ms-flex-order: $int;
    order: $int;
}

//----------------------------------------------------------------------

// Flexbox Grow
//
// The 'flex-grow' property sets the flex grow factor. Negative numbers
// are invalid.
//
// Default: 0
//
// http://w3.org/tr/css3-flexbox/#flex-grow-property

@mixin flex-grow($int: 0) {
    -webkit-box-flex: $int;
    -webkit-flex-grow: $int;
    -moz-flex-grow: $int;
    -ms-flex-positive: $int;
    flex-grow: $int;
}

//----------------------------------------------------------------------

// Flexbox Shrink
//
// The 'flex-shrink' property sets the flex shrink factor. Negative numbers
// are invalid.
//
// Default: 1
//
// http://w3.org/tr/css3-flexbox/#flex-shrink-property

@mixin flex-shrink($int: 1) {
    -webkit-flex-shrink: $int;
    -moz-flex-shrink: $int;
    -ms-flex-negative: $int;
    flex-shrink: $int;
}

//----------------------------------------------------------------------

// Flexbox Basis
//
// The 'flex-basis' property sets the flex basis. Negative lengths are invalid.
//
// Values: Like "width"
// Default: auto
//
// http://www.w3.org/TR/css3-flexbox/#flex-basis-property

@mixin flex-basis($value: auto) {
    -webkit-flex-basis: $value;
    -moz-flex-basis: $value;
    -ms-flex-preferred-size: $value;
    flex-basis: $value;
}

//----------------------------------------------------------------------

// Flexbox "Flex" (shorthand)
//
// The 'flex' property specifies the components of a flexible length: the
// flex grow factor and flex shrink factor, and the flex basis. When an
// element is a flex item, 'flex' is consulted instead of the main size
// property to determine the main size of the element. If an element is
// not a flex item, 'flex' has no effect.
//
// Values: none | <flex-grow> <flex-shrink> || <flex-basis>
// Default: See individual properties (1 1 0).
//
// http://w3.org/tr/css3-flexbox/#flex-property

@mixin flex($fg: 1, $fs: null, $fb: null) {

    // Set a variable to be used by box-flex properties
    $fg-boxflex: $fg;

    // Box-Flex only supports a flex-grow value so let's grab the
    // first item in the list and just return that.
    @if type-of($fg) == 'list' {
        $fg-boxflex: nth($fg, 1);
    }

    -webkit-box-flex: $fg-boxflex;
    -webkit-flex: $fg $fs $fb;
    -moz-box-flex: $fg-boxflex;
    -moz-flex: $fg $fs $fb;
    -ms-flex: $fg $fs $fb;
    flex: $fg $fs $fb;
}

//----------------------------------------------------------------------

// Flexbox Justify Content
//
// The 'justify-content' property aligns flex items along the main axis
// of the current line of the flex container. This is done after any flexible
// lengths and any auto margins have been resolved. Typically it helps distribute
// extra free space leftover when either all the flex items on a line are
// inflexible, or are flexible but have reached their maximum size. It also
// exerts some control over the alignment of items when they overflow the line.
//
// Note: 'space-*' values not supported in older syntaxes.
//
// Values: flex-start | flex-end | center | space-between | space-around
// Default: flex-start
//
// http://w3.org/tr/css3-flexbox/#justify-content-property

@mixin justify-content($value: flex-start) {
    @if $value == flex-start {
        -webkit-box-pack: start;
        -ms-flex-pack: start;
    } @else if $value == flex-end {
        -webkit-box-pack: end;
        -ms-flex-pack: end;
    } @else if $value == space-between {
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
    } @else if $value == space-around {
        -ms-flex-pack: distribute;
    } @else {
        -webkit-box-pack: $value;
        -ms-flex-pack: $value;
    }
    -webkit-justify-content: $value;
    -moz-justify-content: $value;
    justify-content: $value;
}
// Shorter version:
@mixin flex-just($args...) { @include justify-content($args...); }

//----------------------------------------------------------------------

// Flexbox Align Items
//
// Flex items can be aligned in the cross axis of the current line of the
// flex container, similar to 'justify-content' but in the perpendicular
// direction. 'align-items' sets the default alignment for all of the flex
// container's items, including anonymous flex items. 'align-self' allows
// this default alignment to be overridden for individual flex items. (For
// anonymous flex items, 'align-self' always matches the value of 'align-items'
// on their associated flex container.)
//
// Values: flex-start | flex-end | center | baseline | stretch
// Default: stretch
//
// http://w3.org/tr/css3-flexbox/#align-items-property

@mixin align-items($value: stretch) {
    @if $value == flex-start {
        -webkit-box-align: start;
        -ms-flex-align: start;
    } @else if $value == flex-end {
        -webkit-box-align: end;
        -ms-flex-align: end;
    } @else {
        -webkit-box-align: $value;
        -ms-flex-align: $value;
    }
    -webkit-align-items: $value;
    -moz-align-items: $value;
    align-items: $value;
}

//----------------------------------

// Flexbox Align Self
//
// Values: auto | flex-start | flex-end | center | baseline | stretch
// Default: auto

@mixin align-self($value: auto) {
    // No Webkit Box Fallback.
    -webkit-align-self: $value;
    -moz-align-self: $value;
    @if $value == flex-start {
        -ms-flex-item-align: start;
    } @else if $value == flex-end {
        -ms-flex-item-align: end;
    } @else {
        -ms-flex-item-align: $value;
    }
    align-self: $value;
}

//----------------------------------------------------------------------

// Flexbox Align Content

@mixin align-content($value: stretch) {
    // No Webkit Box Fallback.
    -webkit-align-content: $value;
    -moz-align-content: $value;
    @if $value == flex-start {
        -ms-flex-line-pack: start;
    } @else if $value == flex-end {
        -ms-flex-line-pack: end;
    } @else {
        -ms-flex-line-pack: $value;
    }
    align-content: $value;
}

@mixin align($position: relative) {
    position: $position;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%,-50%);
    -ms-transform: translate(-50%,-50%);
    -moz-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
}

@mixin vertical-align($position: relative) {
    position: $position;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    transform: translateY(-50%);
}

@mixin center-align($position: relative) {
    position: $position;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
}

@mixin center-box($position: relative) {
    top: 50%;
    left: 50%;
    position: $position;
    -webkit-transform: translate(-50%,-50%);
    -ms-transform: translate(-50%,-50%);
    -moz-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
}

@mixin position($position, $args) {
    @each $o in top right bottom left {
        $i: index($args, $o);
        @if $i and $i + 1 <=length($args) and type-of(nth($args, $i + 1))==number {
            #{$o}: nth($args, $i + 1);
        }
    }
    position: $position;
}

@mixin absolute($args) {
    @include position(absolute, $args);
}
@mixin hidden() {
    display: none;
    overflow: hidden;
    visibility: hidden;
    @include opacity(0);
}
@mixin appearance ($value) {
    -webkit-appearance: $value;
    -moz-appearance: $value;
    appearance: $value;
}
@mixin border-radius($radius) {
    border-radius: $radius;
    -webkit-border-radius: $radius;
    -moz-border-radius: $radius;
    -o-border-radius: $radius;
    -ms-border-radius: $radius;
}