//style widget Baner Slider

.widget_contact_info{
    .about-image{
        padding-bottom: 35px;
    }
    .address{
        margin-top: 15px;
    }
    .contact-info{
        margin-top: 15px;
    }
    .mobile ,.phone{
        font-size: 32px;
        line-height: 40px;
        color: $color-title;
        font-weight: 400;
        padding-top: 5px;
    }
    .description{
        max-width: 450px;
        line-height: 24px;
        margin-bottom: 0;
    }
}

#na-footer .widget_contact_info a{
    font-weight:400;
    color: $color-meta;
    &:hover{
        color: $color-title;
    }
}
#na-footer.footer-2{
    ul li{
        margin-bottom: 0;
    }
    .footer-bottom{
        padding: 0;
        border: none;
    }
    .container-inner{
        padding: 70px 0 60px;
        border-top: 1px solid #e6e5e1;
    }
    .widget_contact_info{
        .about-image{
            padding-top: 15px;
            @include rtl-float-left();
            width: 33.33%;
            img{
                width: auto;
                height: auto;
            }
        }
        .group-des{
            @include rtl-float-left();
            margin: 0;
            padding: 0 30px;
            width: 33.33%;
            line-height: 28px;
        }

        .contact-info{
            margin: 0;
            @include rtl-padding-left(60px);
            @include rtl-float-left();
            width: 33.33%;
        }
        .mobile ,.phone{
            font-size: 18px;
            line-height: 30px;
            color: $color-title;
            font-weight: 400;
            padding-top: 0px;
        }
        .address{
            margin-top: 0px;
        }
        .description{
            margin-bottom: 15px;
        }
    }
    .sok-social-share{
        padding-top: 5px;
        margin: 0;
        li{
            padding: 0;
        }
    }
}
