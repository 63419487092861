//style widget Image Gallery

.na-imageGallery{
    position: relative;
    .entry-content{
        margin-top: 50px;
        @include rtl-text-align-right();
        display: block;
        @include rtl-float-left();
        width: 50%;
    }
    .entry-content-inner{
        display: inline-block;
        max-width: 600px;
        @include rtl-padding-right(130px);
        @include rtl-padding-left(15px);
        @include rtl-text-align-left();
    }
    .entry-image{
        display: block;
        @include rtl-float-left();
        width: 50%;
    }
    .icon{
        max-width: 130px;
    }
    .title-block{
        font-size: 42px;
        line-height: 52px;
    }
    .des{
        font-size: 16px;
        line-height: 26px;
        padding-bottom: 30px;
    }


    .gallery-main{
        list-style: none;
    }
    .gallery-nav{
        list-style: none;
        width: 365px;
        position: relative;
        margin-top: -100px;
        @include rtl-margin-left(50%);
        .slick-list{
            overflow: hidden;
        }
        .slick-slide{
            &.slick-active{
                display: block;
            }
            &.slick-current{
                display: none;
            }
        }

        .slick-slide{

        }
        .slick-prev{
            top:25px;
        }
        .slick-next{
            top:75px;
        }
        .slick-prev,.slick-next{
            @include rtl-left(-50px);
            box-shadow: none;
            border: none;
            opacity: 1;
            margin-top: 0;
            border-radius:0 ;
            width: 50px;
            height: 50px;
            background: #fff;
            &::before{
                font-size: 18px;
                line-height: 50px;
                opacity: 1;
                color: $color-meta;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
            }
            &:hover{
                background:#f1f1f1;
                &::before{
                    color: $color-title;
                }
            }
        }

    }
}