
//button base
//button-variables-outline($color, $background, $border, $colorhover, $bghover, $borderhover )

/* btn 1 */
.btn-outline{
  @include button-variables-outline($color-title, $color-title,$color-title, #fff, $color-title, $color-title);
}

/* btn 2 */
.btn-inverse{
    @include button-variables-outline($color-title, #FFF , $color-title ,lighten($color-title, 13.5%),#fff,lighten($color-title, 13.5%));    
    border: 0;
    position: relative;
    cursor: pointer;    
    outline: none;
    overflow: hidden;
    padding-top: 16px;
    padding-top: 16px;
    z-index: 1;
    @include transition(color .4s cubic-bezier(.19,1,.22,1));         
    &:after, &:before{        
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
    }
    &:after{
        content: '';
        border: 1px solid $color-title;
    }
    &:before {
        background-color: $color-title;
        content: '';
        z-index: -1;
        @include transform(translateY(100%));
        @include animation(button-leave .4s cubic-bezier(.19,1,.22,1) forwards);       
    }
    &:hover,&:focus{
        color: #FFF;
        i{
            color: #FFF;
        }
        &:before{
            @include animation-name(button-enter); 
        }
    }
}

/* btn-3 */
.btn-variant{
    @include button-variables-outline($white, $theme-color,$theme-color ,$white,lighten($theme-color, 13.5%), lighten($theme-color, 13.5%));
}

.button,.woocommerce-Button{
    @include button-variables-outline($white, #282828 , #282828 , $white, $theme-color ,  $theme-color);
    border-width:0;
    text-transform: uppercase;
}
.btn-lg{
    padding: 15px 30px;
    font-size: 16px;
    letter-spacing: 0.5px;
}

#loadmore-button{
    border: 1px solid #f0f0f0;
    background: #FFF;
    font-size: 14px;
    text-transform: uppercase;
    padding: 15px 25px;
    color:#282828;
    text-align: center;
    display: block;
    &:hover{
        background:$theme-color;
        border: 1px solid $theme-color;
        color:#FFF;
    }
}
.btn-loadmore{
    background: #201f1f;
    font-size: 14px;
    text-transform: uppercase;
    padding: 15px 25px;
    color:#fff;
    text-align: center;
    display: block;
    max-width: 260px;
    margin: auto;
    cursor: pointer;
    &:hover{
        background: $color-title;
        color:#FFF;
    }
}
.btn-readmore{
    font-size: 14px;
    text-transform: uppercase;
    color:$color-title;
    display: inline-block;
    position: relative;
    font-weight: 400;
    letter-spacing: 0.5px;
    padding: 14px 32px;
    border: 0;    
    cursor: pointer;    
    outline: none;
    overflow: hidden;
    z-index: 1;    
    @include transition(color .4s cubic-bezier(.19,1,.22,1));      
    &:after, &:before{        
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
    }
    &:after{
        content: '';
        border: 1px solid $color-title;
    }
    &:before {
        background-color: $color-title;
        content: '';
        z-index: -1;
        @include transform(translateY(100%));
        @include animation(button-leave .4s cubic-bezier(.19,1,.22,1) forwards);       
    }
    &:hover,&:focus{
        color: #FFF;
        i{
            color: #FFF;
        }
        &:before{
            @include animation-name(button-enter); 
        }
    }
}

//ADD TO CART ----------------------------------------------------------------------------------------------------------
.button.single_add_to_cart_button{
    padding: 10px 50px;
    border-radius:0;
    display: inline-block;
    color: #fff;
    background-color: $theme-color;
    border: 2px solid $theme-color;
    font-family: $font-family-once;
    font-size: 14px;
    letter-spacing: 1px;
    text-transform: uppercase;
    font-weight: 400;
    min-width: 285px;
    box-shadow: none;
    -webkit-flex: 1 1 auto;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    @include transition(0.4s);
    &:hover,
    &:focus,
    &:active,
    &.active {
        @include transition(0.4s);
        background-color:darken($theme-color, 10%);
        color: #fff;
        border: 2px solid darken($theme-color, 10%);
    }
    &.disabled{
        cursor: not-allowed;
        opacity: .5;
        pointer-events: none;
    }
}
.woocommerce-variation-add-to-cart{
    display: flex;
}

.page-content .vc_btn3.vc_btn3-style-custom{
    padding: 10px 30px;
    text-transform: uppercase;
    border-radius: 0px;
    letter-spacing: 0.1em;
    color: $white;
    cursor: pointer;
    background-color: $theme-color;
    border: 1px solid $theme-color;
    font-family: $font-family-once;
    font-size: 12px;
    font-weight: 400;
    background-image:none;
    box-shadow:0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
    &:hover,
    &:focus,
    &:active,
    &.active {
        background-color: $theme-color;
        border: 1px solid $theme-color;
        box-shadow:none;
    }
}


.add_to_cart_button,.button.product_type_simple{
    @include button-variables-outline($color-black,transparent, transparent, $theme-color, transparent, transparent);
    padding: 0;
    font-weight: 400;
    font-size: 16px;
    box-shadow: none;
    .fa-shopping-cart{
        display: none;
    }
    &.loading {
        opacity: 0.7;
        position: relative;
        padding-right: 30px;
        &:after {
            font-family: "ionicons";
            content: "\f45a";
            vertical-align: top;
            -webkit-font-smoothing: antialiased;
            font-size: 14px;
            position: absolute;
            top:12px;
            @include rtl-right(10px);
            -webkit-animation: fa-spin 1s infinite steps(8);
            animation: fa-spin 1s infinite steps(8);
        }
    }
    &.added{
        display: none;
    }
}
.added_to_cart{
    @include button-variables-outline($theme-color,transparent, transparent, $theme-color, transparent, transparent);
    padding: 0;
    font-weight: 400;
    box-shadow: none;
}

//wishlist -------------------------------------------------------------------------------------------------------------
.yith-wcwl-add-to-wishlist{
    display: none;
}
.wishlist-buttom{
    display: inline-block;
    .yith-wcwl-add-to-wishlist{
        display: inline-block;
    }
}
#yith-wcwl-popup-message{
    background: rgba(0, 0, 0, 0.5) none repeat scroll 0 0;
    color: white;
    font-size: 16px;
    border: none;
    padding: 40px;
}
.yith-wcwl-add-button a.add_to_wishlist{
    border-radius: 0;

}
.yith-wcwl-add-to-wishlist{
    margin: 0;
    position: relative;
    a.add_to_wishlist{
        font-size: 0px;
        font-weight: 400;
        color: $color-title;
        display: inline-block;
        text-align: center;
        position: relative;
        @include transition(0.4s);
        &:before{
            content: "\f387";
            margin: auto;
            font-family: "ionicons";
            font-size: 20px;
            line-height: 40px;
            text-align: center;
            display: inline-block;
            @include transition(0.4s);
            color: $theme-color;
        }
        &:hover{
            &:before{
                @include transition(0.4s);
                content: "\f388";
                color: $theme-color;
            }
        }
    }
    .ajax-loading{
        left: 0;
        margin: auto;
        position: absolute;
        right: 0;
        top: 32px;
    }
    .feedback{
        display: none;
    }
}
.product-block:hover{
    .yith-wcwl-add-to-wishlist{
        a:before{
            opacity: 1;
            @include transition(0.4s);
        }
    }
}
.yith-wcwl-wishlistexistsbrowse,.yith-wcwl-wishlistaddedbrowse{
    a{
        font-size: 0px;
        font-weight: 400;
        color: $color-title;
        display: inline-block;
        text-align: center;
        position: relative;
        @include transition(0.4s);
        &:before{
            margin: auto;
            content: "\f388";
            font-family: "ionicons";
            font-size: 20px;
            line-height: 40px;
            text-align: center;
            display: inline-block;
            @include transition(0.4s);
            color: $theme-color;
        }
    }
}
.product-detail-wrap{
    .yith-wcwl-add-to-wishlist a.add_to_wishlist,
    .yith-wcwl-wishlistexistsbrowse a,
    .yith-wcwl-wishlistaddedbrowse a{
        font-size: 16px;
        line-height: 22px;
        font-weight: 300;
        box-shadow: 0 -1px 0 #ccc inset;
        &::before{
            content: none;
        }
    }
    .wishlist-buttom{
        float: left;
    }
    .btn-sizeguide{
        float: right;
    }
}

.wishlist_table .add_to_cart.button{
    @include button-variables-outline($color-title, #FFF , $color-title ,$white,$color-title, $color-title);
}

.yith-wcwl-share{
    margin: 0;
    ul{
        @include rtl-float-left();
        list-style: none;
        padding: 0;
        margin: 0;
        position: relative;
        display: block;
        height: auto;
    }
    li{
        display: inline-block;
        height: 40px;
    }
    li a{
        width: 40px;
        height: 40px;
        line-height: 40px;
        font-size: 22px;
        background-image: none !important;
        border: 1px solid #dddddd;
        margin: 0 5px;
        text-align: center;
        &:hover{
            background: $color-title;
            border: 1px solid $color-title;
            color: #fff;
        }
        i{
            width: 40px;
            height: 40px;
            line-height: 40px;
            &:hover{
                color: #FFF;
            }
        }
    }

    .yith-wcwl-share-title{
        @include rtl-padding-right(30px);
        @include rtl-float-left();
        font-weight: 400;
        font-size: 16px;
        color: $color-meta;
    }
}
//quick view -----------------------------------------------------------------------------------------------------------
.yith-wcqv-button{
    display: none;
}

.quick-view{
    display: none;
    opacity: 0;
    position: absolute;
    top:50%;
    left:50%;
    margin-top: -22px;
    @include rtl-margin-left(-22px);
    visibility: hidden;
    @include transition(0.4s);
    z-index: 99;
    .yith-wcqv-button{
        display: block;
        position: relative;
        text-align: center;
    }
    span{
        color: #fff;
        font-size: 24px;
        padding:0;
        right:1px;
        top:2px;
        position: relative;
        text-align: center;
        vertical-align: middle;

    }
    a{
        background: $theme-color;
        border-radius: 100%;
        border-width: 0;
        height: 45px;
        padding: 10px;
        text-align: center;
        width: 45px;
        z-index: 10;
        opacity: 0.8;
    }

}
.return-to-shop{
    width:48%;
    margin-bottom: 0;
    @include rtl-float-left();        
    .wc-backward{
        width:100%;
        display: inline-block;
        text-align: center;
        padding:0px 15px;
        font-size: 14px;
        background: #FFF;        
        color:$color-title ;
        cursor: pointer !important;        
        height: 50px;
        text-transform: uppercase;
        line-height: 50px;        
        border: 0;
        position: relative;
        cursor: pointer;    
        outline: none;
        overflow: hidden;
        z-index: 1;        
        @include transition(color .4s cubic-bezier(.19,1,.22,1));         
        &:after, &:before{        
            left: 0;
            position: absolute;
            top: 0;
            width: 100%;
            height: 100%;
        }
        &:after{
            content: '';
            border: 1px solid $color-title;
        }
        &:before {
            background-color: $color-title;
            content: '';
            z-index: -1;
            @include transform(translateY(100%));
            @include animation(button-leave .4s cubic-bezier(.19,1,.22,1) forwards);       
        }
        &:hover,&:focus{
            color: #FFF;
            i{
                color: #FFF;
            }
            &:before{
                @include animation-name(button-enter); 
            }
        }        
    }
}

.btn-updatecart{
    @include rtl-float-right();
    display: inline-block;
    text-align: center;
    padding: 7px 15px;
    width:48%;
    font-size: 14px;
    background: #FFF;
    border: 1px solid #cecece;
    color:$color-title ;
    height: auto;
    cursor: pointer !important;
    @include transition(0.2s);
    &:hover{
        @include transition(0.2s);
        color:#fff ;
        background: $color-title ;
        border: 1px solid $color-title ;
    }
}
.btn-checkout{
    color:$white ;
    display: inline-block;
    text-align: center;
    padding: 22px 40px;
    width: 100%;
    font-size: 16px;
    border: 1px solid #1f1b1c;
    background: #1f1b1c;
    font-weight: 400;    
    text-transform: uppercase;
    @include transition(0.4s);
    &:hover,&:focus{
        @include transition(0.4s);
        border: 1px solid $color-title;
        background: $color-title;
        color:$white ;
    }
}
.btn-order{
    display: inline-block;
    text-align: center;
    padding: 15px 40px;
    width: 100%;
    font-size: 16px;
    line-height: 36px;
    color: $white !important;
    height: auto !important;
    background: $color-title;
    border: 1px solid  $color-title;
    @include transition(0.4s);
    &:hover{
        @include transition(0.4s);
        border: 1px solid $color-title;
        background: $color-title;
        color:$white ;
    }
}
.btn-login{
    padding: 10px 40px;
    font-size: 14px;
    color: #fff !important;
    text-transform: uppercase;
    letter-spacing: 1px;
}
.btn-mini-search{
    font-size: 17px;
    border: none;
    color: #000000;
    background: none;
    cursor: pointer;
    z-index: 99;
    position: relative;
}
.shop-btn-filter{
    @include rtl-margin-right(30px);

}
.filter-mini{
    cursor: pointer;
    text-align: center;
    background:$theme-color;
    margin-bottom: 15px;
    vertical-align: middle;
    .title-filter{
        padding-right: 10px;
        font-size: 16px;
        text-transform: uppercase;
        color: #fff;
        line-height: 50px;
    }
}

.btn-filter{
    vertical-align: middle;
    color: #fff;
    display: inline-block;
    line-height: 50px;
    font-size: 28px;
    font-weight: 400;
    cursor: pointer;
    &:hover{
        color: #fff;
    }
    i{
        @include rtl-padding-right(5px);
    }
}
.btn-shop{
    background: $color-title;
    color: #fff;
    font-size: 14px;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    @include transition(0.4s);
    padding: 15px 30px;
    &:hover{
        background: #929292;
        @include transition(0.4s);
    }
}
.btn-mini-close,.ion-ios-close-empty{
    background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
    border: medium none;
    color: #1a1a26;
    font-size: 50px;
    line-height: 30px;
    opacity: 1;
    position: absolute;
    right: 0;
    top: 0px;
    transition: all 0.4s ease 0s;
    cursor: pointer;
}
#loadShop{    
    margin: auto;
    text-align: center;
    background:transparent;
    color: $color-title;
    padding:20px 40px 20px 20px;
    font-weight: 400;
    max-width: 220px;
    letter-spacing: 1px;
    font-size: 14px;
    text-transform: uppercase;
    border: 0;
    position: relative;
    cursor: pointer;    
    outline: none;
    overflow: hidden;
    z-index: 1;
    display: block;    
    @include transition(color .4s cubic-bezier(.19,1,.22,1));         
    &:after, &:before{        
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
    }
    &:after{
        content: '';
        border: 1px solid $color-title;
    }
    &:before {
        background-color: $color-title;
        content: '';
        z-index: -1;
        @include transform(translateY(100%));
        @include animation(button-leave .4s cubic-bezier(.19,1,.22,1) forwards);       
    }
    &:hover,&:focus{
        color: #FFF;
        i{
            color: #FFF;
        }
        &:before{
            @include animation-name(button-enter); 
        }
    }
    i{
        margin-left: 15px;
        color: $color-title;
        font-size: 22px;
        line-height: 24px;
        vertical-align: middle;
        right: 40px;
        top: 13px;
        position: absolute;
        padding-top: 2px;
        z-index: 1;
    }
    &.loading{
        position: relative;
        i{
            animation-name: spin;
            animation-duration: 1500ms;
            animation-iteration-count: infinite;
            animation-timing-function: linear;
        }
    }
}

.infload-to-top{
    display: block;
    margin: auto;
    text-align: center;
    background:transparent;
    color: $color-title;
    padding: 20px;
    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: 5px;
    pointer-events: none;
}

.btn-sizeguide{
    font-size: 16px;
    line-height: 22px;
    font-weight: 300;
    display: inline-block;
    padding: 0;
    margin-bottom: 0px;
    letter-spacing: 0;
    color: $color-title;
    box-shadow: 0 -1px 0 #ccc inset;
    &:hover,&:focus,&:active{
        color: $color-title;
        box-shadow: 0 -1px 0 #ccc inset;
    }
    .icon-sizeguide{
        @include rtl-padding-right(5px);
    }
}
.btn-link{
    font-weight: 400;
    position: relative;
    font-size: 14px;
    line-height: 24px;
    display: inline-block;
    color:$color-title;
    padding:10px 30px;
    text-transform: uppercase;
    border: 0;
    cursor: pointer;    
    outline: none;
    overflow: hidden;
    z-index: 1;     
    @include transition(color .4s cubic-bezier(.19,1,.22,1)); 
    &:after, &:before{        
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
    }
    &:after{
        content: '';
        border: 1px solid $color-title;
    }
    &:before {
        background-color: $color-title;
        content: '';
        z-index: -1;
        @include transform(translateY(100%));
        @include animation(button-leave .4s cubic-bezier(.19,1,.22,1) forwards);       
    }
    &:hover,&:focus{
        color: #FFF;
        text-decoration: none;
        i{
            color: #FFF;
        }
        &:before{
            @include animation-name(button-enter); 
        }
    }    
}

.btn-unline{
    font-size: 18px;
    line-height: 28px;
    display: inline-block;
    text-align: center;
    box-shadow: 0 -1px 0 #ccc inset;
    margin: 0 auto 60px;
}

.btn-next{
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 400;
    vertical-align: middle;
    display: inline-block;
    letter-spacing: 1px;
    position: relative;
    padding:20px 0 0;
    i{
        font-size: 30px;
        vertical-align: middle;
        padding-left: 10px;
        @include transition(0.2s);
    }
    &:hover,&:focus{
        color: $color-title;
        i{
            padding-left: 15px;
            @include transition(0.2s);
        }
    }
}