@mixin button-variables-outline($color, $background, $border, $colorhover, $bghover, $borderhover ) {
    color: $color;
    border-style: solid;
    background-color: $background;
    border-color: $border;
    border-radius:0px;
    font-family: $font-family-once;
    font-size: 16px;
    padding: 15px 20px;
    height: auto;
    font-weight: 400;
    cursor: pointer;
    border-width:1px;
    &:hover,
    &:focus,
    &:active,
    &.active {
        color: $colorhover;
        background-color: $bghover;
        border-color: $borderhover;
        box-shadow: none;
    }
    .open & {
    &.dropdown-toggle {
      color: $colorhover;
      background-color: $bghover;
      border-color: $borderhover ;
    }
    }
    &:active,.btn-sizeguide
    &.active {
    background-image: none;
    }
    .open & {
    &.dropdown-toggle {
      background-image: none;
    }
    }
    &.disabled,
    &[disabled],
    fieldset[disabled] & {
    &,
    &:hover,
    &:focus,
    &:active,
    &.active {
      background-color: $background;
          border-color: $border;
    }
    }
    .badge {
    color: $background;
    background-color: $color;
    }
};

@mixin size($width,$height){
    width:$width ;
    height:$height;
};

