[class*="ion-social-"]{
    @include size(30px,30px);
    background:transparent;
    color: $footer-color;
    font-size: 18px;
    display: inline-block;
    line-height: 30px;
    text-align: center;
    @include transition(0.4s);
    border-radius: 100%;
    &:hover{
        color:$color-title;
        @include transition(0.4s);
        outline: none;
    }
}
.widget_apsc_widget{
    .apsc-inner-block{
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
    }
    .apsc-each-profile{
        padding-bottom: 5px;
        border-bottom: 1px solid #eee;
        &:last-child{
            border-bottom: none;
            padding: 0;
        }
    }
    .apsc-icons-wrapper{
        padding-top: 0;
    }

    .social-icon {
        -webkit-flex: 0 1 auto;
        -ms-flex: 0 1 auto;
        flex: 0 1 auto;
        vertical-align: middle;

        .fa{
            font-size: 20px;
            width: 20px;
            height: 50px;
            line-height: 50px;
            text-align: center;
            @include rtl-margin-right(15px);
        }
        .media-name{
            color: #585858;
            font-size: 14px;
            text-transform: uppercase;

        }
    }
    .apsc-count{
        -webkit-flex: 1 1 auto;
        -ms-flex: 1 1 auto;
        flex: 1 1 auto;
        vertical-align: middle;
        height: 50px;
        @include rtl-text-align-right();
        line-height: 50px;
        @include rtl-margin-right(5px);
    }
    .apsc-media-type{
        line-height: 50px;
        text-transform: lowercase;
    }

    .fa-facebook{
        color: #3b5998;
    }

    .fa-google-plus{
        color: #dd4b39;
    }

    .fa-twitter{
        color: #00aced;
    }

    .fa-instagram{
        color: #517fa4;
    }

    .fa-pinterest{
        color: #cb2027;
    }

    .fa-skype{
        color: #0078ca;
    }

    .fa-vimeo{
        color: #aad450;
    }
    .fa-youtube{
        color: #bb0000;
    }

    .fa-dribbble{
        color: #ea4c89;
    }

    .fa-linkedin{
        color: #007bb6;
    }

    .fa-rss{
        color: #FF9900;
    }
}
.social-footer{
    li{
        display: block;
        line-height: 36px;
        padding: 0;
    }
    [class*="ion-social-"]{
        width: 30px;
        text-align: left;
        padding-right: 10px;
        color: $color-title;
        float: left;
        padding-top: 5px;        
    }
}