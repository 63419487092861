//style widget Tabs Product

.product-tabs{
    margin-bottom: 40px;
    .widgettitle{
        display: block;
        text-align: center;
        text-transform: capitalize;
        margin: 0;
        padding-bottom: 30px;
        .nav-tabs{
            display: inline-block;
        }
        a{
            color:$font-color;
        }
    }
    .tabs-title{
        font-weight: 500;
        font-size: 36px;
        color: $color-title;
        margin-top: 0;
        margin-bottom: 20px;
    }
    .widget-content{
        padding-top: 0;
    }
    .tabs-title-product{
        font-size: 16px;
        padding-bottom:0px;
        padding-top: 30px;
        font-weight: 400;
        background: none;
        color: $color-title;
        display: block;
        &:focus,
        &.focus,
        &:active:focus,
        &.focus:active,
        &.active:focus,
        &.active.focus{
            text-decoration:underline;
            background: none;
            color: $color-title;
        }
    }
    ul.nav-product-tabs{
        display: inline-block;
        position: relative;
        border: none;
        li{
            @include rtl-padding-right(30px);
            a:hover{
                background: none;
            }
        }
        li.active > a,
        li.active > a:hover,
        li.active > a:focus{
            text-decoration:underline;
            background: none;
            color: $color-title;
        }
    }

    .product-block{
        margin-bottom:30px;
    }
}
.category-product{
    .widget-content{
        padding-top: 20px;
    }
}
