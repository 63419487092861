//style widget Slider Fixed
.box-intro-images{
    position: relative;
    .intro-images-box{
        position: relative;
    }
    .box-content{
        position: absolute;
        bottom:60px;
        left: 60px;
    }
    .content-box{
        padding-top: 20px;
        font-size: 18px;
        line-height: 32px;
        font-weight: 300;
        color: $color-title;
        max-width: 600px;
    }
    .title-box{
        margin-bottom: 0;
        font-size: 48px;
        line-height: 62px;
        font-weight: 500;
        color: $color-title;
    }
    .title-box a{
        margin-bottom: 0;
        font-size: 48px;
        line-height: 62px;
        font-weight: 500;
        color: $color-title;
    }
    .bg-img-fixed{
        background-attachment: fixed;
        background-clip: padding-box;
        background-position: top center;
        height:100vh;
        top: 0;
        width: 100%;
        background-size: cover;
        background-repeat: no-repeat;
    }

}