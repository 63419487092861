// Small screen  < tablet 991
@media (max-width: $screen-sm-max){
    .btn-close {
        font-size: 28px;
        line-height: normal;
    }
    .cart-wrap{   
        margin-left: 0;
        margin-right: 10px;
        width: auto;
        &:before {
            content: none;
        }
    }
    .na-cart{
        .icon-cart {
            .icon-basket{
                margin-right: 25px;
            }
            .mini-cart-items {
                top: 20px;
                right: 10px;
                width: 18px;
                height: 18px;
            }
        }
    } 
    .page-header {
        padding: 150px 0;
    }

    .error-404 .page-title,
    .error-404 .title-page,
    .no-results .page-title,
    .no-results .title-page{
        font-size: 42px;
        line-height: 80px;
        margin-top: 80px;
    }
    .error-404,.no-results  {
        .page-content{
            padding-top: 10px;
        }
    }
    .canvas-header{
        .btn-close {            
            @include square(60px);
        }
    }
    .mobile-mini{
        float: none;
        margin: 0;
        padding: 0;
        z-index: 1;
        cursor: pointer;
        text-align: right;
        @include justify-content(center);
        @include align-items(center);
        @include flexbox();
        .btn-mobile{
            display: block;            
            text-align: center;
            margin: 0;
            padding: 0;
            @include square(100%);
        }
        i.icon {
            margin-left: 0;            
            margin-top: 2px;
            vertical-align: top;
        }
    }
    .shop-sidebar{
        z-index: 99999 !important;
    }  
    //slider
    #slider-showcase_wrapper,
    #slider-showcase_forcefullwidth,
    .tp-fullwidth-forcer{
        max-height: 660px!important;
    }
    .wpb_text_column{
        &.text_column_faq {
            padding-left: 50px;
            text-align: center;
            padding-right: 50px;            
        }    
    }          
    .wpb-js-composer{
        .vc_tta-accordion{
            &.accordion_faq {                
                padding-top: 50px;
                padding-left: 50px;
                padding-right: 50px;
            }   
            &.vc_tta{
                &.vc_general{
                    .vc_tta-panel-title{
                        > a {
                            padding: 30px 60px;
                        }   
                        &:before {
                            left: 17px;
                        }
                        &:after {
                            left: 24px;
                        }
                    }                     
                }                 
            }            
        }        
    } 
    .vc_custom_heading{
        &.custom_heading_faq {
            font-size: 36px !important;
            line-height: 46px !important;
            padding-top: 38px;
            text-align: center !important;
        }
    }  
    .page-cart-warp {
        padding-top: 20px;
    }  
    .cart-bottom {     
        padding-bottom: 80px;
    }
    .header-simple .searchform-mini{
        padding-right: 20px;
    }
    .woocommerce{
        table.shop_table {
            margin-bottom: 20px;
            &.cart{
                + .return-to-shop{
                    margin-bottom: 30px;
                }
            }
        }
        table{
            &.wishlist_table{
                margin-bottom: 0;
                thead{
                    tr{
                        @include flexbox();
                        @include align-items(center);
                        @include justify-content(flex-start);
                        th{         
                            border: 0;
                            padding-right: 12px;                                    
                            @include flex-grow(1);
                            @include flexbox();                                                         
                            &.download-remaining,
                            &.product-thumbnail,
                            &.product-stock-status,
                            &.product-remove,
                            &.product-add-to-cart,
                            &.woocommerce-orders-table__header-order-date{                      
                                display: none;
                            }   
                            &.product-name{
                                padding-left: 0;
                                @include justify-content(center);   
                            }
                            &.product-price{
                                padding-right: 0;
                                @include justify-content(center);   
                            }                           
                        }
                    }               
                } 
                tbody{
                    tr{
                        @include flexbox();
                        @include align-items(center);
                        @include justify-content(flex-start);
                        td{         
                            @include flex-grow(1);
                            @include flexbox();                 
                            &.download-remaining,                            
                            &.product-stock-status,
                            &.woocommerce-orders-table__cell-order-date{
                                display: none;
                            }
                            &.product-remove{
                                padding-right: 20px;
                            }
                            &.product-name{
                                @include flexbox();
                            }
                            &.product-add-to-cart{                          
                                a {                             
                                    padding: 0;
                                    overflow: hidden;
                                    color: transparent;
                                    background: white;
                                    border-width: 2px;
                                    position: relative;
                                    border-color: $color-border;
                                    @include square(32px);                                                                      
                                    @include border-radius(100px !important);       
                                    &:after{
                                        content: '\f370';
                                        font-family: Ionicons;
                                        color: $gray;
                                        font-size: 16px;
                                        @include center-box(absolute);                                                                                  
                                    }
                                    &:hover{
                                        color: transparent;
                                        background: transparent;
                                        border-color: $color-border;
                                    }                                                       
                                }
                            }   
                            &.product-name,&.product-price{                             
                                max-width: 50%;                                 
                                @include flex-basis(50%);                                                                   
                            }                               
                            &.product-remove,.product-add-to-cart,&.product-name,&.product-price{
                                @include flex-grow(0);
                                @include justify-content(flex-start);   
                            }               
                            &.product-add-to-cart{
                                @include justify-content(flex-end); 
                            }                           
                        }                   
                    }               
                }                   
            }
        }    
    }
    .cart-collaterals{
        .wc-btn-updatecart, 
        .wc-proceed-to-checkout { 
            padding: 0 20px;
            margin-bottom: 0;
        }
    }
    .hidden-filters{
        .paging, 
        .infload-controls {
            margin-right: 0;
        }
    }    
    .cats-block{
        margin-bottom: 0;
    }
    .product-category {     
        margin-bottom: 0;
    }
    .cat-carousel{
        .slick-next {        
            line-height: normal;
            margin: 0;      
            @include flexbox();
            @include justify-content(center);
            @include align-items(center);
            @include vertical-align(absolute);            
            @include square(40px);
        }
    }     
    .box-intro-images{
        .bg-img-fixed{
            background-attachment: inherit;
        }
    } 
    .left-fixed{
        &.view {
            display: none;
        }
    }    
    .nano-slider-category{
        .article-carousel{
            .title-box {
                font-size: 68px;     
            }
        }         
    }    
    .shop-content-top {
        margin-bottom: 15px;
    } 
    .vc_column-inner{
        &.vc_custom_1550306465718{
            padding-left: 80px !important;
            padding-right: 80px !important; 
        }
    }
    .address-contact {
        padding-left: 15px;
        padding-right: 15px;
        padding-bottom: 50px;
        text-align: center;
    }
    .wpcf7-form {
        padding-right: 15px;
        padding-left: 15px;
        .right {
            text-align: center;
        }
    }
    .about-heading {
        max-width: 340px;
        font-size: 20px !important;
        line-height: 36px !important;
    }
    .block-products-widgets{
        .widgetcontent {
            margin-top: 30px;
        }
        .items-duel{
            padding-top: 0;
            .col-item {
                padding-right: 15px;
                padding-left: 15px;
                padding-top: 50px;
            }
        }         
        &.block-duel {
            padding-left: 0;
            padding-right: 0;            
        }
        .title-ground{
            .block-title {
                float: none;
                width: 100%;
                text-align: center;
            }
            .btn-unline {
                float: none;
                margin: 0;
                padding-top: 10px;
                text-align: center;
                margin: 0;
                display: inline-block;
                left: 50%;
                position: relative;
                transform: translateX(-50%);
            }
        } 
    }        
    .product-grid{
        .product-price{
            .price {
                max-width: 100%;
                @include flex-basis(100%);
                @include justify-content(center);
            }
            .shopswatchinput {
                display: none;
            }
        }         
    }   
    .product-name {
        text-align: center;
        line-height: 32px;        
    }
    .shop-content {
        padding-top: 0;
        &.layout-shop-duel {
            padding-top: 0;
        }
    }     
    .caption-product {
        text-align: center;        
    }
    .box-intro-images{
        .box-content {     
            bottom: 0;
            left: 0;
            padding: 30px;
            width: 100%;
        }
    }     
    .post-related{       
        .item-col{
            &:last-child{
                .post-grid{
                    .article-content {
                        padding-bottom: 0;
                    }
                }                 
            }
        }
        article{
            &.post-item {
                text-align: center;
                .entry-header {
                    text-align: center;
                }
                .entry-header-title{
                    .entry-title {
                        padding-left: 100px;
                        padding-right: 100px;                        
                    }
                }                 
            }            
        }
        .post-grid{
            .post-image{
                img{
                    max-height: none;
                }
            }               
        }         
    }     
    .menu-item{
        > a{
            border-top: 1px dotted rgba(255,255,255,0.2);
        }
    } 
    // single product
    .product-layout-sticky{
        .sticky-product-detail {
            padding: 80px;
        }
        .entry-summary,
        .product-data-tabs {
            max-width: 100%;
        }
        .product-detail-wrap{
            .cart {
                padding-top: 10px;
                max-width: none;
            }
        } 
    }    
    .product-layout-vertical{
        .rows{
            margin-left: -15px;
            margin-right: -15px;
        }
        .product-detail-wrap{
            .product-data-tabs {
                max-width: 100%;
            }
        }         
        .product-detail-before {
            padding-left: 15px;
            position: static;
        }
        .product-detail-summary {
            padding: 80px;
            .entry-summary {
                padding: 0;
            }     
        }
    }    
    .detail-content {
        padding-bottom: 60px;
    }
    .widget-related{
        .widgettitle {
            margin-bottom: 40px;
        }
        .product-block {
            margin-bottom: 0;
        }
    }         
    //short codes
    .nano-infobanner{
        &.left-under .image-content-hover {
            bottom: 10px;
            @include rtl-left(10px);
        }
        .title-banner{
            font-size: 18px;
            margin-bottom:5px;
        }
        .btn-banner{
            @include rtl-padding-left(20px);
            letter-spacing: 0;
            font-size: 13px;
        }
        .btn-banner:before{
            width: 15px;
            bottom: 7px;
        }
    }
    .na-infoCategory {
        .entry-content{
            margin-top:0px;
            @include rtl-padding-left(30px);
            .title-box{
                font-size: 36px;
                line-height: 42px;
            }
            .content-box{
                margin-bottom: 30px;
            }
        }
        .entry-content.style-2{
            .title-box{
                font-size: 36px;
                line-height: 42px;
            }
            .content-box{
                margin-top: 0;
                @include rtl-padding-left(30px);
            }
        }
    }
    .widget.widget_instagram{
        .btn-follow {        
            margin-bottom: 30px;     
        }
    }     
    .nano-slider{
        .article-carousel{
            margin: 0 -20px;
            .col-item{                
                padding-left: 7.5px;
                padding-right: 7.5px;
            }
        }           
    }   
    .product-block{
        margin-bottom: 30px;
    }      
    .nano-textBlock{
        padding: 0 30px;
        .image-box{
            padding: 0;
        }
        .content-box{
            padding:30px 0px 0 0;
        }
        .title-text{
            margin-top: 15px;
            font-size: 36px;
            line-height: 50px;
        }
    }
    .scattered-box{
        &.ver-left{
            padding-left: 70px;
        }
        &.squ-right{
            .entry-image{
                padding-left: 100px;
                padding-top: 40px;
            }
            .entry-content{
                left: 50px;
                top:50%;
            }
        }
        &.hoz-center{
            padding-left: 50px;
            padding-right: 150px;
        }
        &.ver-right{
            .entry-image{
                padding-left: 70px;
            }
        }
        &.squ-left{
            .entry-image{
                padding-left: 50px;
                padding-right: 50px;
            }
            .entry-content{
                top:50%;
            }
        }
        .title-box{
            margin-top: 15px;
            font-size: 26px;
            line-height: 36px;
        }
        .des-box{
            padding-bottom: 20px;
        }
    }
    .na-imageGallery{
        .entry-content-inner{
            padding-right: 30px;
            padding-left: 30px;
        }
        .icon{
            max-width: 50px;
        }
        .title-block{
            font-size: 26px;
            line-height: 36px;
            margin-top: 15px;
        }
        .des{
            padding-bottom: 10px;
        }
    }    
    .category-tabs{
        .cat-title.left-fixed{
            display: none;
        }
    }
    .items-scattered{
        .sm-center{
            padding:0 50px;
            .product-block{
                padding-top: 60px;
                margin-bottom: 50px;
            }
        }
        .lg-right{
            padding-right: 15px;
        }
        .lg-left{
            padding-right: 15px;
        }
        .sm-right{
            padding-left: 100px;
            padding-top: 0;
            .product-block{
                padding-top: 60px;
                margin-bottom: 50px;
            }
        }
        .product-scattered,.product-scattered-sm{
            margin-bottom: 70px;
        }
    }
    //fillter
    #filter-full{
        padding: 15px 30px;
        .content-inner{
            margin: 0;
        }
        .widget,.widget-area{
            width: 100%;
            float: none;
            display: block;
            position: relative;
            margin: 0;
            padding: 0;
            .widget{
                margin: 0;
                padding: 0;
            }
            .widgettitle, > h2, > h3{
                padding:20px 0 15px;
                font-size: 14px;
                border-bottom: 1px solid #ddd;
                text-transform: uppercase;
                color: $color-title;
                font-weight: 400;
                z-index: 1;
                margin: 0;
                cursor: pointer;
                position: relative;
                display: block;

                &:after{
                    background: transparent;
                    color: #777777;
                    content: "\f123";
                    font-family: "ionicons";
                    height: 100%;
                    pointer-events: none;
                    position: absolute;
                    right: 5px;
                    text-align: center;
                    top:18px;
                    width: 10px;
                    font-size: 16px;
                    border: none;
                }
                + *{
                    display: none;
                    border-bottom: 1px solid #ddd;
                    padding: 20px 0;
                }
            }
            &:last-child{
                .widgettitle, > h2, > h3 {
                    + *{
                        border-top: none;
                    }
                }
            }
            &:hover {
                .widgettitle, > h2, > h3 {
                    & + *{
                        display: block;
                    }
                }
            }
            a:hover{
                color: $color-title;
            }
        }
        .content-inner > .widget-area:last-child{
            h3 {
                border-bottom: none;
            }
        }
    }
    #filter-down .widget, #filter-down .widget-area{
        padding-left: 10px;
        padding-right: 10px;
    }
    //slider
    #slider-showcase_wrapper,#slider-showcase_forcefullwidth,.tp-fullwidth-forcer{
        max-height: 500px!important;
    }
    .nano-slider .article-carousel-center .slick-prev{
        @include rtl-left(0);
    }
    .nano-slider .article-carousel-center .slick-next{
        @include rtl-right(0);
    }
    .tp-bullets.sok-2{
        display: none !important;
    }
    .sok-bullet .bullet-number-all:after{
        content: none;
    }
    .vc_row-fluid[data-vc-stretch-content="true"]{
        padding-left: 15px;
        padding-right: 15px;
    }
    .block{
        margin: 30px 0;
    }
    .product-tabs{
        .tabs-title-product{
            padding-top: 0;
        }
    }     
    .wpb_images_carousel{
        margin-top: 40px;
        .vc_carousel-slideline{
            .vc_item{
                .vc_inner{
                    padding-left: 40px;
                    padding-right: 40px;   
                }                
            }
        }
    }    
    .na-service{
        .items-service {
            padding-left: 15px;
            padding-right: 15px;
            @include flex-wrap(wrap);
        }
        .service-box {
            padding: 0;
            margin-bottom: 15px;
            margin-top: 15px;
            max-width: 50%;
            @include flex-basis(50%);
        }
        .service-box-inner{
            padding: 0;
        }
    }       
    .nano-infoTeams{
        margin-bottom: 0;
        .block-content {
            padding-top: 15px;           
            @include flex-wrap(wrap);              
            .box-team-list {              
                max-width: 50%;  
                margin-bottom: 40px;
                @include flex-basis(50%);   
                &:nth-last-child(1) {
                    margin-bottom: 0;
                }
                &:nth-last-child(2) {
                    margin-bottom: 0;
                }
                .box-content {
                    padding-left: 20px;
                    padding-right: 20px;                    
                    position: absolute;
                    left: 0;
                    right: 0;    
                    bottom: 0;
                    width: auto;
                    min-width: auto;    
                    margin: 0 25px;
                }
            }
        }
    }     
    .cat-3{
        .grid-sizer,
        .category-thumb{
            width: 33.33%;
        }
    }
    .woocommerce-ordering{
        float: right !important;
    }
    //product
    .product-list {
        .caption-product{
            padding: 20px 1px;
        }
        .description-product{
            padding: 15px 0px 15px;
        }
        .caption-product .button-groups{
            padding-top: 0;
            @include rtl-padding-left(10px !important) ;
        }
    }
    .product-gallery {
        .caption-product-inner{
            padding: 30px;
        }
        .description-product{
            display: none;
        }
        .caption{
            padding-top: 30px;
        }
        .ground-addcart{
            padding-top: 20px;
        }
    }
    .padding-big{
        padding: 200px 0;
    }
    .nano-infoTeams {
        .block-content{
            text-align: center;
        }
        .box-team-list{
            float: none;
            padding-bottom: 30px;
            display: inline-block;
        }
    }
    //topbar
    .top-navbar{
        display: none;
    }
    .search-transition-wrap{
        .search-transition-inner{
            padding:0;
        }
    }     
    .slicknav_menu{
        .slicknav_icon-bar{
            box-shadow: none;
        }    
    }     
    .single-post{
        .social-modal {
            padding: 0;
            width: 100%;
            @include align(absolute);
        }
    }      
    blockquote, 
    .wp-block-quote:not(.is-large):not(.is-style-large) {
        padding: 100px 50px 20px 50px;
    }
    .meta-social{
        .modal-body {             
            width: 100%;
            height: 100%;
            padding: 0 15px;            
            .entry-header-title{
                .entry-title {
                    padding-left: 0;
                    padding-right: 0;
                    max-width: none;
                    min-width: none;
                }    
            }             
        }
        #btnShare{
            padding-right: 0 !important;
        }
    }
    .modal-header {
        padding: 0;
        min-height: auto;
        &.btn-close-popup{            
            .close{
                top: 80px;
                right: 60px;
                line-height: normal;
                position: absolute;                
                @include square(50px);
                @include flexbox();
                @include justify-content(center);
                @include align-items(center);   
                @include border-radius(0);
                i {
                    font-size: 48px;                 
                }
            }            
        }
    }
    //content
    .wrap-content{
        margin-top: 50px;
        margin-bottom: 50px;
        &.wrap-single{
            margin-bottom: 90px;
            .entry-header-title{
                .entry-title{
                    padding-left: 15px;
                    padding-right: 15px;
                }
            }
        }
    }
    .container-fluid {
        padding-left: 30px;
        padding-right: 30px;
    }
    .container-fluid .product-nav{
        @include rtl-right(15px);
    }
    //detail
    .product-detail-wrap {
        .product_vertical{
            .na-product-image{
                padding: 0px;
            }
        }         
    }
    .product-layout-grid{
        .product_grid{
            padding: 0 7.5px;
        }
        .entry-summary {            
            max-width: none;
        }
        .grid-thumbnail{
            width: 50%;
            @include rtl-float-left();            
        }
        .summary-before {
            padding-left: 0;
        }
        .product-detail-summary {
            padding: 80px;
        }
        .product-data-tabs {         
            max-width: none;
        }
    }
    .product-layout-carousel-left{
        .entry-summary-inner{
            padding-bottom: 60px;
            padding-left: 60px;
        }
    }  
    .meta-sizeguide{
        .modal{
            padding-right: 0 !important;
        }
        .modal-body{
            .modal-inner {        
                margin-top: 50%;
            }
        }         
    }    
    //blog
    .archive-blog{
        .entry-content{
            display: none;
        }
        .item-post {
            margin-bottom: 30px;
        }
        &.layout-grid{
            margin-left: -15px;
            margin-right: -15px;     
            .item-post {
                padding-right: 15px;
                padding-left: 15px;
            }
        }    
        &.layout-tran{
            margin-left: -15px;
            margin-right: -15px;             
            .item-post {
                padding-right: 15px;
                padding-left: 15px;
                margin-bottom: 50px;
            }
        }
    }  
    .padding-content-right {
        padding-right: 15px;
    }      
    .article-content{
        &.full-width{
            .entry-content{
                display: block;
            }   
        }         
    }     
    .bg-cover.bg-lg {
        min-height: 400px;
    }    
    .sm-copyright-text-center{
        text-align: center;
    }
    #na-footer{        
        .footer-top{
            .widgettitle {
                padding-top: 0;
            }
        }         
        .footer-center{
            padding-top: 80px;
            padding-bottom: 50px;
        }
        &.footer-2{
            .container-inner{
                padding: 50px 0 30px;
            }   
        }         
    }    
    #sticky-wrapper{
        display: none;
    }
    .header-content-right{
        position: static;        
    }
    .user-mini, 
    .btn-intro, 
    .btn-mini-search, 
    .mini-cart {
        padding: 0;
        margin: 0;
        float: none;
    }
    .header-center{
        .searchform-mini {
            padding: 0;
            margin: 0;
            left: 30px;
            top: 50%;
            position: absolute;
            text-align: left;
            @include square(40px);
            @include flexbox();
            @include align-items(center);
            @include justify-content(flex-start);
            @include transform(translateY(-50%));
        }
        .user-mini{
            padding: 0;
            margin: 0;
            left: 80px;
            top: 50%;
            position: absolute;
            text-align: left;
            @include square(40px);
            @include flexbox();
            @include align-items(center);
            @include justify-content(flex-start);
            @include transform(translateY(-50%));

        }
        .header-content-right{
            max-width: 100%;
            @include flex-basis(100%);
        }
    }
    .widget.recent_blog .post-image{
        width: auto;
    }
    .slicknav_btn{
        top:50%;
        margin-top:-20px !important;
    }
    .header-left .site-logo{
        display: inline-block;
    }    
    .header-left .header-content{
        display: none;
    }
    .header-left .header-middle{
        display: none;
    }
    .sok-header-content{
        padding:30px 0;
    }    
    .site-logo{
        max-height: 50px;
    }
    #logo img {
        max-height: 50px;
    }
    #archive-sidebar{
        display: none;
    }
    //category
    .switch-layout{
        display: none;
    }
    .shop-btn-filter{
        width: 120px;
    }
    //menu
    .sok_icon{
        display: none;
    }
    .header-content-menu{
        display: none;
    }
    .sok-header-content{
        width: 100%;
        padding: 0;
        margin: 0;        
        @include flex-direction(wrap);        
        @include align-items(center);        
    }
    .header-center .header-content-logo{
        padding: 0;
        margin: 0;
        text-align: center;
        display: block;
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;        
        margin: auto;        
        @include transform(translateY(-50%));
    }
    .slicknav_btn{
        bottom: 0;
        vertical-align: middle;
        margin: auto;
        padding-left: 30px;        
    }
    .slicknav_menu .slicknav_icon-bar{
        background-color:#040203;
        .slicknav_no-text{
            padding-top: 5px;
        }
    }
    .slicknav_nav{
        top:100% !important;
        height: 100vh;
        overflow-y: auto;
        padding-bottom:200px;
        -webkit-overflow-scrolling: touch;
        z-index: 99999;
        ul{
            text-align: left;
            padding: 0 0 0 20px;
            margin: 0;
            .menu{
                padding: 0;
                li{
                    padding: 0;
                }
            }
        }
        img{
            display: none !important;
        }
        .widgettitle{
            padding-top: 20px;
            font-size: 14px;
            color: white;
        }
    }
    .wpb_button, .wpb_content_element, ul.wpb_thumbnails-fluid > li{
        margin-bottom: 0 !important;
    }
    //detail
    .widget-related .related-wrapper{
        margin-bottom: 0;
    }
    .product-detail-wrap{
        margin-top: 0;
        margin-bottom:0px;
        >.container{
            padding: 0;
            margin: 0;
            width: 100%;
        }
        .gallery-main{
            img{
                width: 100%;
            }
        }
    }
    .summary-before{
        padding-left: 0;
        padding-right: 0;
    }
    .product-thumbnails{
        display: none;
    }
    .entry-summary{
        padding:50px 50px 30px;
    }
    .woocommerce-tabs{
        padding-left: 50px;
        padding-right: 50px;
    }
    .woocommerce-tabs li.resp-tab-item{
        font-size: 14px;
    }
    //topbar
    #sok-top-navbar ul > li{
        padding: 7px 15px;
    }
    //quickview
    .single-product{
        .mfp-wrap,.mfp-bg{
            display: none !important;
        }
    }
    //footer
    #na-footer{
        .widgettitle{
            padding-top: 50px;
        }
        .container-fluid .container-inner, .container-full .container-inner{
            padding: 0;
        }
    }
    //home1
    .widget-introduce{
        .introduce-text{
            width:100%;
        }
        .introduce-image{
            width: 0;
            display: none;
        }
    }
    div.widget_deals{
        padding-bottom: 150px;
        padding-top: 50px;
        .countdown-times > * > b{
            font-size: 20px;
            min-width: 60px;
            padding: 10px;
            margin-bottom:10px;
        }
    }
    .newsletters .group-input-newsletter{
        width: 500px;
    }
    .single-post{
        .post-related{
            margin-bottom: 30px;
        }
    }
    #comments .heading,
    #review_form .heading,
    #comments .comment-respond .heading,
    #review_form .comment-respond .heading{
        font-size: 28px;
        line-height: 38px;
    }
    #comments, #review_form{
        margin-top:30px;
    }
    #comments ul.children, #review_form ul.children{
        padding-left: 30px;
    }
    #comments , #review_form{
        ul.children ul.children ul.children ul.children {
            padding-left: 0;
        }
    }
    #comments .comment-reply-title,
    #review_form .comment-reply-title,
    #comments .heading,
    #review_form .heading{
        margin-top: 50px;
    }

    .block-asymmetric-products{
        margin: 0;
        .asymmetric-title{
            padding: 50px;
            .block-title{
                margin-top: 0;
            }
        }
        .asymmetric-product{
            padding: 0;
        }
        .products-blocks{
            margin:0px;
            li{
                padding: 0 30px;
                &:first-child {
                    .product-block{
                        padding-left: 0px;
                    }
                }
                &:last-child {
                    .product-block{
                        padding-right: 0px;
                    }
                }
            }
        }
        &.style-2{
            .row{                
                @include flexbox();
                @include flex-direction(column);                
            }
            .col-md-8{
                @include order(2);
            }
            .col-md-4{
                @include order(1);
            }
        }
    }
    .nano-intro{
        .content-box {
            float: none;
            text-align: center;
            width: 100%;
            height: 340px;
            min-height: 340px;            
            @include rtl-padding(0, 60px, 0px, 60px); 
            @include align-items(flex-start);
        }
        .content {
            max-width: none;
        }
        .image-box {
            float: none;
            padding: 0;
            width: 100%;            
        }
    }
    .products-block { 
        margin-left: -15px;
        margin-right: -15px; 
    }
    .product-grid{
        .ground-addcart,
        .button-groups {
            display: none;
        }
    }    
    .product-col,
    .col-item {
        padding-left: 15px;
        padding-right: 15px;    
    }
    #na-footer{
        .footer-center {
            padding-top: 20px;
            padding-bottom: 60px;
        }    
    } 
    .newsletters{
        .group-input-newsletter {
            width: 100%;
        }
    }     
    .page-content {
        padding-top: 30px;
        padding-bottom: 80px;     
    }
    .product-cat-duel{
        &.col2{            
            .col-item{
                padding-top: 0;
                &.duel-top {
                    padding-top: 0;
                }
                &.duel-right {
                    padding-left: 15px;
                }
                &.duel-left {
                    padding-right: 15px;
                }
                &:nth-child(2) {
                    padding-top: 0;
                }
            }
            .product-block {
                margin-bottom: 20px;
            }            
        }  
        &.products-block {         
            margin-bottom: 0;
        }       
    }    
    .nano-showcase{
        .sub_box {
            top: 18%;
        }
        .cat-menu{
            h2 {
                line-height: 150px;                
            }
        }         
        .content-left{
            .title-box{
                a {
                    font-size: 30px;
                    line-height: 40px;
                }
            }             
            .gallery-top {        
                height: auto;
                display: block;
                width: 100%;                                
                @include vertical-align(absolute);
            }
        }         
        .content-right {
            width: 44%;
            padding-left: 80px;
            padding-right: 30px;
        }
    }
    #comments, #review_form,#comments .comment-reply-title, #review_form .comment-reply-title{
        margin-top: 50px;
    }
    #archive-sidebar.shop-sidebars{
        display: block;
        background: #fff none repeat scroll 0 0;
        border-radius: 0;
        height: 100%;
        opacity: 1;
        position: fixed;
        @include rtl-left(0);
        top: 0;
        transform: translateX(-600px);
        transition: all 0.5s ease 0s;
        visibility: visible;
        width: 360px;
        padding:60px 30px;
        z-index: 999;
        overflow-y: auto;
        .widget{
            margin: 40px 0px 30px;
            padding-bottom: 30px;
            border-bottom: 1px solid #e9e9e9;
            .widgettitle , > h3{
                color: #282828;
                display: block;
                font-size: 16px;
                font-weight: 500;
                padding: 0;
                margin: 0;
                @include rtl-text-align-left();
                text-transform: capitalize;
                &:after{
                    content:none;
                }
            }
            > * + *{
                padding-top: 20px;
            }
        }
        .widgettitle + ul{
            padding-top: 15px;
        }
    }
    .canvas-filter{
        #archive-sidebar.shop-sidebars{
            transform: translateX(0px);
            -webkit-transform: translateX(0px);
            -ms-transform: translateX(0px);
            -o-transform: translateX(0px);
            z-index: 99999;
        }
    }
}

@media (min-width: 768px) and (max-width: 1024px) {
    .searchform-mini,.cart-wrap{
        margin-right:10px;
    }
    .nano-showcase{
        .content-left{
            .title-box {
                left: 40%;
                max-width: 48%;
                a{
                    font-size: 26px;
                    line-height: 36px;
                }
            }
            .col-item{
                .entry-image {
                    padding-left: 36%;
                    padding-right: 7%;                    
                }  
            } 
            .content-box {
                padding-left: 36%;
            }            
        }         
    }           
    .block-products-widgets{
        &.block-duel{
            .product-cat-duel{
                &.products-block {
                    margin-bottom: 70px;
                }   
            }    
        }         
    }
    li[data-index="rs-1"]{        
        .tp-bgimg{
            background-position: 45% center !important;
        }           
    }  
    li[data-index="rs-2"]{        
        .tp-bgimg{
            background-position: 60% center !important;
        }   
    }        
    .entry-title {
        font-size: 46px;
        line-height: 60px;
    }
    .cats-block{
        .product-category{
            .woocommerce-loop-category__title {
                bottom: 0;
                left: 0;
                right: 0;
                margin: 0;
                padding: 15px 0;
            }
        }         
    }     
}

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    #na-footer{
        .container{
            width: 970px;
        }
        .container-inner{
            padding-left: 0px;
            padding-right: 0px;
        }
    }
    li[data-index="rs-1"]{        
        .tp-bgimg{
            background-position: center center !important;
        }   
    }  
    li[data-index="rs-2"]{        
        .tp-bgimg{
            background-position: center center !important;
        }   
    }  
    .vc_row{
        &.vc_custom_1550458406586{
            padding-left: 0 !important;
            padding-right: 0 !important;
        }     
    }  
    .wpb_images_carousel{
        .vc_carousel-slideline{
            .vc_item{
                .vc_inner {
                    padding-left: 50px;
                    padding-right: 50px;
                }
            }             
        }         
    }     
    .caption-product {    
        min-height: 120px;
    }      
    .cats-block{
        .product-category{
            .woocommerce-loop-category__title {
                margin: 0 15px 15px 15px;      
            }
        }         
    } 
    .nano-showcase{
        .sub_box {
            top: 10%;
        }
        .cat-menu{
            h2 {
                line-height: 80px;
            }
        }  
        .content-left{
            .title-box {
                left: 36%;
                max-width: 40%;
                a {
                    font-size: 26px;
                    line-height: 36px;
                }
            }
            .col-item{
                .entry-image {
                    padding-left: 30%;
                    padding-right: 20%;
                }                
            }  
            .content-box {
                padding-left: 30%;
            }           
        }         
        .content-right {
            width: 50%;
            padding-left: 50px;
            padding-right: 100px;
        }       
    }        
    .na-service{
        .service-box-inner {
            padding-top: 15px;
            padding-bottom: 15px;
        }
    }    
}