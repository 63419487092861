//style widget Video
.nano-video {
    .image-embed{
        position: relative;
    }
    .btn-play{
        color: $color-title;
        font-size: 36px;
        width: 75px;
        height: 75px;
        line-height: 75px;
        display: inline-block;
        margin-top: -50px;
        background: #fff;
        border-radius: 100%;
        text-align: center;
        cursor: pointer;
        @include rtl-padding-left(5px);
        &:hover{
            background: $color-meta;
            color: #FFF;
        }
    }
    .embed-responsive.hidden{
        position: absolute;
        z-index: -1;
    }
    .video-embed{
        position: relative;
    }
    .video-content{
        color: #fff;
        position: absolute;
        display: inline-block;
        text-align: center;
        margin: auto;
        top: 50%;
        left: 0;
        right: 0;
    }
    .title-video{
        color: #fff;
    }
}

