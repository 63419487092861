//Hearder
.sok-header{
    position: relative;
}
.site-header.header-fixed{
    &.fixed{
        background: #FFF;
        position: fixed;
        top:0;
        box-shadow: 0px 1px 5px 0 rgba(0, 0, 0, 0.05);
        width: 100%;
    }
}
.user-mini,.btn-intro,.btn-mini-search,.mini-cart{
    @include rtl-float-right();
    line-height: 100px;
    cursor: pointer;
    font-size: 22px;
    @include  rtl-padding-left(30px);
    border: none;
    color: $color-title;
    background: none;
    z-index: 99;
    position: relative;
    vertical-align: middle;
    &:hover{
        color: #888890;
    }
}
.cart-wrap{
    @include  rtl-margin-left(30px);
    @include  rtl-margin-right(30px);
    position: relative;
    &:before{
       content: "";
        background: #c6cac4;
        position: absolute;
        left: 0;
        top:50%;
        margin-top: -12px;
        width:1px;
        height: 22px;
    }
}


//HEADER Simple---------------------------------------------------------------------------------------------------------
.sok-header-content{
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}

.header-content-menu {
    position: static;
    vertical-align: middle;
    padding: 0;
}

.header-content-right{
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}

.header-full{
    background: #FFF;
}
//HEADER Center---------------------------------------------------------------------------------------------------------
.header-center{
    background: #FFF;
    &.header-transparent{
        background: transparent;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: 9998;
        @include transition(0.6s);
    }
    #sok-header{
        position: relative;
        text-align: center;
    }
    .header-content-logo{
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
        vertical-align: middle;
        padding: 0;
    }
    .header-content-menu{
        -webkit-flex-basis: 40%;
        -moz-flex-basis: 40%;
        -ms-flex-preferred-size: 40%;
        flex-basis:40%;
        padding-left: 0;
    }
    .header-content-right{
        -webkit-flex-basis: 40%;
        -moz-flex-basis: 40%;
        -ms-flex-preferred-size: 40%;
        flex-basis: 40%;
        -webkit-box-pack: end;
        -ms-flex-pack: end;
        -webkit-justify-content: flex-end;
        -moz-justify-content: flex-end;
        justify-content: flex-end;
    }
    #na-menu-primary ul.mega-menu > li > a{
        line-height: 100px;
    }
    .nav-menu{
        -webkit-order: 0;
        -ms-flex-order: 0;
        order: 0;
        -webkit-flex: 1 1 auto;
        -ms-flex: 1 1 auto;
        flex: 1 1 auto;
        -webkit-align-self: auto;
        -ms-flex-item-align: auto;
        align-self: auto;
        max-height: 90px;
        @include transition(0.4s);
    }
    .searchform-mini{
        padding: 0;
    }
}
//HEADER Left---------------------------------------------------------------------------------------------------------
.header-left{
    &.site-header{
        //box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
    }
    #sok-header{
        @include rtl-text-align-left();
    }
    .header-content-logo{
        padding: 20px 0;
    }
    .header-content{
        border-top: 1px solid rgba(0, 0, 0, 0.1);
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: row;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-flex-wrap: nowrap;
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
        -webkit-justify-content: flex-start;
        -ms-flex-pack: start;
        justify-content: flex-start;
    }

    .sok-header-content{
        -webkit-justify-content: flex-start;
        -ms-flex-pack: start;
        justify-content: flex-start;
    }
    .header-content-right{
        -webkit-justify-content: flex-end;
        -ms-flex-pack: end;
        justify-content: flex-end;
        -webkit-flex: 1 1 auto;
        -ms-flex: 1 1 auto;
        flex: 1 1 auto;
    }
    #na-menu-primary ul.mega-menu > li > a{
        line-height: 70px;
    }
    #na-menu-primary ul.mega-menu > li:first-child > a{
        @include rtl-padding(0,20px,0,0);
    }

    .header-content-logo{
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: row;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-flex-wrap: nowrap;
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
        -webkit-justify-content: flex-start;
        -ms-flex-pack: start;
        justify-content: flex-start;
    }
    .header-middle{
        -webkit-justify-content: flex-end;
        -ms-flex-pack: end;
        justify-content: flex-end;
        -webkit-flex: 1 1 auto;
        -ms-flex: 1 1 auto;
        flex: 1 1 auto;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
    }
    .site-logo{
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-justify-content: flex-start;
        -ms-flex-pack: start;
        justify-content: flex-start;
    }
}
//HEADER drawer---------------------------------------------------------------------------------------------------------
.header-drawer{
    background: $menu-bg;
    #sok-top-navbar{
        background:transparent ;
    }
    #sok-header{
        background: transparent;

    }
    .na-cart .mini-cart {
        @include rtl-padding-left(20px);
        @include rtl-padding-right(30px);
        padding-top: 10px;
    }
    .search-menu{
        background: $search-menu ;
        max-width: 700px;
        min-width: 600px;
        border-radius: 3px !important;
        vertical-align: middle;
        display: inline-block;
        .btn-primary{
            background: transparent;
            color: $search-color;
            cursor: text;
            &:hover{
                color: #e0e0e0;
            }
        }
        .form-control{
            background:$search-menu ;
            border-radius: 3px !important;
            border-color: $search-menu ;
            padding: 7px 20px;
            height: 44px;
            color:$search-color;
            &:focus{
                border-color: $search-menu ;
                &::-moz-placeholder{
                    opacity: 0;
                }
            }
            &::-moz-placeholder{
                color: $search-color
            }
        }
    }
    .searchform, .woocommerce-product-search{
        padding: 0;

    }
    .header-content-menu{
        text-align: center;
    }
}

//Menu Drawer  ---------------------------------------------------------------------------------------------------------
.menu-drawer{
    position: fixed;
    z-index: 9998;
    background: #FFF;
    width: 250px;
    height: 100%;
    @include rtl-left(0);    
    @include rtl-border-right(1px solid #EFEFEF);    
    >*{
        padding-top: 30px;
    }
    @include transition(0.2s);
    @include transform(translateX(-250px));
    &.show{
        @include transform(translateX(0px));         
    }
    #na-menu-primary{
        ul.mega-menu > li{
            float: none;
            &:hover{
                background: #E5E5E5;
            }
        }
        ul.mega-menu > li > a{
            display: block;
            line-height: 50px;
            padding: 0 30px;
            text-transform: capitalize;
            color: #757575;
            &:hover{
                color: $theme-color;
            }
        }
        ul > li[class*="-has-children"] > a::before{
            @include rtl-right(20px);
            color:#757575 ;
        }
        //lv2
        ul.mega-menu > li ul{
            top:100%;
            @include rtl-left(100%);
        }
        ul.mega-menu > li:hover ul{
            top:0;
        }
        ul.mega-menu li.mega-menu-megamenu{
            position:relative;
        }
        ul.mega-menu {
            > li.mega-menu-flyout > ul{
                min-width: 300px;
            }
            > li > ul{
                min-width: 900px;
                max-width: 1170px;
            }
        }
        //lv3
        ul.mega-menu li ul  ul{
            margin: 0;
            padding: 0;
            display: block;
            @include rtl-left(0);
            position: relative;
        }
    }
}

//Login ----------------------------------------------------------------------------------------------------------------
.sok-header-content .user-login {
    cursor: pointer;
    position: relative;
    .author-img{
        padding: 0;
    }
    &:hover {
        #menu-topbar-menu{
            top: 45px;
            opacity: 1;
            visibility: visible;
            @include transition(0.4s);
        }
    }
    #menu-topbar-menu{
        background: #FFFFFF none repeat scroll 0 0;
        box-shadow: 1px 5px 10px 0 rgba(0, 0, 0, 0.15);
        min-width: 125px;
        padding: 10px;
        position: absolute;
        @include rtl-right(0px);
        top: 60px;
        @include transition(0.4s);
        opacity: 0;
        visibility: hidden;
        li{
            float: none;
            font-size: 12px;
            >a{
                padding: 5px;
                &:hover,&:focus{
                    background: none;
                }
            }
        }
    }
}

//Menu Vertical  ---------------------------------------------------------------------------------------------------------
.header-vertical{
    .header-content-menu{
        padding: 23px 30px;
        text-align: center;
        border-bottom:1px solid rgba(225, 225, 225, 0.5) ;
    }
    background: $menu-bg;
    #sok-top-navbar{
        background:transparent ;
    }
    #sok-header{
        background: transparent;

    }
    .na-cart .mini-cart {
        @include rtl-padding-left(20px);
        @include rtl-padding-right(30px);
        padding-top: 10px;
    }
    .search-menu{
        background: $search-menu;
        max-width: 700px;
        min-width: 600px;
        border-radius: 3px !important;
        vertical-align: middle;
        display: inline-block;
        .btn-primary{
            background: transparent;
            color: $search-color;
            cursor: text;
            &:hover{
                color: $search-color;
            }
        }
        .form-control{
            background:  $search-menu;
            border-radius: 3px !important;
            border-color: $search-menu;
            padding: 7px 20px;
            height: 44px;
            color:$search-color;
            &:focus{
                border-color: $search-menu;
                &::-moz-placeholder{
                    opacity: 0;
                }
            }
            &::-moz-placeholder{
                color: $search-color;
            }
        }
    }
    .searchform, .woocommerce-product-search{
        padding: 0;

    }
}
.layout-header-vertical {
        .site-content >.container ,#na-footer >*{
            transform: translateX(120px);
            -webkit-transform: translateX(120px);
            -ms-transform: translateX(120px);
            -o-transform: translateX(120px);
        }
    }
.menu-vertical{
    position: fixed;
    z-index: 9998;
    background: $menu-bg;
    @include rtl-left(0);
    width: 250px;
    height: 100%;
    @include rtl-border-right( 1px solid #dedede) ;
    >*{
        padding-top: 10px;
    }
    .header-content-logo{
        @include rtl-padding-left(30px);
        padding-bottom: 10px;
        border-bottom:1px solid rgba(225, 225, 225, 0.5) ;
    }

    #na-menu-primary{
        ul.mega-menu > li{
            float: none;
            &:hover{
                background: #E5E5E5;
            }
        }
        ul.mega-menu > li > a{
            display: block;
            line-height: 50px;
            padding: 0 30px;
            text-transform: capitalize;
            color:$menu-color;
            &:hover{
                color: $theme-color;
            }
        }
        ul > li[class*="-has-children"] > a::before{
            @include rtl-right(20px);
            color:#757575 ;
        }
        //lv2
        ul.mega-menu > li ul{
            top:100%;
            @include rtl-left(100%);
        }
        ul.mega-menu > li:hover ul{
            top:0;
        }
        ul.mega-menu li.mega-menu-megamenu{
            position:relative;
        }
        ul.mega-menu {
            > li.mega-menu-flyout > ul{
                min-width: 300px;
            }
            > li > ul{
                min-width: 900px;
                max-width: 1170px;
            }
        }
        //lv3
        ul.mega-menu li ul  ul{
            margin: 0;
            padding: 0;
            display: block;
            @include rtl-left(0);
            position: relative;
        }
    }
}

//over icon  -----------------------------------------------------------------------------------------------------------
.sok_icon{
    display: inline-block;
    vertical-align: middle;
    cursor:pointer;
    margin-right: 30px;
    margin-left: 30px;
    .sok_icon-bar{
        background: $icon-bar;
        display: block;
        height: 2px;
        margin: 4px;
        position: relative;
        width: 22px;
        @include transition(0.4s);
    }
    &:hover{
        .sok_icon-bar{
            @include transition(0.4s);
            background: $icon-bar-hover;
        }
    }
}

