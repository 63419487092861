.form-control{
    border: none;
    font-size: 16px;
    font-weight: 300;
    @include border-radius(none);    
    @include box-shadow(none);
    &:focus{
        @include box-shadow(none);
        border-color:$theme-color;
    }
}
.input-group{
    input,
    .form-control{
        height: 36px;
    }
    .btn{
        padding: 7px 0;
    }
}
input{
    height: 50px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857;
    color: #555555;
    border: 1px solid #e3e3e3;
    @include border-radius(0);
    @include box-shadow(none);
    @include transition(border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s);
    &:-webkit-autofill {    
        -webkit-box-shadow: 0 0 0px 1000px white inset;
        -webkit-text-fill-color: $color-title;        
        &:focus {    
            -webkit-box-shadow: 0 0 0px 1000px white inset;            
        }
    }
    &::selection {    
        background-color: $color-title;
        color: #ffffff;
    }
    &::-moz-selection {    
        background: #000000;
        color: #ffffff; 
    }
}

textarea{
    display: block;
    padding: 12px;
    font-size: 14px;
    line-height: 1.42857;
    color: #555555;
    border: 1px solid #e3e3e3;
    @include border-radius(0);
    @include box-shadow(none);
    @include transition(border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s);
    &:-webkit-autofill {    
        -webkit-box-shadow: 0 0 0px 1000px white inset;
        -webkit-text-fill-color: $color-title;        
        &:focus {                
            -webkit-box-shadow: 0 0 0px 1000px white inset;            
        }
    }
    &::selection {    
        background-color: $color-title;
        color: #ffffff;
    }
    &::-moz-selection {    
        background: #000000;
        color: #ffffff; 
    }
}
//tabs
.nav-tabs{
   > li{
        @include rtl-float-left();
        @include rtl-padding-right(15px);
        > a{
            padding:10px 20px;
            font-size: 16px;
            border: none;
            border-radius: 0;
            &:hover{
                background: transparent;
            }
        }

        &.active{
            > a{
                &,
                &:hover,
                &:focus {
                    background: none;
                    border: none;
                    border-bottom-color: transparent;
                    cursor: default;
                }
            }
        }
   }
}
.btn:focus, .btn.focus, .btn:active:focus, .btn.focus:active, .btn.active:focus, .btn.active.focus{
    outline: none;
}
.form-control::-moz-placeholder{
    font-weight: 400;
}
textarea::-webkit-input-placeholder,
textarea::-moz-placeholder,
input::-webkit-input-placeholder,
input::-moz-placeholder {
    opacity: 0.4;
    transition: opacity 0.4s;
    -webkit-transition: opacity 0.4s;
}

textarea:focus::-webkit-input-placeholder,
textarea:focus::-moz-placeholder,
input:focus::-moz-placeholder,
input:focus::-webkit-input-placeholder  {
    opacity: 0;
    transition: opacity 0.4s;
    -webkit-transition: opacity 0.4s;
}

//form contact
.form-center{
    text-align: center;
    input,textarea{
        width: 100%;
        margin: auto;
    }

    .form-contact{
        margin: auto;
        max-width: 500px;
        display: block;
        text-align: left;
    }
    .des{
        max-width: 800px;
        margin: 0 auto 50px;
        font-size: 16px;
        line-height: 28px;
    }
}
.wpcf7-form{
    text-align: left;

    .block-title{
        text-align: left;
        font-size: 42px;
        margin-bottom: 40px;
    }
    .form-contact span{
        display: block;
    }
    input.wpcf7-form-control{
        display: block;
        width: 100%;
    }
    textarea.wpcf7-form-control{
        display: block;
        width:100%;
    }
    .message-contact{
        margin-top: 30px;
    }
    input.wpcf7-submit{
        margin-top: 30px;
        text-align: center;
        max-width: 200px;
        display: inline-block;
        &.btn-lg{
            padding: 0px 30px;
            height: 60px;
            line-height: 60px;
            font-size: 14px;
            transition: 0.4s;
            letter-spacing: 1px;
        }
        &:hover,&:focus{
            transition: 0.4s;
            border-color: transparent;
        }
    }
    .des{
        margin-bottom: 50px;
    }
    .right{
        text-align: right;
    }
}

form{
    &.login{
        .lost_password{
            font-size: 16px;
            height: 24px;
            @include flexbox();
            @include align-items(center);
        }
        .form-row{            
            .checkbox-style{
                margin: 0;
                height: 24px;
                font-weight: 300;
                font-size: 16px;                
                @include flexbox();
                @include align-items(center);
            }
        }
    }   
}