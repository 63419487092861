.wpb-js-composer{
    .vc_tta{ 
        &.accordion-small{
            &.vc_tta-color-grey{
                &.vc_tta-style-classic{
                    .vc_tta-panel{
                        .vc_tta-panel-body {                                     
                            padding: 15px 0;
                        }
                    }      
                }
            }                       
        }
        &.vc_general{
            .vc_tta-panel-title{
                font-size: 24px;
                line-height: normal;
                position: relative;                
                &:before,&:after{
                    content: "";
                    position: absolute;
                    background-color: $color-title;
                    @include square(17px);
                    @include transition(background-color,transform); 
                    @include transition-duration(.45s);
                    @include transition-timing-function(cubic-bezier(.4,0,.2,1));                                        
                }
                &:before {                 
                    top: 50%;
                    left: 46.5px;
                    margin-top: -1.5px;
                    width: 17px;
                    height: 3px;
                }
                &:after {    
                    top: 50%;
                    left: 53.5px;
                    margin-top: -8.5px;
                    width: 3px;
                    height: 17px;
                }
            }
        }   
        &.vc_tta-accordion{
            .vc_tta-panel-heading{
                .vc_tta-controls-icon-position-right{
                    .vc_tta-controls-icon {                             
                        @include rtl-right(0);
                    }
                }   
            }                        
        }        
    }  
    .vc_tta-color-grey{
        &.vc_tta-style-classic{
            .vc_active{
                .vc_tta-panel-heading{
                    .vc_tta-panel-title{
                        .vc_tta-controls-icon{
                            &:after,&:before{
                                border-color: $color-title;
                            }
                        }   
                    }                    
                }                 
            }                         
            .vc_tta-panel-heading{
                .vc_tta-controls-icon{
                    &:after,&:before{
                        border-color: $color-title;
                    }
                }    
            }                       
        }         
    }    
    .vc_tta-accordion{
        &.accordion_faq{
            margin: 0;
            padding: 162px 0 0 0;
        }
        &.vc_tta-color-grey{
            &.vc_tta-style-classic{
                .vc_tta-panel{
                    margin-top: 0;
                    border-bottom: 1px solid $color-border;
                    &.vc_active{
                        outline-style: none;
                    }
                    .vc_tta-panel-heading {        
                        border-width: 0px;                        
                        background-color: transparent;
                        &:hover,
                        &:focus{
                            background-color: transparent;
                        }
                    }
                    .vc_tta-panel-body {                                                
                        background-color: transparent;
                        border-width: 0;                        
                        padding: 15px 0px 30px 0;                            
                    }
                    .vc_tta-panel-title{
                        > a {
                            color: $color-title;
                            background: transparent;                            
                            @include rtl-padding-left(0);
                        }                        
                    }                     
                    &.vc_active{
                        .vc_tta-panel-title{
                            > a {
                                color: $color-title;
                            }
                        }   
                        .vc_tta-panel-heading {        
                            border-width: 0px;                            
                            background-color: transparent;
                        }
                    }                
                }               
            }
        }   
        &.vc_tta{
            &.vc_tta-color-white{
                &.vc_tta-style-classic{                    
                    .vc_tta-panel{
                        &.vc_active{
                            .vc_tta-panel-heading{
                                background: $color-title;  
                                .vc_tta-panel-title{
                                    &:after {
                                        @include transform(scaleY(0));
                                    }
                                    &:after,&:before {
                                        background: #FFF;
                                    }
                                } 
                                &:hover,&:focus{
                                    background: $color-title;                                  
                                    a{
                                        color: #FFF;
                                    }
                                    &:before{                                    
                                        @include transform(scaleY(0));                             
                                    }
                                }                             
                            }
                            .vc_tta-panel-title{
                                > a{
                                    color: #FFF;
                                }
                            }                             
                        }         
                        .vc_tta-panel-heading {
                            border-color: $color-title;
                            background: transparent;   
                            position: relative;
                            &:before{
                                content: "";
                                position: absolute;
                                top: 0;
                                right: 0;
                                bottom: 0;
                                left: 0;                                
                                background-color: $color-title;
                                @include transform(scaleY(0));
                                @include transform-origin(center bottom);                                      
                                @include transition(transform .45s cubic-bezier(.4,0,.2,1)); 
                            }                            
                            &:hover,&:focus{
                                background: transparent; 
                                .vc_tta-panel-title{
                                    &:before,&:after{
                                        background: #FFF;
                                    }   
                                }
                                a{
                                    color: #FFF;
                                }
                                &:before{                                    
                                    @include transform(scaleY(1));                                   
                                }
                            }
                        }
                        .vc_tta-panel-body{
                            background: $color-title;
                            border-color: $color-title;
                            color: #FFF;
                            &:before,&:after{
                                border-color: $color-title;
                            }
                        }
                        .vc_tta-panel-title{
                            > a{
                                color: $color-title;
                            }   
                        }                         
                    }                     
                }                 
            }            
            &.vc_general{
                .vc_tta-panel-body {
                    color: #FFF;                    
                    background: $color-title;
                    @include rtl-padding(56px, 110px, 103px, 110px);                    
                }
                .vc_tta-icon {
                    font-size: 10px;                                                
                    @include rtl-right(30px);
                    @include vertical-align(absolute);
                }
                .vc_tta-panel-title{
                    > a {
                        padding: 48px 110px;
                    }
                }                 
            }  
            &.vc_tta-style-classic{                
                .vc_tta-panel{
                    &.vc_active{
                        .vc_tta-panel-heading{
                            margin-bottom: -2px;
                        }
                    }    
                    &:not(:last-child){
                        .vc_tta-panel-heading {
                            margin-bottom: -2px;
                        }                 
                    }   
                    .vc_tta-panel-body{
                        &:before,&:after{
                            border-color: transparent;
                        }
                    }                  
                }
                .vc_tta-panel-body,
                .vc_tta-panel-heading {
                    border-width: 2px 0;
                }                
            }             
        }                                
    }    
} 




