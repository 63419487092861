//style carousel
.slick-prev,
.slick-next{
    display: inline-block;
    height: 40px;
    width: 40px;
    text-align: center;
    cursor: pointer;
    color:$font-color;
    background: rgba(255, 255, 255, 1);
    border-radius:0;
    position: absolute;
    top: 50%;
    margin-top: -20px;
    z-index: 99;
    box-shadow:0 1px 3px rgba(0, 0, 0, 0.13);
    opacity: 0;
    @include transition(0.4s);
    &:before{
        color: $font-color;

    }
    &:hover{
        background:#FFF;
        color:$color-title;
        opacity: 1;
        @include transition(0.4s);
        &:before{
            color:$color-title;
        }
    }
}

.article-carousel:hover{
    .slick-prev,
    .slick-next{
        opacity: 1;
    }
}
.nano-testimonial{
    .slick-prev,
    .slick-next{
        margin-top: -50px;
        background: none;
        border: none;
        box-shadow: none;
    }
    .slick-prev:before
    {
        font-size: 36px;
        content: '\f3d5';
        font-family: 'Ionicons';
    }
    [dir='rtl'] .slick-prev:before
    {
        content: '\f3d6';
        font-family: 'Ionicons';
    }
    .slick-next:before
    {
        font-size: 36px;
        content: '\f3d6';
        font-family: 'Ionicons';
    }
    [dir='rtl'] .slick-next:before
    {
        content: '\f3d5';
        font-family: "Ionicons";
    }

    &:hover {
        .slick-prev,
        .slick-next {
            opacity: 1;
        }
    }
}
.article-carousel{
    .slick-prev,
    .slick-next{
        margin-top: -20px;
        background: none;
        border: none;
        box-shadow: none;
    }
    .slick-prev:before
    {
        font-size: 36px;
        content: '\f3d5';
        font-family: 'Ionicons';
    }
    [dir='rtl'] .slick-prev:before
    {
        content: '\f3d6';
        font-family: 'Ionicons';
    }
    .slick-next:before
    {
        font-size: 36px;
        content: '\f3d6';
        font-family: 'Ionicons';
    }
    [dir='rtl'] .slick-next:before
    {
        content: '\f3d5';
        font-family: "Ionicons";
    }

    &:hover {
        .slick-prev,
        .slick-next {
            opacity: 1;
        }
    }
}

.product-carousel{
    .slick-prev,
    .slick-next{
        margin-top: -50px;
        background: none;
        border: none;
        box-shadow: none;
    }
    .slick-prev:before
    {
        font-size: 36px;
        content: '\f3d5';
        font-family: 'Ionicons';
    }
    [dir='rtl'] .slick-prev:before
    {
        content: '\f3d6';
        font-family: 'Ionicons';
    }
    .slick-next:before
    {
        font-size: 36px;
        content: '\f3d6';
        font-family: 'Ionicons';
    }
    [dir='rtl'] .slick-next:before
    {
        content: '\f3d5';
        font-family: "Ionicons";
    }

    &:hover {
        .slick-prev,
        .slick-next {
            opacity: 1;
        }
    }
}

.na-product-image{
    .product-gallery-slider{
        .flickity-prev-next-button{
            height: 30px;
            width: 30px;
            background: none;
            opacity: 0;
            visibility: hidden;
            @include transition(0.4s);
            &:hover,&:focus,&:active{
                .arrow{
                    fill: $theme-color;
                    box-shadow: none;
                }
            }
        }
        &:hover{
            .flickity-prev-next-button{
                opacity: 1;
                visibility: visible;
                @include transition(0.4s);
            }
        }
    }
}

