//Style  Home layout

.home{
    .wrap-breadcrumb{
        display: none;
    }
    .page-header{
        display: none;
    }
}

.site-title{
    display: inline-block;
}
.site-logo{
    a {
        vertical-align: text-top;
    }
}
//layout
.container-fluid{

}
.container-fluid{
    margin-left: auto;
    margin-right: auto;
    padding-left: 60px;
    padding-right: 60px;
}
.container-full{
    margin-left: auto;
    margin-right: auto;
    .container-inner{
        padding: 0 60px;
    }
}
.site-content{
    padding-top:0px;
    padding-bottom:0px;
}


// TopBar ==============================================================================================================
#sok-top-navbar{
    background: $topbar-bg;
    font-size: 12px;
    color: $topbar-color;
    border-bottom:$topbar-border-bottom;
    a{
        color: #888890;
        line-height: 40px;
        font-size: 14px;
        &:hover,
        &:focus{
            color: $topbar-color;
            background: transparent;
        }
    }
    ul > li{
        @include rtl-float-left;
        padding: 7px 15px;
        a{
            padding: 0;
            margin: 0;
            line-height: 26px;
        }
        &:first-child{
            @include rtl-padding-left(0px);
        }
    }

    .topbar-left{
        a{
            color:#888890;
            &:hover{
                color: $topbar-color;
            }
        }
        >*{
            display: block;
            @include rtl-float-left;
            line-height: 26px;
        }
        .list-unstyled{
            padding: 7px 20px;
        }
        .language{
            @include rtl-padding-left(0px);
        }
    }

    .topbar-right{
        line-height: 40px;
        @include rtl-text-align-right();
        .na-topbar{
            @include rtl-float-right();
        }
        .navbar{
            margin: 0;
            min-height:auto;
            >a{
                padding:0;
            }
        }
        .fa{
            @include rtl-margin-right(5px);
        }
        #menu-topbar{
            li:last-child{
                @include rtl-padding-right(0px);
            }
        }
    }
    .widget_nav_menu{
        ul{
            margin: 0;
            padding: 0;
            li{
                list-style: none;
            }
        }
    }
    .sok-social-icon a{
        font-size: 14px;
    }
    .sok-social-icon >a:hover{
        border: none;
    }
}
//====================Header===========================
#na-header{
    padding: 20px 0;

}