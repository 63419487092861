// Style Newsletters
.input-newsletter {
    input{
        padding: 0;
    }
    i {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        z-index: 9;
        font-size: 24px;
        margin: 0;
    }
}
.btn-newsletter.btn {
    padding: 0;
}
.newsletters{
    @include rtl-text-align-left();
    .widgettitle{
    }
    .des-newsletter{
        line-height: 34px;
    }
    .group-input-newsletter{
        margin-top: 25px;
        display:block;
    }
    input,.form-control{
        height: 40px;
        font-size: 14px;
    }
    #mc4wp{
        background: #FFF;
        padding: 5px 15px;
        height: 52px;
        border: 2px solid $color-title;
    }
    .btn-newsletter{
        font-size: 14px;
        margin: 0;
        height: 52px;
        min-width: 100px;
        font-weight: 500;
        color:#FFF;
        text-align: center;
        padding: 7px 10px;
    }
}