

$image-url                : '../images/' !default;



$theme-bg:              #fff ;


$theme-color:           #222;
$font-color:            #212529;


$border-color-base: 1px solid #ebebeb;

$secondary:         #F3F3F3;    // Secondary buttons
$secondarytext:     desaturate(darken($secondary, 60%), 21%);

$color-title                :#222222;

$white:	#ffffff;
$black: $color-title;

$font-family-once	    : "now" !default;
$font-family-two  	    : $font-family-once !default;
$font-family-three      : $font-family-once;

$font-size:             13px !default;

//===============================topbar============================
$topbar-bg              :#F5F5F5;
$topbar-color           :$color-title ;
$topbar-border-color    :1px solid #232323;
$topbar-border-bottom   :0px solid transparent;

//===============================Menu============================

$menu-bg                 :white;
$menu-bg-hover           :transparent;

$menu-color              :#040203;
$menu-color-hover        :$theme-color ;

$menu-border             :1px solid #f1f1f1;

$menu-padding            :0;
$menu-rtl                :-15px;

$icon-bar                 :#040203;
$icon-bar-hover           :$theme-color;

//===============================Search============================
$search-menu              :lighten($menu-bg, 12%);
$search-color             :#757575;

//===============================Buttom============================

$btn-primary-bg:         $theme-color !default; // #337ab7
$btn-success-bg:         #b7cc16 !default;
$btn-info-bg:            #4BC5C6 !default;
$btn-warning-bg:         #DF8B1B !default;
$btn-danger-bg:          #E3503C !default;

//btn
$btn-cart-color:            $white;
$btn-cart-bg:               #1f1b1c;
$btn-cart-border:           #1f1b1c;
$btn-cart-colorhover:       $white;
$btn-cart-bghover:          $theme-color;
$btn-cart-borderhover:      $theme-color;

$btn-outline-color:        #8c8c8c;
$btn-outline-bg:           transparent;
$btn-outline-border:       transparent;
$btn-outline-colorhover:   $theme-color;
$btn-outline-bghover:      transparent;
$btn-outline-borderhover:  transparent;

$btn-inverse-color:        #8c8c8c;
$btn-inverse-bg:           transparent;
$btn-inverse-border:       transparent;
$btn-inverse-colorhover:   $theme-color;
$btn-inverse-bghover:      transparent;
$btn-inverse-borderhover:  transparent;

//===============================Product============================

$sale                       :#c69f73 !default;
$out-of-stock               :#8b8b91 !default;

//===============================Footer============================
$footer-top             :#F5F5F5;
$footer-center          :#fff;
$footer-bg              :#fff;
$footer-bg-bottom       :#fff;
$footer-color           :#767676;
$footer-title-color     :$color-title;
$footer-border-color    :1px solid #424242;
$footer-border-top      :1px solid #555555;
$footer-border-nav      :1px solid #424242;

$coppy-right-color:          #ffffff;
$coppy-right-color-link:     $theme-color;

//===============================New Letter============================
$new-letter-color        :#333333;
