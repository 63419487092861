/*Style single blog*/
.wrap-single{
    margin-top: 80px;
}
.modal-header{
    &.btn-close-popup{
        padding: 0;
        margin: 0;
        min-height: auto;
    }
} 
.meta-social{
    #btnShare{
        padding: 0 !important;
    }
    .modal-body{
        padding: 0;
        margin: 0;
        position: relative;
        width: 100vw;
        height: 100vh;
    }
}
.post-format .ti-control-play,
.post-format .ti-camera,
.post-format .ti-headphone,
.post-format .ti-quote-left{
    position: absolute;
    right:10px;
    top:10px;
    background: $theme-color;
    width: 50px;
    height: 50px;
    text-align: center;
    vertical-align: middle;
    color: #f1f1f1;
    border-radius: 100%;
    line-height: 45px;
    @include transition(0.4s);
    display:none;
    &:hover{
        @include transition(0.4s);
        background: $theme-color;
        color: #f1f1f1;
    }
}
.ti-control-play
.post-image{
    text-align: center;
    padding-bottom: 15px;
    position: relative;
}

.post-image{
    > *{
        display: block;
    }
    ul li{
        list-style:none;
    }
    .owl-carousel{
        padding: 0;
        z-index: 999;
        .fa{
            font-weight: 700;
        }
        .owl-prev{
            left:15px;
            border-width:2px;
        }
        .owl-next{
            right:15px;
            border-width:2px;
        }

    }
    img{
        float: none !important;
    }
}
.single-image{
    background: #ccc;
    margin-bottom: 50px;
}
.post-cat{
    text-transform: capitalize;
    position: relative;
    a{
        color: $font-color;
        font-size: 14px;
        &:hover{
            color: $color-title;
        }
    }
}

.entry-date{
    color: $font-color;
    &:hover{
        color: $color-title;
    }
}
//title entry
.entry-header{
    margin-bottom: 50px;
    max-width: 1100px;
    text-align: center;
    >header{
        display: block;
    }
}
.entry-title{
    display:block;
    padding-top: 10px;
    margin-bottom: 20px;
    font-size: 54px;
    line-height:80px;
    font-weight: 500;
    margin-top: 0;
    -ms-word-wrap: break-word;
    word-wrap: break-word;
    color: #1a1a26;
    >a{
        color: #1a1a26;
        -ms-word-wrap: break-word;
        word-wrap: break-word;
        &:hover{
            color: $color-title;
        }
    }
}

.entry-avatar{
    padding: 15px 30px 50px;
    .avatar{
        border-radius: 100%;
        @include rtl-margin-right(10px);
    }
    .author-by{
        font-style: italic;
        color: #747474;
    }
    .author-title{
        @include rtl-padding-left(3px);
        color: $theme-color;
    }
    .author-link{
        color: $theme-color;
    }
    .count-share{
        @include rtl-float-right();
    }
    >*{
        display:inline-block;
    }
}

.entry-footer{
    color: #757575;
    margin-top: 50px;
    .watch-action {
        float: none;
        width: auto;
        .status {
            display: none;
        }
        .lc, .unlc {
            line-height: 24px;
        }
    }
    i{
        font-size: 14px;
        @include rtl-padding-right(3px);
        line-height: 22px;
    }

    .comment-text{
        @include rtl-padding-right(20px);
        .fb_comments_count{
            @include rtl-padding-right(5px);
        }
        .i{
            font-size: 16px;
        }
        .txt{
            font-size:12px;
        }

    }
    .total-view{
        @include rtl-padding-right(20px);
    }
}
.archive-header .entry-title{
    padding: 15px 0;

}

//meta comment
.post-comment{
    padding-bottom: 25px;
    line-height: 24px;
    .fa{
        @include rtl-padding-right(10px);
        color: $theme-color;
    }
    span{
        font-size: 12px;
        &:first-child{
            @include rtl-padding-left(0px);
        }
    }
}

//tag
.tags{
    > span{
        font-weight: bold;
        font-size: 16px;
        @include rtl-padding-right(5px);
    }
    .fa{
        @include rtl-padding-right(10px);
        color: $font-color;
    }
    a{
        color: $color-meta;
        display: inline-block;
        font-size: 16px;
        line-height: 24px;
        @include rtl-padding-left(7px);
        text-decoration: none;
        transition: all 0.4s ease 0s;
        &:hover{
            @include transition(0.4s);
            color: #fff
        }

    }
}

//content
.entry-content{
    margin-top: 50px;
    padding-bottom: 40px;
    font-size: 18px;
    line-height: 34px;
    ul,ol{
        margin-bottom: 30px;
    }
    a{
        box-shadow: 0 -1px 0 $font-color inset;
        &:hover{
            box-shadow:none;
        }
    }
}
//avatar
.author-img{
    >img{
        border-radius:100%;
        max-width:inherit;
    }
}
.avatar{
    >img{
        max-width:inherit;
        border-radius: 100%;
    }
}
.author-content{
    h5{
        margin-top: 40px;
        margin-bottom: 15px;
    }

    a{
        color: $color-title;
        font-size: 24px;
        text-transform: capitalize;
    }
    .author-social{
        margin: 0 15px;
        .ti-facebook{
            color: #3b5998;
        }
        .ti-twitter-alt{
            color: #00aced;
        }
        .ti-google{
            color: #dd4b39;
        }
    }
    p{
        font-size: 18px;
        max-width: 900px;
        margin: auto;
        padding-bottom: 0px;
        position: relative;
    }
    [class*="ion-social-"]{
        font-size: 24px;
    }
    .author-social{
        margin: 0px 15px;
        padding-top: 15px;
        position: relative;
        display: inline-block;
    }
}
//comment
.comment-text{
    .tags-link-wrap{
        @include rtl-padding-right(30px);
        display: inline-block;
    }
    .text-comment{
        .fa{@include rtl-padding-right(10px);}
        display: inline-block;
    }
    time{
        font-size: 12px;
    }
    .meta{
        margin-bottom: 15px;        
        line-height: 20px;
    }
}
.comment-box{
    .comment-text{
        >p{
            margin:0;
        }
    }
}
.commentform{
    padding-bottom: 10px;
}
.logged-in-as {    margin-bottom: 20px;
    
}
#comments,#review_form {
    margin-top: 80px;
    border-top:1px solid  $color-border;
    clear: both;
    .form-control {
        border: 1px solid $color-border;
    }
    .comment-reply-title{
        margin-top: 80px;
        margin-bottom: 10px;
    }
    input {
        height: 50px;        
        &.submit{
            height: 60px;
            padding: 6px 50px;
        }
    }    
    .heading{
        font-size: 32px;
        color: $color-title;
        margin-bottom: 20px;
        margin-top: 80px;
        line-height: 42px;
    }
    .comment-respond .heading{
        font-size: 30px;
        color: $color-title;
        line-height: 36px;
    }
    .comment-list{
        padding: 0;
        margin: 0;

        li.comment,li.pingback {
            list-style: outside none none;
            padding: 30px 0;
            &:last-child{
                padding-bottom: 0;
            }
        }
        > li:first-child{
            border: none;
        }
        li.pingback {
            .avatar{
                padding: 0;
            }
            .comment-bottom{
                display: none;
            }
            .meta-user{
                padding-top: 0;

            }
            .text-user{
                text-transform: capitalize;
                font-size: 16px;
                font-weight: 400;
            }
        }
    }

    .text-user{
        text-transform: capitalize;
        color: #1a1a26;
        >a{
            color: $color-title;
        }
    }
    .meta-user{
        color: #7c7c7e;
        padding-top: 15px;
        a{
            color: $color-title;
            font-size: 14px;
            line-height: 24px;
            position: relative;
            @include rtl-padding-left(25px);
            &:hover {
                color: $color-meta;
            }
            &:after{
                content: "\f3d5";
                font-family: Ionicons;
                font-size: 26px;
                line-height: 24px;
                vertical-align: middle;
                padding-right: 5px;
                display: inline-block;                
                left: 0;                
                @include vertical-align(absolute);
            }
        }
    }

    .children{
        list-style: none;
        @include rtl-padding-left(120px);
        li:first-child{
            margin-top: 30px;
        }
        li:last-child{
            padding-bottom: 0;
        }
    }
    .comment-author{
        padding-bottom: 15px;
    }

    .h-info{
        padding-bottom: 0px;
    }

    .the-comment > .avatar{
        @include rtl-padding-right(40px);
    }
    .ground-user{
        float: left;
        .comment-time{
            padding:0px;
            display: block;
            font-size: 14px;
            line-height: 20px;
            color: $color-meta;
        }
        .text-user{
            font-size: 20px;
            letter-spacing: 0.5px;
            font-weight: 500;
        }
    }
    .comment-edit-link{
        float: right;
        font-size: 14px;
        padding-top: 15px;
    }
    #cancel-comment-reply-link{
        @include rtl-padding-left(30px);
        padding-top: 8px;
        font-size: 14px;
        text-transform: capitalize;
        &:hover{
            color: $color-title;
        }
    }
}
#commentform{
    .comment-text{
        text-transform: lowercase;
    }
}

//post related
.post-related{
    article.post-item {
        box-shadow: none;
        margin-bottom: 0px;
    }
    .widgettitle{
        text-align: center;
        display: block;
        font-size: 36px;
        margin-bottom: 60px;
        font-weight: 500;
        padding-bottom: 0;
        position: relative;
        text-transform: none;

    }
    .post-grid  .article-content{
        padding:15px 5px 30px;
        &.full-width{
            padding:0px 5px 30px;
            margin-top: -10px;
        }
    }
    .row{
        margin: 0 -20px;
    }
    .item-col{
        padding-left: 20px;
        padding-right: 20px;
    }
    .post-cat{
        font-size: 12px;
    }
    .group-des{
        color: $font-color;
        font-style: italic;
        font-size: 12px;
    }
    .author-link{
        color:$font-color;
        font-style: normal;
        &:hover{
            color: $theme-color;
        }
    }
    .entry-title a{
        font-size: 24px;
        line-height: 38px;
        font-weight: 500;
    }
    .post-grid .entry-title{
        line-height: 24px;
    }
    .btn-readmore{
        display: none;
    }
    .full-width{
        .entry-header{
            padding-top: 0;
        }
    }
    .entry-content{
        padding-bottom: 15px;
    }
    .posted-on a,.entry-date{
        font-size: 14px;
        color: $color-meta;
    }

}
.item-related{
    .post-format{
        width: 30px;
        height: 30px;
        @include rtl-right(20px);
        line-height: 32px;
        font-size: 12px;
        bottom: -15px;
        top:auto;
    }
}
.item-related{
    margin-top: 20px;
    border-radius: 3px;
    img{
        width: 100%;
    }
    .post-image{
        padding: 0;
    }
    .ground-title{
        min-height: 70px;
        padding: 15px 20px 30px;
    }
    .post-title{
        @include rtl-text-align-left();
        font-size: 14px;
        color: $color-title;
        margin: 3px 0 20px;
        line-height: 20px;
        min-height: 40px;
        >a{
            color:$color-title;;
            &:hover{
                color: $theme-color;
            }
        }
    }
    .embed-responsive{
        overflow: visible;
    }
    .article-content.full-width .entry-content{
        padding: 0;
    }

}

//comments
.item-comment{
    padding:0;
    font-style: normal;
    margin: 0;
    >span{
        vertical-align: middle;
        @include rtl-padding-left(7px);
        font-size: 14px;
    }
    i{
        font-size: 14px;
    }
}
.icon-comment{
    font-size: 18px;
    color: #D1D1D0;
    @include rtl-padding-left(0px);
}
//pagination post
.entry_pagination{
    margin-top: 15px;
    .txt-pagination {
        p{
            margin-bottom: 5px;
        }
        span{
            font-size: 14px;
            display: inline-block;
            color: $color-meta;
            line-height: 24px;
            text-transform: capitalize;
        }
    }
    .pagination{
        padding:20px 0px 25px 0px;
        margin: 0;
        .txt-pagination{
            span{
                font-size: 14px;
                line-height: 30px;
                vertical-align: top;
            }
        }

    }
    .page-numbers{
        background: none;
        box-shadow: none;
        color: $color-title;
        width: auto;
        vertical-align: middle;
        border-radius:0;
        margin: 0;
        border: none;
        height: initial;
        line-height: 30px;
        img {
            display: table-cell;
        }
        i{
            font-size: 24px;
            line-height: 30px;
            display: inline-block;
            color: $color-meta;
        }
        &:hover{
            background: transparent;
            color: $color-title;
            span{
                color: $color-title;
            }
            i{
                color: $color-title;
            }
        }
        &.page-prev{
            max-width: 45%;
            @include rtl-text-align-left();
            @include transition(0.4s);
            border-radius: 0 0 0 5px;
            img{
                @include rtl-float-left();
            }
            i{
                @include rtl-padding-right(5px);
            }
        }
        &.page-next{
            max-width: 45%;
            @include rtl-text-align-right();
            @include transition(0.4s);
            border-radius: 0 0 5px 0;
            img{
                @include rtl-float-right();
            }
            i{
                @include rtl-padding-left(5px);
            }
        }
        .title-pagination{
            font-size: 16px;
            font-weight: 500;
            line-height: 24px;
        }

    }
}
.form-submit{
    @include clearfix();
    margin-top: 40px;
    margin-bottom: 0;
}

.article-meta-single{
    font-size: 16px;
    position: relative;
    overflow: hidden;
    font-weight: 300;
    > * {
        display: inline-block;
        text-align: center;
        padding: 0 10px;
    }
    .posted-share{
        padding-left: 3px;
    }
    .author-link,.posted-on,a,.posted-share{
        color: $color-title;
        font-weight: 400;
        box-shadow: 0 -1px 0 #ccc inset;
        @include transition(0.4s);
        &:hover{
            color: $color-title;
            box-shadow: 0 -1px 0 $color-title inset;
            @include transition(0.4s);
        }
    }
    .byline{

    }
    .btn-share{
        cursor: pointer;
    }
}

.tags-wrap{
    display:block;
    font-size: 14px;
    text-align: center;
    a{
        font-size: 14px;
        padding:2px 15px;
        margin: 0 5px;
        border: 1px solid $color-meta;
        color: $color-title;
        &:hover{
            background: $color-title;
            border: 1px solid $color-title;
            color: #fff;
        }
    }
}
.entry-footer-social{
    display:block;
    text-align: center;
    color: #757575;
    .entry-footer-left{
        >*{
            display: inline-block;
        }
    }

    .entry-footer-right{
        >*{
            display: inline-block;
            color: #252525;
        }
        .lc-4,.lc{
            color: #757575;
            height: 30px;
            @include rtl-left(0);
            position: absolute;
            top: 0;
            width: 30px;
            font-size: 0;
        }
        .action-like  span::before{
            font-size: 14px;
            @include rtl-left(-22px);
            line-height: 5px;
            top: 6px;
            color: #DD4B39;
        }
        .like-links{
            cursor: pointer;
            &:hover{
                color: #DD4B39;
                a{
                    color: #DD4B39;
                }
            }
        }
        .share-links .social-item{
            padding:0 10px;
        }
        .social-item a i{
            font-size: 24px;

        }
    }
}
//like
.entry-content{
    .watch-action{
        display: none !important;
    }
}
.watch-action{
    .watch-position,.action-like,.lc,.unlc{
        float: none;
        line-height: 24px;
    }
    position: relative;
    .action-like,.action-unlike{
        img{
            display: none;
        }
    }
    .action-like{
        position: relative;
        @include rtl-padding-left(15px);
        >a{
            color: #757575;
            .lc{
                color:#757575;
            }
        }
        span::before{
            content:"\e634";
            font-family: 'themify';
            font-size: 14px;
            top:-2px;
            @include rtl-left(0);
            position: absolute;

        }
    }
    .action-unlike{
        display: none !important;
    }
    .status{
        display: none !important;
    }

}

.main-content .box-comment{
    margin-bottom: 0;
    margin-top: 150px;
}
.comment-navigation{
    clear: both;
    padding: 30px 0;
    .previous{
        @include rtl-float-left();
        display: inline-block;
    }
    .next{
        @include rtl-float-right();
        display: inline-block;
    }
}
.taxonomy-description {
    font-size: 14px;
    margin-bottom: 30px;
}
.description-page{
    display: none;
    &.show{
        display: block;
    }
}

//img-footer2
.sok-image-content {
    margin-bottom: 50px;
}
.fitnez-instagram{
    margin-left: -10px;
}
.contact-info {
    b{
        color: $color-black;
    }
}
.single-post{
    .entry-header{
        margin:0px auto 60px;
    }
    .entry-excerpt{
        max-width: 1100px;
        font-size: 16px;
        margin: auto;
    }
    .entry-content{
        max-width: 1100px;
        margin:0px auto 0;
    }
    .entry-footer{
        max-width: 1100px;
        margin:auto;
    }
    #comments{
        margin: 50px auto 0;
    }
    .box-author{
        max-width: 1100px;
        background: #F3F3F3;
        margin:80px auto 0;
        padding: 15px;
        text-align: center;
        .post-author{
            padding: 70px;
            border: 1px solid #e3e3e3;
        }
    }
    .entry-footer{
        clear: both;
    }
    .tags-wrap,.entry-footer-social{
        margin-top: 0px;
    }

    .post-related{
        margin:80px auto 30px;
        position: relative;
        .widgettitle {
            span{
                padding: 30px;
                background: #FFF;
                &:before{
                    content: "";
                    background: #e3e3e3;
                    width: 100%;
                    height:1px;
                    position: absolute;
                    top:24px;
                    left: 0;
                    z-index: -1;
                }
             }
        }
    }
    .box-article{
        .entry-excerpt{
            color: $color-title;
        }
    }
    .woocommerce-breadcrumb > span{
        display: none;
    }
    .woocommerce-breadcrumb  a + a{
        margin-right: 0;
        &::after {
            content: none;
        }
    }
    .social-modal{
        padding: 0;
        margin: 0;        
        @include align(absolute);
        .modal-share{
            font-size: 20px;
        }
        .entry-header-title{
            max-width: 900px;
            margin: auto;
        }
        .social-item a{
            box-shadow: none;
            i{
                font-size: 28px;
                color: $color-title;
            }
        }
        .social-item{
            padding: 5px 15px;
        }

    }

}
.wp-block-image{
    margin-bottom: 30px;
}