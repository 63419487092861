
body{
    overflow-x: hidden;
}
.search-box,.intro-box,.login-box,.mobile-box{
    background: #FFF;     
    position: fixed;
    top: 0;
    visibility: visible;
    z-index: 10000;
    height: 100%;
    width: 600px;    
    overflow-y: auto;
    -ms-overflow-y: auto;
    @include opacity(1);
    @include border-radius(0);        
    @include transition(0.5s);
    @include rtl-right(0);    
    @include transform(translateX(600px));
}
.intro-box ,.mobile-box{
    .canvas-header{
        padding-bottom: 50px;
    }
    .widget_contact_info .about-image{
        padding-bottom: 20px;
    }
}

.canvas-overlay.show{
    background-color: rgba(0, 0, 0, 0.4);
    height: 100%;
    position: fixed;
    width: 100%;
    z-index: 9;
    opacity: 1;
    visibility: visible;
    cursor: pointer;
}

.canvas-intro{
    .intro-box{
        transform: translateX(0px);
        -webkit-transform: translateX(0px);
        -ms-transform: translateX(0px);
        -o-transform: translateX(0px);
        z-index: 99999;
    }
}

.canvas-login{
    .login-box{
        transform: translateX(0px);
        -webkit-transform: translateX(0px);
        -ms-transform: translateX(0px);
        -o-transform: translateX(0px);
        z-index: 99999;
    }
}

.canvas-search,.search-wrap{
    .search-field{
        width: 70%;
    }
    button{
        height: 50px;
        width: 27%;
        border: 1px solid #222;
        background: $theme-color;
        color: #FFF;
        float: right;
    }
    .search-box{
        transform: translateX(0px);
        -webkit-transform: translateX(0px);
        -ms-transform: translateX(0px);
        -o-transform: translateX(0px);
        z-index: 99999;
    }
}

.canvas-mobile{
    .mobile-box{
        transform: translateX(0px);
        -webkit-transform: translateX(0px);
        -ms-transform: translateX(0px);
        -o-transform: translateX(0px);
        z-index: 99999;
    }
}


.admin-bar{
    .canvas-warp{
        padding-top: 32px;
    }
}
.canvas-warp{
    padding: 80px;
    @include rtl-border-left(1px solid #e9e9e9);  
    .myaccount-login{
        padding-top: 100px;
        padding-bottom: 30px;
    }  
    .aws-container{
        margin-top: 60px;
    }
    #loginTab{
        margin-bottom: 20px;
        border-bottom: 0;        
        > li{
            > a{
                font-size: 36px;
                line-height: 46px;
                font-weight: 500;        
                padding-bottom: 30px;  
                padding-top: 0;
                color: darken($color-border, 20%);  
                &:first-child{
                    padding-left: 0;
                }
            }
            &.active{
                > a{
                    color: $color-title;
                }
            }            
        }         
    }
    #loginTabContent{        
        input[type="text"],
        input[type="email"],
        input[type="password"]{
            padding: 20px 0;
            border-style: solid;
            border-color: $color-border;            
            border-width: 0 0 1px 0;
        }
    }
    .form-control{
        padding: 7px 15px;
    }
}
.canvas-header{
    display: block;
    padding: 0;
    margin: 0;
    line-height: normal;
    .btn-close{
        @include square(80px);        
        @include flexbox();
        @include justify-content(center);
        @include align-items(center);
        @include opacity(1);
        position: absolute;
        top: 0;
        right: 0px;
        outline: thin;
        background: transparent;
        &:hover{
            background: transparent;
            @include opacity(1);
        }
    }
}
.btn-intro,.btn-mobile{
    font-size: 28px;
}
.btn-close{
    font-size: 42px;
    color: #201f1f;
    opacity: 1;
    font-weight: 400;
    line-height: 26px;
    text-transform: lowercase;
    &:hover{
        color: $theme-color;
    }
}
//Search
.search-title{
    padding-top: 100px;
    font-size: 36px;
    color: $color-title;
    font-weight: 500;
    margin-bottom: 30px;
    margin-top:0;
}
.admin-bar{
    .canvas-header{
        position: relative;
    }
    .canvas-header .btn-close{
        right: -40px;
    }
}