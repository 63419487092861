//Style share box
.share-links{
    text-align: center;
    @include rtl-padding-left(20px);
    .count-share {
        vertical-align: middle;
        position: relative;
        >span {
            font-size: 16px;
            line-height: 30px;
            color: #1a1a26;
            font-weight: 400;
        }
        i{
            font-size: 16px;
            color: $theme-color;
        }
        .social-item{
            i{
                @include rtl-padding-right(0px);
            }
        }
    }
    .social-icons{
        margin: 0;
        display: inline-block;
    }
    .social-item{
        padding: 4px 10px ;
        border-radius: 3px;
        cursor: pointer;
        text-align: center;

        &:hover {
            a,i {
                color: $color-black;
            }
        }
        @include transition(0.2s);
        a {
            display: inline-block;
            color: $color-meta;
            text-align: center;
            i {
                font-size: 16px;
                color: $color-meta;
                @include transition(0.2s);
            }
        }
    }

    .author-social{
        border-radius: 100%;
        @include size(24px,24px);
        text-align: center;
        line-height: 20px;
        @include rtl-margin-right(3px);
        i{
            font-size: 14px;
            @include rtl-padding-right(10px);
        }
    }
}
//sb_instagram
#sb_instagram{
    position: relative;
    .sbi_follow_btn a{
        background: none;
        color: #fff;
        font-size: 14px;
        background: $color-title;
        border-radius: 0;
        text-align: center;
        padding: 12px 20px;
        letter-spacing: 3px;
        @include transition(0.4s);
        &:hover{
            background: #555555;
            box-shadow:none;
            color: #fff;
            letter-spacing: 4px;
            @include transition(0.4s);
        }
    }

}
#sbi_load{
    position: absolute;
    top:30%;
    left: 0;
    right:0;
    text-align: center;
    .fa-instagram,svg:not(:root).svg-inline--fa{
        height: 40px;
        display: none;
        padding-bottom: 10px;
    }
}
#sb_instagram #sbi_load .fa, #sb_instagram .sbi_follow_btn .fa{
    font-size: 24px;
    color: $theme-color;
}
