//style widget TEAMS
.nano-infoTeams {
    text-align: left;
    margin-bottom: 0;
    .block-title{
        margin-bottom: 0;
        margin-top: 0;
    }
    .block-des{
        text-align: center;
        max-width: 600px;
        margin:auto;
        padding-top: 15px;
        padding-bottom: 20px;
        color: $color-meta;
    }
    .block-content{
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        margin:0 -25px;
        padding-top: 40px;
    }
    .box-team-list{
        position: relative;
        -webkit-flex: 1 1 auto;
        -ms-flex: 1 1 auto;
        flex: 1 1 auto;
        padding: 0 25px;
    }
    .title-box{
        font-size: 24px;
        line-height: 34px;
        margin-top: 20px;
        margin-bottom: 0px;
    }

    .des-box{
        font-size: 12px;
        text-transform: uppercase;
        color: $color-meta ;
        letter-spacing: 0.5px;
    }
    .box-content{
        padding-left: 20px;
        background: #FFF;
        position: absolute;
        left: 25px;
        bottom: 0;
        min-width: 270px;
    }
    .list-social{
        position: relative;
        list-style: none;
        padding: 0;
        li{
            display: inline-block;
            &:hover{
                i{
                    color:$color-title;
                }
            }
        }
        i{
            font-size: 16px;
            width: 30px;
            height: 30px;
            line-height: 30px;
            color: $color-meta;
        }
    }
}