$image-url                : '../images/' !default;
.searchform,.woocommerce-product-search{
    padding: 6px 0;
    .btn{
        z-index: 10;
        @include rtl-margin-left(-4px);
        padding: 10px 22px;
        font-size: 20px;
        line-height: 28px;
        min-width: 69px;
    }
    .form-control{
        width: 100%;
        border-radius: 0;
        padding: 7px 15px;
        font-size: 16px;
        height: 48px;
        &:focus{
            border-color:$theme-color;
        }
    }
    .input-group{
        border: 1px solid #e6e5e1;
    }
    button{
        height: 48px;
    }
    .btn-primary{
        background: none;
        color: $color-title;
    }
}


.na-filter-attribute{
    position: relative;
}

.na-list-product-category{
    margin: 0;
    li{
        @include rtl-float-left();
        font-size: 12px;
        @include rtl-padding-right(30px);
        line-height: 40px;
        text-transform: uppercase;
        position: relative;
        letter-spacing: 1px;
        font-weight: 500;
    }
    li + li::after {
        content: "/";
        @include rtl-left(-17px);
        position: absolute;
        top: 0;
        color: #cfcfcf;
    }
}
.small{
    .widgettitle{
        font-size: 24px;
        > span{
            border: none;
            padding: 0;
        }
    }
    .category-tabs-filter{
        padding-top: 15px;
    }
}
.na-products-wrap.loading{
    .na-wrapper-products-shortcode:after{
        background:url('#{$image-url}loader.gif') repeat 0 0;
        background-repeat: no-repeat;
        content: "";
        display: block;
        height: 31px;
        @include rtl-left(50%);
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 31px;
        z-index: 900;

    }
    .category-tabs-filter{
        opacity: 0.3;
    }
}
.category-tabs-filter{
    padding: 30px 0;
}
.na-list-filter{
    font-size: 14px;
}

.na-products-wrap{
    .filter-content{
        padding: 0;
    }
}

.na-filter-wrap{
    #na-filter .widget{
        min-width:180px;
        @include rtl-float-left();
        .na-ajax-load{
            li{
                cursor: pointer;
                line-height: 25px;

            }
            a{
                color:#777777;
                &:hover{
                    color: $theme-color;
                }
            }
        }
    }
    .chosen{
        color:$theme-color !important;
    }
    .na-remove-attribute{
        font-size: 12px;
        color: $theme-color;
        @include rtl-margin-right(10px);
    }
}

.filter-title{
    font-size: 14px;
}

.tags-list{
    max-width:180px;
    li{
        @include rtl-padding-right(5px);
        @include rtl-float-left();
    }
    a{
        padding: 6px 8px;
        &:hover{
            color: $white !important;
        }
    }
}

//search
.header-content-left .sok-hidden,.header-content-right .sok-hidden{
    opacity: 0;
    visibility: hidden;
    @include transition(1s);
    .searchform,.woocommerce-product-search{
        opacity: 0;
        padding: 50px !important;
        @include translateY(-35%);
        @include transition-transform(0.5s);
    }
    .btn-mini-close{
        opacity: 0;
        @include transition(0.4s);
    }
}
.searchform-mini.searchform-moblie{
    position: absolute;
    top:50%;
    right:0px;
}


