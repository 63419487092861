//style widget Tabs Product

.category-tabs{
    padding-top: 50px;
    .center{
        text-align: center;
    }
    .widgettitle{
        display: block;
        text-transform: capitalize;
        font-size: 36px;
        color: $color-title;
        margin-bottom: 20px;
        margin-top: 0px;
        .nav-tabs{
            display: inline-block;
        }
        span{
            color: $color-title;
        }
        &.left{
            @include rtl-text-align-left();
        }
        &.center{
            text-align: center;
        }
        &.right{
            @include rtl-text-align-right();
        }
    }
    ul.nav-tabs{
        display: inline-block;
        position: relative;
        border: none;
        li{
            @include rtl-padding-right(30px);
            a:hover{
                background: none;
            }
        }
        li.active > a,
        li.active > a:hover,
        li.active > a:focus{
            text-decoration:underline;
            background: none;
            color: $color-title;
        }
    }
    .cat-title{
        padding-bottom: 30px;
        &.left-fixed{
            vertical-align: bottom;
            text-align: center;
            position: fixed;
            top:30%;
            left: 0;
            //opacity: 0;
            -ms-writing-mode: tb-rl;
            -webkit-writing-mode: vertical-rl;
            writing-mode: vertical-rl;
            transform: rotate(180deg);
            white-space: nowrap;
            @include transition(0.4s);
            opacity: 0;
            &.view{
                @include transition(0.4s);
                opacity: 1;
            }
        }
    }
    .tabs-title-cat{
        font-size: 16px;
        padding-bottom:5px;
        padding-top: 30px;
        font-weight: 400;
        background: none;
        color: $font-color;
        display: block;
        &:focus,
        &.focus,
        &:active:focus,
        &.focus:active,
        &.active:focus,
        &.active.focus{
            text-decoration:underline;
            background: none;
            color: $color-title;
        }
    }


    &.category-tabs-filter{
        .widgettitle{
            font-size: 30px;
            padding-bottom: 30px;
        }
    }
}


