//Menu style

//lv1
#na-menu-primary{
    ul.na-menu >li >a{
        color: $color-title;
        line-height: 100px;
        @include transition(0.4s);
        font-weight: 400;
        padding: 0 20px;
         &:hover,&:focus{
             color: #888890;
             background: $menu-bg-hover;
             @include transition(0.4s);
         }
    }
    ul.na-menu >li:first-child > a{
        @include rtl-padding-left(0);
    }
    ul.na-menu{
        position: static;
        li.information_widget:first-child{
            clear: left;
        }
        .mega-sub-menu{
            max-width: 1170px;
        }
        ul > li {
            float: none;
        }

    }
    ul.na-menu > li[class*="mega-current-"] > a{
        color: $menu-color-hover !important;
        &:before{
            color: $menu-color-hover !important;
        }
    }
    ul li a{
        display: inline-block;
    }
    li.new > a{
        width: auto;
        display: inline-block;
        position: relative;
        &:after{
            background:url('#{$image-url}trending.png') repeat 0 0;
            background-repeat: no-repeat;
            content: "";
            display: block;
            height: 31px;
            right: -40px;
            position: absolute;
            top: 5px;
            width: 31px;
        }
        &:hover,&:focus{
            &:after{
                width: 31px!important;
            }
        }
    }
    li.hot > a{
        width: auto;
        display: inline-block;
        position: relative;
        &:after{
            background:url('#{$image-url}hot.png') repeat 0 0;
            background-repeat: no-repeat;
            content: "";
            display: block;
            height: 31px;
            right: -40px;
            position: absolute;
            top: 5px;
            width: 31px;
        }
        &:hover,&:focus{
            &:after{
                width: 31px !important;
            }
        }
    }

    ul{
        margin: 0;
        padding: 0;
        position: relative;
        @include clearfix();
        > li {
            list-style:none;
            position: relative;
            @include rtl-float-left();
            &:hover{
                > a{
                    color: $color-title;
                }
            }

            > a{
                font-size: 13px;
                font-weight:500;
                text-transform: uppercase;
                color: #888890;
                padding:$menu-padding;
                position: relative;
                font-family: $font-family-two;
                letter-spacing: 0.5px;
                &:hover,&:focus{
                    color: $menu-color;
                    background: transparent;
                    &:after{
                        display: block;
                        width: 100%;
                        @include transition(0.4s);
                    }

                }
            }
        }
    }
    //lv2
    ul >li{
        > ul{
            display: none;
            top:120%;
            @include transition(0s);
            position: absolute;
            min-width: 210px;
            box-shadow: 1px 5px 15px 0 rgba(0, 0, 0, 0.05);
            background: #FFF;
            z-index: 99989;
             li[class*="-has-children"]{
                  a:before{
                      content: none;
                  }
                  &:after{
                      position: absolute;
                      content: "\f125";
                      color: #888890;
                      font-family:'ionicons';
                      @include rtl-right(10px);
                      line-height: 50px;
                      font-size: 14px;
                      top:0;
                  }
                 &:hover:after{
                     color:$color-title;
                 }
             }

            >li{
                float: none ;
                display: block;
                @include rtl-border-right(none) ;
                @include rtl-text-align-left;
                border-top:$border-color-base;
                color: $font-color;
                &:first-child{
                    border:none;
                }
                a{
                    padding: 15px 25px;
                    text-transform: capitalize;
                    font-size: 14px;
                    line-height: 20px;
                    font-weight: 400;
                    letter-spacing: 0;
                }
            }

            a{
                width: 100%;
                font-size: 12px;
            }
            ul{
                box-shadow: 1px 5px 15px 0 rgba(0, 0, 0, 0.05);
            }
        }
        &:hover{
            > ul{
                display: block;
                top:100%;
                @include transition(0.5s);
            }
        }

    }
    .mega-sub-menu,.mega-sub-menu{
        >li a{
            color: $font-color;
        }
    }
    //lv3
    ul >li >ul >li{
        ul{
            float: none !important;
            display: none;
            position: absolute;
            @include rtl-left(100%);
            top:0;
        }
        &:hover{
            > ul{
                display: block;
            }
            >.sub-menu{
                top:0;
            }
        }
    }

}

//================================================ MEGA MENU ===========================================================
#na-menu-primary ul.na-menu .megamenu{
    &:hover{
        >ul{
            display: block;
        }
    }
    //lv2
    >ul{
        width: 1170px;
        padding: 50px 30px 30px;
        left: 0;
        right:0;
        margin: auto;
        display: none;
        @include transition(0.4s);
        z-index: 9998;
        > li{
            @include rtl-float-left();
            display: block;
            border: none;
            padding: 0 15px;
            > a{
                color: $color-title;
                padding: 0;
                font-size: 15px;
            }
            &:after{
                content: none;
            }
            //lv3
            ul{
                padding-top: 15px;
                display: block;
                opacity: 1;
                visibility: visible;
                @include rtl-left(0);
                position: relative;
                box-shadow: none;
                li{
                    border: none;
                    a{
                        line-height: 32px;
                        color: $color-meta;
                        padding: 0;
                        font-size: 14px;
                        &:hover{
                            color: $color-title;
                        }
                    }
                }
            }
        }
    }
}
#na-menu-primary ul.na-menu .megamenu.col-2 {
    >ul {
        width: 500px;
    }
    >ul > li{
        width:50%;
    }
}
#na-menu-primary ul.na-menu .megamenu.col-3 {
    >ul {
        width: 800px;
    }
    >ul > li{
        width: 33.33%;
    }
}
#na-menu-primary ul.na-menu .megamenu.col-4 {
    >ul {
        width: 1170px;
    }
    >ul > li{
        width: 25%;
    }
}


//widget
#na-menu-primary {
    //widget  title
    .mega-block-title{
        padding-bottom: 15px;
        color: $font-color;
        font-size: 14px;
        text-transform: uppercase;
        border-bottom: $border-color-base;
        margin: 10px 0;
    }
    //widget  content


    //======================================================================
    //widget  fix

    /* widget product list */
    .product_list_widget{
        box-shadow:none;
        max-width: 400px;
        position: relative !important;
        >li{
            margin-bottom: 30px;
            border: none;
            @include clearfix;
            .product-image{
                padding:0 !important;
            }
        }
    }
    .cart_list.product_list_widget{
        >li{
            border-bottom: 1px solid #e0e0e0;
            margin-bottom: 0;
        }
    }

    .product-image{
        @include rtl-margin-right(10px);
        width: auto;
        padding: 0;
        @include rtl-float-left();

        .img-effect:hover .wp-post-image {
            @include rtl-margin-left(0%);
            transition: all 0.4s ease 0s;
        }
        .hsok-image{
            display: none;
        }
    }
    .product-details{
        padding: 0;
        overflow: hidden;
        a{
            @include rtl-padding-left(0px);
            color: #000000;
            font-size: 15px;
            padding-bottom: 10px;
            &:hover{
                color: $theme-color;
            }
        }
        .amount{
            color: #000000;
            font-size: 16px;
            line-height: 20px;
        }
    }

    /* widget image widget */
    .widget_sp_image-image-link{
        padding: 0;
    }
    .widget_sp_image-description{
        padding: 15px 0;
        font-style: italic;
        text-align: justify;
    }
}

//Menu fixed
.single-product.sok-product-trans{
    .site-header{
        background: transparent;
        position: fixed;
        top: 0;
        width: 100%;
        z-index: 9998;
        @include transition(0.6s);
    }
}
//Keep - Header
.site-header.fixed ,.single-product .site-header.fixed{
    background: #FFF;
    @include transition(0.4s);
}
.site-header.fixed {
    z-index: 9998;
    .header-fixed{
        background: #FFF;
    }
}

.admin-bar{
    .site-header.fixed {
            top:32px;
    }
    .single-product.sok-product-trans{
        .site-header{
            top:32px;
        }
    }
}
.slicknav_nav .slicknav_arrow {
    font-size: 12px;
    margin: 0 0 0 0.4em;
    position: absolute;
    right: 5px;
    top: 0;
    line-height: 54px;
    width: 50px;
    height: 50px;
    text-align: center;
}

//Mobile Menu

#mobile-primary,
#mobile-primary ul,
#mobile-primary ul li,
#mobile-primary ul li a {
    margin: 0;
    padding: 0;
    border: 0;
    list-style: none;
    line-height: 1;
    display: block;
    position: relative;
    float: none;
    @include box-sizing(border-box);
}
#mobile-primary > ul > li > a{
    border-bottom: 1px solid #eaeaea;
}
#mobile-primary > ul ul > li > a{
    border-bottom: 1px solid #eaeaea;
}
#mobile-primary > ul > li:last-child a{
    border-bottom: none;
}
#mobile-primary ul li.open{
    background: transparent;
    > a{
        background: transparent;
        &:focus, &:hover{
            background: transparent;
        }
    }
}
#mobile-primary {
    color: #201f1f;
}
#mobile-primary ul ul {
    display: none;
}
.align-right {
    float: right;
}
#mobile-primary > ul > li > a {
    padding: 20px 0px;
    cursor: pointer;
    z-index: 2;
    font-size: 14px;
    font-weight: 400;
    text-decoration: none;
    color: #201f1f;
    text-transform: uppercase;
}
#mobile-primary > ul > li > a:hover,
#mobile-primary > ul > li.active > a,
#mobile-primary > ul > li.open > a {
    color: #201f1f;
}
#mobile-primary > ul > li.open > a {
}
#mobile-primary > ul > li:last-child > a,
#mobile-primary > ul > li.last > a {
}
.holder {
    @include square(55px);
    line-height: normal;
    z-index: 99;
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
    @include flexbox();
    @include justify-content(flex-end);
    @include align-items(center);
    @include rtl-text-align-right();
    &:before {
        display: block;
        position: absolute;
        content: "";
        z-index: 10;
        @include square(8px);
        @include rtl-right(2px);
        @include transform(rotate(-135deg));
    }
}
#mobile-primary > ul > li > a:hover > span::after,
#mobile-primary > ul > li.active > a > span::after,
#mobile-primary > ul > li.open > a > span::after {
    border-color: #eeeeee;
}
.holder::before {
    top: 23px;
    border-top: 2px solid;
    border-left: 2px solid;
    border-top-color: inherit;
    border-left-color: inherit;
}
#mobile-primary ul ul li a {
    cursor: pointer;
    padding:20px 20px;
    z-index: 1;
    text-decoration: none;
    font-size: 14px;
    color: #7a7a7a;
    &:hover{
        color: $color-title;
    }
}
#mobile-primary ul ul li:hover > a,
#mobile-primary ul ul li.open > a,
#mobile-primary ul ul li.active > a {
    color: $color-title;
}
#mobile-primary ul ul li:first-child > a {
    box-shadow: none;
}
#mobile-primary ul ul ul li:first-child > a {
}
#mobile-primary ul ul ul li a {
    padding-left: 30px;
}
#mobile-primary > ul > li > ul > li:last-child > a,
#mobile-primary > ul > li > ul > li.last > a {
    border-bottom: 0;
}
#mobile-primary > ul > li > ul > li.open:last-child > a,
#mobile-primary > ul > li > ul > li.last.open > a {
}
#mobile-primary > ul > li > ul > li.open:last-child > ul > li:last-child > a {
    border-bottom: 0;
}

#mobile-primary ul ul li.active > a::after,
#mobile-primary ul ul li.open > a::after,
#mobile-primary ul ul li > a:hover::after {
    border-color: #201f1f;
}
.nav > li > a:hover, .nav > li > a:focus{
    background: transparent;
}

//vertical-primary

#vertical-primary,
#vertical-primary ul,
#vertical-primary ul li,
#vertical-primary ul li a {
    margin: 0;
    padding: 0;
    border: 0;
    list-style: none;
    line-height: 1;
    display: block;
    position: relative;
    float: none;
    @include box-sizing(border-box);
}
#vertical-primary > ul > li{
    max-width: 200px;
}
#vertical-primary > ul ul > li > a{
}
#vertical-primary > ul > li:last-child a{
    border-bottom: none;
}
#vertical-primary ul li.open{
    background: transparent;
    > a{
        background: transparent;
        &:focus, &:hover{
            background: transparent;
        }
    }
}

#vertical-primary {
    color: #201f1f;
    min-width: 180px;
    position: fixed;
    top:30%;
    left: 60px;
    .holder {        
        line-height: normal;
        z-index: 99;
        position: absolute;
        top: 0;
        right: 0;
        cursor: pointer;
        @include square(55px);
        @include opacity(0);
        @include flexbox();
        @include justify-content(flex-end);
        @include align-items(center);
        @include rtl-text-align-right();
        &:before {
            display: block;
            position: absolute;
            content: "";
            z-index: 10;
            color: $color-title;
            @include square(8px);
            @include rtl-right(22px);
            @include transform(rotate(-135deg));            
        }
    }
    li:hover,.open{
        .holder {
            opacity: 1;
        }
    }
    .open .holder,.has-sub:hover .holder{
        &:before {
            color: $color-title;
        }
        .holder {
            opacity: 1;
        }
    }
}
#vertical-primary ul ul {
    display: none;
}
.align-right {
    float: right;
}
#vertical-primary > ul > li > a {
    padding: 20px 0px;
    cursor: pointer;
    z-index: 2;
    font-size: 16px;
    font-weight: 400;
    text-decoration: none;
    color: #201f1f;
}
#vertical-primary > ul > li > a:hover,
#vertical-primary > ul > li.active > a,
#vertical-primary > ul > li.open > a {
    color: #201f1f;
}
#vertical-primary > ul > li.open > a {
}
#vertical-primary > ul > li:last-child > a,
#vertical-primary > ul > li.last > a {
}

#vertical-primary > ul > li > a:hover > span::after,
#vertical-primary > ul > li.active > a > span::after,
#vertical-primary > ul > li.open > a > span::after {
    border-color: #eeeeee;
}
.holder::before {
    top: 23px;
    border-top: 2px solid;
    border-left: 2px solid;
    border-top-color: inherit;
    border-left-color: inherit;
}
#vertical-primary ul ul li a {
    cursor: pointer;
    padding:20px 20px;
    z-index: 1;
    text-decoration: none;
    font-size: 15px;
    font-weight: 300;
    color: #7a7a7a;
    &:hover{
        color: $color-title;
    }
}
#vertical-primary ul ul li:hover > a,
#vertical-primary ul ul li.open > a,
#vertical-primary ul ul li.active > a {
    color: $color-title;
}
#vertical-primary ul ul li:first-child > a {
    box-shadow: none;
}
#vertical-primary ul ul ul li:first-child > a {
}
#vertical-primary ul ul ul li a {
    padding-left: 30px;
}
#vertical-primary > ul > li > ul > li:last-child > a,
#vertical-primary > ul > li > ul > li.last > a {
    border-bottom: 0;
}
#vertical-primary > ul > li > ul > li.open:last-child > a,
#vertical-primary > ul > li > ul > li.last.open > a {
}
#vertical-primary > ul > li > ul > li.open:last-child > ul > li:last-child > a {
    border-bottom: 0;
}

#vertical-primary ul ul li.active > a::after,
#vertical-primary ul ul li.open > a::after,
#vertical-primary ul ul li > a:hover::after {
    border-color: #201f1f;
}
.left-fixed{
    display: none;
    &.view{
        display: block;
    }
}

.nav > li > a:hover, .nav > li > a:focus{
    background: transparent;
}