//Post layout
.layout-list {
    .item-post{
        margin-bottom: 0;
        &:first-child .post-item{
            padding-top: 0;
        }
    }

}
.archive-blog .item-post{
    &:first-child .post-list{
        padding-top: 0;
    }
}
.post-list{
    position: relative;
    border-bottom: 1px solid #e3e3e3;
    padding-bottom: 60px;
    padding-top: 60px;
    max-width: 1100px;
    margin:0 auto 0px;
    text-align: center;
    .post-image {
    }
    .entry-title{
        font-size: 34px;
        line-height: 52px;
        font-weight: 500;
    }
    .article-image{
        position: relative;
        display: inline-block;
        margin-bottom: 50px;
        margin-top: 20px;
    }
    .article-content{
        max-width: 700px;
        margin:0 auto;
        text-align: center;
        &.full-width{
            width: 100%;
        }
    }
    .entry-header{
        max-width: 700px;
        margin:0 auto;
    }
    .entry-content{
        margin-top: 0;
        margin-bottom: 20px;
    }
    .full-width{

    }
    .post-image{
        @include rtl-text-align-right();
    }

}

.post-grid{

    .entry-header{
        padding-top: 0px;
        margin: 0;
        text-align: left;
    }
    .entry-title{
        margin: 0;
        font-size: 22px;
        line-height: 38px;
        padding-top: 5px;
    }
    .post-image{
    }
    .entry-content{
        display: none;
        margin-top: 5px;
        padding-bottom: 15px;
    }
    .entry-date {
        color: #a3a3a3;
        font-size: 12px;
        text-transform: uppercase;
    }
    .entry-meta-right{
        padding-top: 5px;
    }
    .btn-readmore{
        padding-top: 20px;
        display: none;
    }
    .article-content{
        background: #fff;
        padding: 15px 0px 30px;
    }
    .article-content.full-width .entry-header{
        padding:0px;
    }

}

.post-tran{
    position: relative;
    display: block;
    @include transition(0.4s);
    .entry-header{
        padding-top: 20px;
        margin: 0;
    }
    .entry-title{
        margin: 0;
        padding-top: 5px;
    }
    .entry-content{
        margin-top: 20px;
    }
    .btn-readmore{
        padding-top: 20px;
    }
    .entry-meta-right{
        padding-top: 5px;
    }
    .article-content-inner{
        background:#fff;
        width: 100%;
        height: 100%;
        padding: 30px;
    }
    .article-content{
        opacity: 0;
        visibility: hidden;
        @include transition(0.4s);
        position: absolute;
        top:0;
        @include rtl-left(0);
        padding: 10px;
        width: 100%;
        height: 100%;
    }

    &:hover .article-content{
        opacity: 1;
        visibility: visible;
        @include transition(0.4s);
    }

}

.posts-listing{
    .post-thumb{
        width: 30%;
        float: left;
    }
    .post-content{
        width: 70%;
        padding-left: 25px;
        float: left;
    }
    .entry-title{
        padding-top: 5px;
    }
    .article-meta-date{
        padding-top: 10px;
    }
}

.archive-blog .col-item{
    &.col-md-4:nth-last-child(-n+3){
        margin-bottom: 0;
        .entry-content{
            padding-bottom: 0;
        }
    }
    &.col-md-6{
        &:nth-last-child(-n+1){
            margin-bottom: 0;
            .entry-content{
                padding-bottom: 0;
            }
        }
        &:nth-child(2n){
            @include rtl-clear-left();
        }
    }
}
.block-blog{
    .block-title{
        margin-bottom: 20px;
    }
    .des-box{
        text-align: center;
        font-size: 16px;
        max-width: 550px;
        margin: auto auto 40px;
    }
    .posts-featured{
        margin: 0 -15px;
    }
}
.layout-grid{
    margin-left: -25px;
    margin-right: -25px;
    .item-post{
        padding-right: 25px;
        padding-left: 25px;
        padding-bottom: 15px;
    }
}
.layout-tran{
    margin-left: -50px;
    margin-right: -50px;
    .item-post{
        padding-right: 50px;
        padding-left: 50px;
        margin-bottom: 55px;
    }

}